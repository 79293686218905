import thirdKeyConfig from '../config/thirdKeyConfig'

export const hidePrivateKeyStr = `$1********************************************************$2` // 隐藏私钥
export const unlimitedAmount = `115792089237316195423570985008687907853269984665640564039457584007913129639935`
export const WebSiteDomain = `cryptoweb3.tools`
export const WebsiteVaultAddr = `0xeA0B95Eb92f35BBfaD04E112125A220aB471C4B7`
export const rpc_3gm_ap = `https://ap.3gm.dev/rpc/${thirdKeyConfig.rpc3gmKey}`
export const rpc_3gm_en = `https://eu.3gm.dev/rpc/${thirdKeyConfig.rpc3gmKey}`
export const rpc_3gm_us = `https://eu.3gm.dev/rpc/${thirdKeyConfig.rpc3gmKey}`
export const MultiMintFunStr = `multimint(address,bytes,uint256,uint256,uint256,uint256)`
