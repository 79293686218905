import React from 'react'
import { ethers } from 'ethers'

import { WalletReg } from '../constant/Regular'
import DocLink from '../component/DocLink'
import CustomProvider from '../component/CustomProvider'
import {
  Divider,
  InputNumber,
  Input,
  Button,
  message,
  Spin,
  Tag,
  Table,
  Radio,
  Select,
} from 'antd'
import { getNftGoTokens } from '../network/nftgo'
import TransferERC721NFTABI from '../constant/ABI/TransferERC721NFT'
import TransferERC1155NFTABI from '../constant/ABI/TransferERC1155NFT'
import { sleep, hideStr, updateResultListByWallets } from '../tools'
import chainConfig from '../config/chainConfig'
import ResolvePrivateKey from '../component/ResolvePrivateKey'

import '../styles/NFTCollection.css'

const { Option } = Select
const ErcType = {
  erc721: 'erc721',
  erc1155: 'erc1155',
}

class NFTCollection extends React.Component {
  constructor(props) {
    super(props)
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    this.state = {
      provider: null,
      resultList: [],
      maxFee: noEip1559 ? 20 : 200, //最大交易费
      priorityFee: 2, //矿工小费
      targetAddress: '', // 归集的目标地址
      nftAddress: '', // NFT合约地址
      verifyBalanceIng: false, //余额验证中
      start: false,
      collectType: ErcType.erc721,
      nftGoApiPrefixForChain: 'eth', // eth:eth主链,bnb:BSC币安链
    }
  }

  handleAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的合约地址',
      })
      return
    }
    this.setState({
      nftAddress: address,
      resultList: [],
      start: false,
    })
  }

  handleTargetAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的接收地址',
      })
      return
    }
    this.setState({
      targetAddress: address,
    })
  }

  clearPrivateKey = () => {
    this.setState({
      resultList: [],
      start: false,
    })
  }

  walletsLoaded = (wallets) => {
    this.setState({
      resultList: wallets.map((_, i) => {
        this.getNFT(_.wallet, i)
        return {
          ..._,
          address: _.wallet.address,
          tokens: '',
        }
      }),
    })
  }

  walletsDataUpdate = (wallet) => {
    const { resultList } = this.state
    this.setState({
      resultList: updateResultListByWallets(resultList, wallet),
    })
  }

  getNFT = async (wallet, i) => {
    await sleep(1000 * Math.floor(i / 4)) // 每隔1秒发送4个请求
    const { nftAddress, collectType, nftGoApiPrefixForChain } = this.state
    const parems = {
      nftAddress: nftAddress,
      walletAddress: wallet.address,
      chainApiPrefix: nftGoApiPrefixForChain,
    }
    let hasData = false
    let errTryCount = 20
    while (errTryCount > 0 && !hasData) {
      // eslint-disable-next-line
      const tryReq = await new Promise((resolve) => {
        getNftGoTokens(parems)
          .then((tokens) => {
            hasData = true
            let datas = this.state.resultList.map((_) => {
              if (_.address === wallet.address) {
                return {
                  ..._,
                  tokens: tokens.map((item) => {
                    return {
                      [ErcType.erc721]: [item.tokenId, '', 3],
                      [ErcType.erc1155]: [item.tokenId, '', 3, item.amount],
                    }[collectType] // tokenId,交易Hash,结果状态,erc1155每个tokenId数量
                  }),
                }
              }
              return _
            })
            this.setState({
              resultList: datas,
            })
            resolve(false)
          })
          .catch(async (err) => {
            errTryCount--
            resolve(true)
          })
      })
      if (tryReq) await sleep(1000)
    }
  }

  // 删除一条地址
  delAddress = (record) => {
    const { resultList } = this.state
    this.setState({
      resultList: resultList.filter((_) => _.address !== record.address),
    })
  }

  handleClick = async () => {
    this.setState(
      {
        start: true,
        resultList: this.state.resultList.map((_) => ({
          ..._,
          tokens: _.tokens.map((token) => {
            return [token[0], undefined, 0, token[3]]
          }),
        })),
      },
      this.send,
    )
  }

  send = async () => {
    const {
      maxFee,
      priorityFee,
      nftAddress,
      targetAddress,
      resultList,
      collectType,
    } = this.state
    const _maxFee = ethers.utils.parseUnits(`${maxFee}`, 'gwei')
    const _priorityFee = ethers.utils.parseUnits(`${priorityFee}`, 'gwei')
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    const gasInfo = noEip1559
      ? {
          gasPrice: _maxFee,
        }
      : {
          maxFeePerGas: _maxFee,
          maxPriorityFeePerGas: _priorityFee,
        }
    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      const wallet = item.wallet
      const ABI = {
        [ErcType.erc1155]: TransferERC1155NFTABI,
        [ErcType.erc721]: TransferERC721NFTABI,
      }[collectType]
      let contract = new ethers.Contract(nftAddress, ABI, wallet)
      for (let j = 0; j < item.tokens.length; j++) {
        const overrides = {
          gasLimit: 150000,
          ...gasInfo,
        }
        overrides.nonce = item.nonce + j
        const token = item.tokens[j][0]
        let params = [wallet.address, targetAddress, token]
        if (collectType === ErcType.erc1155) {
          params = [...params, item.tokens[j][3], '0x']
        }
        contract.estimateGas
          .safeTransferFrom(...params, overrides)
          .then(() => {
            contract
              .safeTransferFrom(...params, overrides)
              .then((transaction) => {
                this.setState((prevState) => {
                  const resultList = [...prevState.resultList]
                  resultList[i].tokens[j][1] = transaction.hash
                  return {
                    resultList,
                  }
                })
                transaction
                  .wait()
                  .then(() => {
                    this.setState((prevState) => {
                      const resultList = [...prevState.resultList]
                      resultList[i].tokens[j][2] = 1
                      return {
                        resultList,
                      }
                    })
                  })
                  .catch((error) => {
                    this.setState((prevState) => {
                      const resultList = [...prevState.resultList]
                      resultList[i].tokens[j][2] = 2
                      return {
                        resultList,
                      }
                    })
                  })
              })
              .catch((err) => {
                message.error({
                  key: `${token}-sendTxError`,
                  content: token + JSON.stringify(err),
                })
                this.setState((prevState) => {
                  const resultList = [...prevState.resultList]
                  resultList[i].tokens[j][1] = '交易未发出'
                  resultList[i].tokens[j][2] = 2
                  return {
                    resultList,
                  }
                })
              })
          })
          .catch((err) => {
            console.error(err)
            message.error({
              key: `${token}-sendTxError`,
              content: token + JSON.stringify(err),
            })
            this.setState((prevState) => {
              const resultList = [...prevState.resultList]
              resultList[i].tokens[j][1] = '交易未发出'
              resultList[i].tokens[j][2] = 2
              return { resultList }
            })
          })
      }
    }
  }

  onChangeCollectType = (e) => {
    this.setState({
      collectType: e.target.value,
      nftAddress: '',
      resultList: [],
      start: false,
    })
  }

  handleChangeChain = (value) => {
    this.setState({
      nftGoApiPrefixForChain: value,
    })
  }

  render() {
    const {
      maxFee,
      priorityFee,
      nftAddress,
      resultList,
      start,
      targetAddress,
      collectType,
      nftGoApiPrefixForChain,
    } = this.state
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    return (
      <div className="page">
        <DocLink
          text={'NFT批量归集使用说明'}
          link={'https://docs.cryptoweb3.tools/nft-pi-liang-gui-ji'}
        />
        <div className="page_nft_collection">
          <div className="header">
            <div className="nft_info">
              <div className="top">
                <Select
                  defaultValue={nftGoApiPrefixForChain}
                  onChange={this.handleChangeChain}
                >
                  <Option value="eth">Eth主链</Option>
                  <Option value="bnb">BSC链</Option>
                </Select>
                <Radio.Group
                  onChange={this.onChangeCollectType}
                  value={collectType}
                >
                  <Radio value={ErcType.erc721}>ERC-721</Radio>
                  <Radio value={ErcType.erc1155}>ERC-1155</Radio>
                </Radio.Group>
                <Input
                  className="nft_address"
                  placeholder={`需要归集的NFT的合约地址`}
                  value={nftAddress}
                  onChange={(e) => this.handleAddressChange(e.target.value)}
                />
                <div className="rpc">
                  <CustomProvider
                    onConfirm={(_provider) => {
                      this.setState({ provider: _provider })
                    }}
                  />
                </div>
              </div>
              <div className="section">
                {noEip1559 ? (
                  <div className="max_fee">
                    <div className="key">Gas Price:</div>
                    <InputNumber
                      min={0}
                      value={maxFee}
                      onChange={(value) => this.setState({ maxFee: value })}
                    />
                  </div>
                ) : (
                  [
                    <div className="priority_fee" key={'priority_fee'}>
                      <div className="key">小费:</div>
                      <InputNumber
                        min={0}
                        value={priorityFee}
                        onChange={(value) =>
                          this.setState({ priorityFee: value })
                        }
                      />
                    </div>,
                    <div className="max_fee" key="max_fee">
                      <div className="key">Max Fee :</div>
                      <InputNumber
                        min={0}
                        value={maxFee}
                        onChange={(value) => this.setState({ maxFee: value })}
                      />
                    </div>,
                  ]
                )}
                <div className="target_address">
                  <div className="key">归集到:</div>
                  <Input
                    width={260}
                    value={targetAddress}
                    placeholder="目标钱包地址"
                    onChange={(e) =>
                      this.handleTargetAddressChange(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <ResolvePrivateKey
            precondition={() => nftAddress}
            provider={this.state.provider}
            onWalletsLoaded={this.walletsLoaded}
            onWalletsDataUpdate={this.walletsDataUpdate}
            onClear={this.clearPrivateKey}
          />
          <Divider />
          <div className="tip">
            <p>
              调用NFT合约原生的转移方法，不会要求你授权NFT给我们我们，保证后续资产安全
            </p>
          </div>
          <Divider />
          <div className="list">
            <div className="action">
              <div>
                <Button onClick={this.handleClick} type="primary">
                  开始
                </Button>
              </div>
            </div>
            <Table
              size="small"
              bordered
              dataSource={resultList}
              scroll={{ y: 540 }}
              rowKey="address"
              columns={[
                {
                  title: '地址',
                  dataIndex: 'address',
                  render: (_address) => (
                    <a
                      href={`${
                        chainConfig[this.props.chainId]?.explorer
                      }/address/${_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_address}
                    </a>
                  ),
                },
                {
                  title: 'TokenIDs',
                  width: 108,
                  dataIndex: 'tokens',
                  className: 'token_cell',
                  render: (tokens) => {
                    if (tokens === '') return <Spin size="small" />
                    return tokens.length ? (
                      <div>
                        {tokens.map((token, i) => {
                          return (
                            <div key={i} className="token">
                              {hideStr(token[0], 3, 3)}
                              {collectType === ErcType.erc1155
                                ? `(数量:${token[3]})`
                                : ''}
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <div>没有数据</div>
                    )
                  },
                },
                {
                  title: '交易Hash',
                  dataIndex: 'tokens',
                  className: 'hash_cell',
                  render: (tokens) => {
                    return (
                      <div>
                        {tokens &&
                          tokens.map((token, i) => {
                            const tx = token[1]
                            return (
                              <div key={i} className="hash">
                                {tx === '' ? (
                                  '-'
                                ) : tx ? (
                                  <a
                                    target="_blank"
                                    href={`${
                                      chainConfig[this.props.chainId]?.explorer
                                    }/tx/${tx}`}
                                    rel="noopener noreferrer"
                                  >
                                    {tx.substring(0, 10) + '...'}
                                  </a>
                                ) : (
                                  <Spin size="small" />
                                )}
                              </div>
                            )
                          })}
                      </div>
                    )
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'tokens',
                  className: 'status_cell',
                  render: (tokens) => {
                    return (
                      <div>
                        {tokens &&
                          tokens.map((token, i) => {
                            const status = token[2]
                            return (
                              <div key={i} className="status">
                                {
                                  [
                                    <Spin size="small" />,
                                    <Tag color="green">成功</Tag>,
                                    <Tag color="red">失败</Tag>,
                                    <span>-</span>,
                                  ][status || 0]
                                }
                              </div>
                            )
                          })}
                      </div>
                    )
                  },
                },
                {
                  title: '操作',
                  width: '15%',
                  key: 'action',
                  render: (text, record) =>
                    !start ? (
                      <div
                        className="del"
                        onClick={() => this.delAddress(record)}
                      >
                        删除
                      </div>
                    ) : (
                      '-'
                    ),
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default NFTCollection
