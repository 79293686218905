import React from 'react'
import { ethers } from 'ethers'
import { WalletReg } from '../constant/Regular'
import CustomProvider from '../component/CustomProvider'
import { Divider, Input, Button, message, Spin, Table } from 'antd'
import CoinInfoABI from '../constant/ABI/CoinInfo'
import chainConfig from '../config/chainConfig'
import '../styles/QueryTokenBalance.css'

const { TextArea } = Input
class QueryTokenBalance extends React.Component {
  constructor(props) {
    super(props)
    const defaultToken = chainConfig[this.props.chainId]?.nativeCurrency.symbol
    this.state = {
      provider: null,
      resultList: [],
      tokenAddress: '', // Token合约地址
      decimals: 0, // Token 精度
      tokenLoading: false,
      tokenName: defaultToken, // token名
      start: false,
    }
  }

  handleAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的合约地址',
      })
      return
    }
    address && this.getTokenName(address)
    this.setState({
      tokenAddress: address,
      resultList: [],
      start: false,
    })
  }

  getTokenName = async (address) => {
    const { provider } = this.state
    const abiStr = JSON.stringify(CoinInfoABI)
    const contract = new ethers.Contract(address, abiStr, provider)
    const hide = message.loading('代币信息加载中...', null)
    this.setState({ tokenLoading: true })
    try {
      const decimals = await contract.decimals()
      const name = await contract.symbol()
      this.setState({
        decimals,
        tokenName: name,
        tokenLoading: false,
      })
    } catch (error) {
      this.setState({
        tokenName: '错误地址',
        tokenLoading: false,
      })
    }
    hide()
  }

  handleTargetAddressChange = (user, value) => {
    if (!WalletReg.test(value) && value !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的接收地址',
      })
      return
    }
    this.setState({
      resultList: this.state.resultList.map((_) => {
        if (_.address === user) _.targetAddr = value
        return _
      }),
    })
  }

  handleTargetAddressListChange = (addrListStr) => {
    const addrList = addrListStr.split(/[(\r\n)\r\n]+/)
    for (let i = 0; i < addrList.length; i++) {
      const addr = addrList[i]
      if (!WalletReg.test(addr) && addr !== '') {
        message.error({
          key: 'addressError',
          content: '请输入正确的查询地址',
        })
        return
      }
    }
    this.setState({
      addressList: addrList,
    })
  }

  getBalanceOf = async (address) => {
    const { tokenAddress, provider, decimals } = this.state
    if (!tokenAddress) {
      provider.getBalance(address).then((balance) => {
        let datas = this.state.resultList.map((_) => {
          if (_.address === address) {
            return {
              ..._,
              _balance: `${ethers.utils.formatUnits(`${balance}`, 'ether')}`,
            }
          }
          return _
        })
        this.setState({
          resultList: datas,
        })
      })
    } else {
      const abiStr = JSON.stringify(CoinInfoABI)
      const contract = new ethers.Contract(tokenAddress, abiStr, provider)
      contract.balanceOf(address).then((value) => {
        let datas = this.state.resultList.map((_) => {
          if (_.address === address) {
            return {
              ..._,
              _balance: `${
                ethers.utils.formatUnits(`${value}`, 'ether') *
                10 ** (18 - decimals)
              }`,
            }
          }
          return _
        })
        this.setState({
          resultList: datas,
        })
      })
    }
  }

  queryBalance = () => {
    this.setState({
      resultList: this.state.addressList.map((address) => {
        this.getBalanceOf(address)
        return { balance: '-', address }
      }),
    })
  }

  render() {
    const { tokenAddress, resultList, start, tokenName } = this.state
    return (
      <div className="page">
        <div className="page_balance_collection">
          <div className="box">
            <div className="target_address">
              <TextArea
                rows={5}
                placeholder="待查询地址"
                onChange={(e) =>
                  this.handleTargetAddressListChange(e.target.value)
                }
              />
            </div>
            <div className="top">
              {tokenName && <div className="token_name">{tokenName}</div>}
              <Input
                className="nft_address"
                placeholder={`代币地址，不填写默认为本链原生代币`}
                value={tokenAddress}
                onChange={(e) => this.handleAddressChange(e.target.value)}
              />
              <div className="rpc">
                <CustomProvider
                  onConfirm={(_provider) => {
                    this.setState({ provider: _provider })
                  }}
                />
              </div>
              <Button
                className="query"
                type="primary"
                onClick={this.queryBalance}
              >
                查询
              </Button>
            </div>
          </div>
          <Divider />
          <div className="list">
            <Table
              size="small"
              bordered
              dataSource={resultList}
              scroll={{ y: 540 }}
              rowKey="address"
              columns={[
                {
                  title: '地址',
                  dataIndex: 'address',
                  render: (_address) => (
                    <a
                      href={`${
                        chainConfig[this.props.chainId]?.explorer
                      }/address/${_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_address}
                    </a>
                  ),
                },
                {
                  title: `余额(${tokenName})`,
                  dataIndex: '_balance',
                  render: (_balance, record) => {
                    return _balance ? (
                      _balance.substring(0, 6)
                    ) : (
                      <Spin size="small" />
                    )
                  },
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default QueryTokenBalance
