const thirdKeyConfig = {
  x2y2ApiKeys: [
    'a9cfdbd8-451b-44e0-8560-e96f06048e78',
    '3002dfa7-fc2d-43e4-a1a9-6b6baeb0a94a',
    'a87d1035-504f-416f-829c-ca58969ef42b',
  ],
  nftgoKeys: ['342a1e3d-be5f-42e8-a233-82e12f9ba91f'], // nftgo api key
  openSeaApiKeys: [
    `e2dc8eb32617477f87637e4cf0a7e6ec`,
    // `4714cd73a39041bf9cffda161163f8a5`,
    // `29930174fbb94c91bd30a31e79153922`,
    // `2ed3744174484f28803e9759f7df3592`,
  ],
  rpc3gmKey: 'EQRPb4AAEJzUnkZmmdxhWC4b',
}

export default thirdKeyConfig
