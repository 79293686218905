import React, { useEffect, useState } from 'react'
import {
  Divider,
  InputNumber,
  Button,
  Input,
  message,
  Tag,
  Modal,
  Table,
  Tooltip,
  Radio,
  Space,
  Select,
  Spin,
} from 'antd'
import { BinanceCoins, BinanceNetworks } from '../constant/Exchange'
import { withdrawFromBinance, getWithDrawRecord } from '../network/site'
import DocLink from '../component/DocLink'
import '../styles/WithDrawFromExchange.css'

const { TextArea } = Input
const { Option } = Select

function beforeunload(e) {
  let msg = '离开页面后数据将不会被保存，确定离开？'
  e = e || window.event
  e.returnValue = msg
  return msg
}

function WithDrawFromExchange() {
  const [cex, setCex] = useState('Binance')
  const [coin, setCoin] = useState('ETH')
  const [network, setNetwork] = useState('ETH')
  const [apiKey, setApiKey] = useState('')
  const [passphrase, setPassphrase] = useState('')
  const [secretKey, setSecretKey] = useState('')
  const [errorRow, setErrorRow] = useState([])
  const [addressAmount, setAddressAmount] = useState(``)
  const [resultList, setResultList] = useState([])
  const [timeMode, setTimeMode] = useState(1)
  const [minDelayTime, setMinDelayTime] = useState('')
  const [maxDelayTime, setMaxDelayTime] = useState('')
  const [minAmount, setMinAmount] = useState('')
  const [maxAmount, setMaxAmount] = useState('')
  const [decimal, setDecimal] = useState('')

  const [withdrawRecordVisible, setWithdrawRecordVisible] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [size, setSize] = useState(0)
  const [records, setRecords] = useState()

  useEffect(() => {
    requestRecords()
  }, [pageNum, pageSize])

  useEffect(() => {
    // requestCoinsAndChains()
    window.addEventListener('beforeunload', beforeunload)
    return () => {
      window.removeEventListener('beforeunload', beforeunload)
    }
  }, [])

  // const requestCoinsAndChains = () => {
  //   getCoins().then((data) => {
  //     setRecords(data?.list || [])
  //   })
  //   getChains().then((data) => {
  //     setRecords(data?.list || [])
  //   })
  // }

  const requestRecords = () => {
    getWithDrawRecord({
      pageNum: pageNum,
      pageSize: pageSize,
    }).then((data) => {
      setRecords(data?.list || [])
      setSize(data.total)
    })
  }

  const sendAmount = () => {
    if (!minAmount || !maxAmount || !decimal) {
      message.error('请输入发送金额')
      return
    }
    if (!errorRow.length && addressAmount) {
      let arr = addressAmount.split(/[(\r\n)\r\n]+/).filter((_) => _)
      arr = arr.map((item) => {
        const amount = +(
          Math.random() * (minAmount - maxAmount) +
          maxAmount
        ).toFixed(decimal)
        const [addr, num, memo] = item.split(',')
        let str = `${addr},${amount}`
        if (memo) str += `,${memo}`
        return str
      })
      setAddressAmount(arr.join('\n'))
    }
  }

  const changeAddressAccount = (value) => {
    setAddressAmount(value)
    if (!value) {
      setErrorRow([])
      return
    }
    const arr = value.split(/[(\r\n)\r\n]+/).filter((_) => _)
    const _erroeRow = []
    const regex = new RegExp(/.(,(\d+)(\.\d+)?)?$/)
    arr.forEach((row, index) => {
      if (!regex.test(row) && row) {
        _erroeRow.push(index + 1)
      }
    })
    setErrorRow(_erroeRow)
  }
  const handleChangeCoin = (value) => {
    setCoin(value)
  }
  const handleChangeNetwork = (value) => {
    setNetwork(value)
  }
  const handleChangeApiKey = (e) => {
    setApiKey(e.target.value)
  }
  const handleChangePassphrase = (e) => {
    setPassphrase(e.target.value)
  }
  const handleChangeSecretKey = (e) => {
    setSecretKey(e.target.value)
  }
  const getDelayTime = (lastTime) => {
    if (timeMode === 2)
      return (
        Math.floor(Math.random() * (minDelayTime - maxDelayTime + 1)) +
        maxDelayTime
      )
    if (timeMode === 1) {
      const interval =
        Math.floor(Math.random() * (minDelayTime - maxDelayTime + 1)) +
        maxDelayTime
      return lastTime + interval
    }
  }
  const confirmInfo = () => {
    let err = false
    addressAmount.split(/[(\r\n)\r\n]+/).forEach((item) => {
      if (!item.includes(',') || /,0$/.test(item)) {
        message.error(`地址 ${item} 未设置金额!`)
        err = true
      }
    })
    if (err) return
    let lastTime = 0
    setResultList(
      addressAmount.split(/[(\r\n)\r\n]+/).map((item) => {
        const time = getDelayTime(lastTime)
        lastTime = time
        return {
          address: item.split(',')[0],
          amount: item.split(',')[1],
          memo: item.split(',')[2],
          delay: time,
        }
      })
    )
  }
  const startWithdraw = () => {
    Modal.confirm({
      okText: '我已确认',
      title: `你将从 ${cex} 交易所提取 ${coin} 币到 ${network} 链上，请确认交易所支持该链该币，且该链能收到此币种，否则将丢失你的 ${coin} `,
      width: 600,
      onOk: async () => {
        setResultList(
          resultList.map((_) => {
            const { address, amount, delay, memo } = _
            withdrawFromBinance(
              {
                cex: cex,
                delay,
                address,
                amount,
                coin,
                network,
                apiKey,
                secretKey,
                passphrase,
                memo,
              },
              (res) => {
                if (res === null) successWtithdraw(address)
                else errorWtithdraw(address, res)
              }
            )
            return {
              ..._,
              status: 0,
            }
          })
        )
      },
    })
  }
  const successWtithdraw = (addr) => {
    setResultList(
      resultList.map((_) => {
        if (_.address === addr) _.status = 1
        return _
      })
    )
  }
  const retryWithdraw = (data) => {
    if (!apiKey || !secretKey) {
      message.error(
        `ApiKey或SecretKey${
          cex === 'Bitget' || cex === 'Okx' ? '或Passphrase' : ''
        }未设置，请先返回设置`
      )
      return
    }
    withdrawFromBinance(
      {
        cex: data.cex,
        delay: 0,
        address: data.address,
        amount: data.amount,
        coin: data.coin,
        network: data.network,
        apiKey,
        secretKey,
        passphrase,
      },
      (res) => {
        if (res === null) message.info('交易已成功提交，请刷新数据查看')
        else message.info(`交易发送失败，${JSON.stringify(res)}`)
      }
    )
  }
  const errorWtithdraw = (addr, err) => {
    setResultList(
      resultList.map((_) => {
        if (_.address === addr) {
          _.status = 2
          _.errMsg = err
        }
        return _
      })
    )
  }
  const toggleWithdrawRecordPage = () => {
    setWithdrawRecordVisible(!withdrawRecordVisible)
  }
  const refreshRecords = () => {
    if (pageNum === 1 && pageSize === 20) {
      requestRecords()
    }
    setPageNum(1)
    setPageSize(20)
  }

  const changeCex = (e) => {
    setCex(e.target.value)
  }
  return (
    <div className="page">
      <DocLink
        text={`交易所提币使用说明`}
        link={'https://docs.cryptoweb3.tools/jiao-yi-suo-pi-liang-ti-xian'}
      />
      <div className="main withdraw_page">
        <div className="modo">
          <Radio.Group value={cex} onChange={changeCex}>
            {!withdrawRecordVisible && (
              <Space>
                <Radio value={'Binance'}>币安</Radio>
                <Radio value={'Okx'}>欧易</Radio>
                <Radio value={'Mexc'}>MEXC</Radio>
                <Radio value={'Bybit'}>ByBit</Radio>
                <Radio value={'gate'}>Gate</Radio>
                <Radio value={'Bitget'}>
                  Bitget
                  {(cex === 'Bitget' ||
                    cex === 'Bybit' ||
                    cex === 'Mex' ||
                    cex === 'gate') && (
                    <span>
                      （使用 {cex} 提币前，需要先在
                      <a
                        href={
                          {
                            Bitget:
                              'https://www.bitget.com/zh-CN/asset/address',
                            Bybit:
                              'https://www.bybit.com/user/assets/money-address',
                            Mexc: 'https://www.mexc.com/zh-CN/user/withdraw-address',
                            gate: 'https://www.gate.io/zh/myaccount/withdraw_address',
                          }[cex]
                        }
                        target="_blank"
                      >
                        提币地址簿添加通用地址
                      </a>
                      ）
                    </span>
                  )}
                </Radio>
              </Space>
            )}
          </Radio.Group>

          <div>
            {withdrawRecordVisible && (
              <Button onClick={refreshRecords} type="dashed">
                刷新
              </Button>
            )}
            &nbsp;
            <Button onClick={toggleWithdrawRecordPage} type="dashed">
              {withdrawRecordVisible ? '返回' : '提币记录'}
            </Button>
          </div>
        </div>
        <Divider />
        {withdrawRecordVisible ? (
          <div>
            <Table
              dataSource={records}
              size="small"
              rowKey={(_) => `${_.address}_${_.executeTime}`}
              pagination={{
                current: pageNum,
                pageSize: pageSize,
                total: size,
                onChange: (page, pageSize) => {
                  setPageNum(page)
                  setPageSize(pageSize)
                },
              }}
              columns={[
                {
                  title: '提币地址',
                  dataIndex: 'address',
                },
                {
                  title: '金额',
                  dataIndex: 'amount',
                },
                {
                  title: '币种',
                  dataIndex: 'coin',
                },
                {
                  title: '网络',
                  dataIndex: 'network',
                },
                {
                  title: '执行时间',
                  dataIndex: 'executeTime',
                },
                {
                  title: '平台',
                  dataIndex: 'cex',
                },
                {
                  title: '状态',
                  dataIndex: 'sendStatus',
                  render: (sendStatus, record) => {
                    return [
                      [
                        <Tag color="yellow">等待执行</Tag>,
                        <Tag color="green">提币成功</Tag>,
                        <Tooltip title={record.sendResult} defaultOpen={true}>
                          <Tag color="red">提币失败</Tag>
                        </Tooltip>,
                      ][sendStatus - 1] || '-',
                      sendStatus === 3 && (
                        <Button
                          size="small"
                          onClick={() => retryWithdraw(record)}
                        >
                          重试
                        </Button>
                      ),
                    ]
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className="">
            <div className="body">
              <div className="input_box">
                <div className="content">
                  <p>
                    {!!errorRow.length && (
                      <Tag color="red">{`格式错误，请参照案例标准填写`}</Tag>
                    )}
                  </p>
                  <TextArea
                    rows={12}
                    value={addressAmount}
                    placeholder={`一行一个地址`}
                    onChange={(e) => changeAddressAccount(e.target.value)}
                  />
                </div>
              </div>
              <div className="withdraw_info">
                <div className="amount">
                  <b>金额：</b>
                  <InputNumber
                    min={0}
                    value={minAmount}
                    placeholder="最小随机金额"
                    onChange={setMinAmount}
                  />
                  <InputNumber
                    min={0}
                    value={maxAmount}
                    placeholder="最大随机金额"
                    onChange={setMaxAmount}
                  />
                  <InputNumber
                    min={0}
                    step={1}
                    value={decimal}
                    placeholder="小数位数"
                    onChange={setDecimal}
                  />
                  <Button
                    type="primary"
                    onClick={sendAmount}
                    disabled={
                      !addressAmount || !minAmount || !maxAmount || !decimal
                    }
                  >
                    设置金额
                  </Button>
                </div>
                <div className="delay">
                  <Radio.Group
                    onChange={(e) => {
                      setTimeMode(e.target.value)
                      setMaxAmount('')
                      setMinAmount('')
                    }}
                    value={timeMode}
                  >
                    <Radio value={1}>间隔模式</Radio>
                    <Radio value={2}>区间随机</Radio>
                  </Radio.Group>
                  {timeMode === 1 && (
                    <div className="time_mode2">
                      <b>每笔交易间隔</b>
                      <InputNumber
                        min={0}
                        step={1}
                        value={minDelayTime}
                        placeholder="最小间隔"
                        onChange={setMinDelayTime}
                      />
                      <InputNumber
                        min={0}
                        step={1}
                        value={maxDelayTime}
                        placeholder="最大间隔"
                        onChange={setMaxDelayTime}
                      />
                      <b>秒</b>
                    </div>
                  )}
                  {timeMode === 2 && (
                    <div className="time_mode2">
                      <b>未来</b>
                      <InputNumber
                        min={0}
                        step={1}
                        value={maxDelayTime}
                        onChange={setMaxDelayTime}
                      />
                      <b>秒内随机</b>
                    </div>
                  )}
                </div>
                <div className="select">
                  <b>币：</b>
                  <Select defaultValue={coin} onChange={handleChangeCoin}>
                    {BinanceCoins.map((item) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      )
                    })}
                  </Select>
                </div>
                <div className="select">
                  <b>链：</b>
                  <Select defaultValue={network} onChange={handleChangeNetwork}>
                    {BinanceNetworks.map((item) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      )
                    })}
                  </Select>
                </div>
                <span className="tip">
                  提币前请按左上角教程设置白名单IP，使用完后请及时删除ApiKey，网站不会存储用户任何密钥信息
                </span>
                {(cex === 'Bitget' || cex === 'Okx') && (
                  <Input
                    placeholder="Passphrase"
                    value={passphrase}
                    onChange={handleChangePassphrase}
                  />
                )}
                <Input
                  placeholder="API Key"
                  value={apiKey}
                  onChange={handleChangeApiKey}
                />
                <Input
                  placeholder="Secret Key"
                  value={secretKey}
                  onChange={handleChangeSecretKey}
                />
                <Button
                  type="primary"
                  onClick={confirmInfo}
                  className="submit"
                  disabled={
                    !apiKey || !secretKey || !addressAmount || errorRow.length
                  }
                >
                  确认提币信息
                </Button>
              </div>
            </div>
            <Divider />
            <div className="list">
              <div className="action">
                <Button type="primary" onClick={startWithdraw}>
                  开始提币
                </Button>
              </div>
              <Table
                bordered
                size="small"
                dataSource={resultList}
                rowKey="address"
                columns={[
                  {
                    title: '地址',
                    dataIndex: 'address',
                  },
                  {
                    title: `金额（共计${
                      resultList.reduce(
                        (a, c) => a + Number(c.amount * 10 ** 6),
                        0
                      ) /
                      10 ** 6
                    }）`,
                    dataIndex: 'amount',
                    sorter: (a, b) => a.amount - b.amount,
                  },
                  {
                    title: `状态`,
                    dataIndex: 'status',
                    render: (status, record) => {
                      return {
                        undefined: '-',
                        0: <Spin size="small" />,
                        1: (
                          <Tag color="green">
                            已添加到任务队列，可在提现记录中查看
                          </Tag>
                        ),
                        2: (
                          <Tooltip title={record.errMsg} defaultOpen={true}>
                            <Tag color="red">失败</Tag>
                          </Tooltip>
                        ),
                      }[status]
                    },
                  },
                ]}
                pagination={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default WithDrawFromExchange
