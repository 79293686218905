const chainConfig = {
  1: {
    name: 'Ethereum Mainnet',
    rpc: `https://rpc.ankr.com/eth`,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    chainId: 1,
    apiUrl: 'https://api.etherscan.io',
    explorer: 'https://etherscan.io',
  },
  42161: {
    name: 'Arbitrum One',
    rpc: `https://arb1.arbitrum.io/rpc`,
    nativeCurrency: { name: 'Arb Ether', symbol: 'ArbETH', decimals: 18 },
    chainId: 42161,
    apiUrl: 'https://api.arbiscan.io',
    explorer: 'https://arbiscan.io',
    noEip1559: true,
  },
  10001: {
    name: 'Etherem PoW',
    rpc: `https://mainnet.ethereumpow.org`,
    nativeCurrency: { name: 'ETHW', symbol: 'ETHW', decimals: 18 },
    chainId: 10001,
    apiUrl: '',
    explorer: 'https://mainnet.ethwscan.com/',
    noEip1559: true,
  },
  3: {
    name: 'Ethereum Ropsten',
    rpc: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Ropsten Ether', symbol: 'ROP', decimals: 18 },
    chainId: 3,
    apiUrl: 'https://api-ropsten.etherscan.io',
    explorer: 'https://ropsten.etherscan.io',
  },
  4: {
    name: 'Ethereum Rinkeby',
    rpc: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Rinkeby Ether', symbol: 'RIN', decimals: 18 },
    chainId: 4,
    apiUrl: 'https://api-rinkeby.etherscan.io',
    explorer: 'https://rinkeby.etherscan.io',
  },
  5: {
    name: 'Ethereum Goerli',
    rpc: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Goerli Ether', symbol: 'GOE', decimals: 18 },
    chainId: 5,
    apiUrl: 'https://api-goerli.etherscan.io',
    explorer: 'https://goerli.etherscan.io',
  },
  42: {
    name: 'Ethereum Kovan',
    rpc: 'https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Kovan Ether', symbol: 'Kovan', decimals: 18 },
    chainId: 5,
    apiUrl: 'https://api-kovan.etherscan.io',
    explorer: 'https://kovan.etherscan.io',
  },
  56: {
    name: 'Binance Mainnet',
    rpc: `https://rpc.ankr.com/bsc`,
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    chainId: 56,
    apiUrl: 'https://api.bscscan.com',
    explorer: 'https://bscscan.com',
    noEip1559: true,
  },
  4200: {
    name: 'Merlin Mainnet',
    rpc: `https://rpc.merlinchain.io`,
    nativeCurrency: {
      name: 'BTC',
      symbol: 'BTC',
      decimals: 18,
    },
    chainId: 4200,
    apiUrl: 'https://scan.merlinchain.io/api-docs',
    explorer: 'https://scan.merlinchain.io',
    noEip1559: true,
  },
  97: {
    name: 'Binance Testnet',
    rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'tBNB',
      decimals: 18,
    },
    chainId: 97,
    apiUrl: 'https://api-testnet.bscscan.com',
    explorer: 'https://testnet.bscscan.com',
    noEip1559: true,
  },
  137: {
    name: 'Polygon Mainnet',
    rpc: 'https://polygon-rpc.com/',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    chainId: 137,
    apiUrl: 'https://api.polygonscan.com',
    explorer: 'https://polygonscan.com',
  },

  // 波场链 信息
  TRON: {
    name: 'TRON Mainnet',
    apiUrl: 'https://api.trongrid.io',
    explorer: 'https://shasta.tronscan.org',
  },
}

export default chainConfig
