import React, { useState } from 'react'
import { Button, Input, message, Select, Modal } from 'antd'
import Role from '../../constant/Role'
import { WalletReg } from '../../constant/Regular'

import '../../styles/admin_page/AddAccounts.css'
import { addMember } from '../../network/site'

const { TextArea } = Input
const { Option } = Select

const options = [
  { key: Role.MemberMonth, txt: `月度会员` },
  { key: Role.MemberYear, txt: `年度会员` },
  { key: Role.MemberTrial, txt: `三天体验` },
]

function AddAccounts() {
  const [role, setRole] = useState(Role.MemberMonth)
  const [accounts, setAccounts] = useState('')

  const handleChange = (value) => {
    setRole(value)
  }
  const handleAddressChange = (e) => {
    const value = e.target.value
    setAccounts(value)
  }
  const handleAdd = async () => {
    const _account = accounts.split(/[(\r\n)\r\n]+/).filter((_) => !!_)
    for (let i = 0; i < _account.length; i++) {
      const addr = _account[i]
      if (!WalletReg.test(addr)) {
        message.error(`错误的钱包地址: ${addr}`)
        return
      }
    }

    const roleTxt = options.find((_) => _.key === role).txt

    Modal.confirm({
      okText: '提交',
      title: `将为如下账号添加 [${roleTxt}] 权限`,
      content: accounts.toLocaleUpperCase(),
      width: 600,
      onOk: async () => {
        const hide = message.loading('正在添加账号...', null)
        try {
          const data = await addMember({
            walletList: _account,
            roleName: role,
          })
          hide()
          if (data === null) {
            message.success('添加成功')
            setAccounts('')
          }
        } catch (err) {
          message.error('添加失败')
        }
      },
    })
  }

  return (
    <div className="page">
      <div className="add_account_page">
        <div className="title">添加用户:</div>
        <TextArea
          value={accounts}
          placeholder="请输入会员地址, 一行一个地址"
          rows={6}
          onChange={handleAddressChange}
        />
        <div className="action">
          <Select onChange={handleChange} value={role} style={{ width: 200 }}>
            {options.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.txt}
                </Option>
              )
            })}
          </Select>
          <Button type={'primary'} onClick={handleAdd}>
            确认
          </Button>
        </div>
      </div>
    </div>
  )
}
export default AddAccounts
