import React, { useState, useContext, useEffect } from 'react'
import { ethers } from 'ethers'
import {
  Divider,
  InputNumber,
  Button,
  Input,
  message,
  Tag,
  Popover,
  Table,
  Switch,
  Radio,
  Steps,
  Modal,
  Spin,
} from 'antd'
import {
  CheckCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { WalletReg, PrivateKeyReg } from '../constant/Regular'
import CoinInfoABI from '../constant/ABI/CoinInfo'
import DocLink from '../component/DocLink'
import '../styles/TransferTokens.css'
import examplePng from '../assets/img/1.png'
import { DISPERSE } from '../constant/contract'

import { Context } from '../context'
import chainConfig from '../config/chainConfig'
import CustomProvider from '../component/CustomProvider'
import { add0xPrefix, amountToStrByDecimals, str2hex, sleep } from '../tools'
import { unlimitedAmount } from '../constant/String'
import {
  Keypair,
  PublicKey,
  Transaction,
  SystemProgram,
  sendAndConfirmTransaction,
} from '@solana/web3.js'

const { Step } = Steps
const { TextArea } = Input
let wallet
const abiStr = JSON.stringify(CoinInfoABI)

function TransferTokens() {
  const { chainId } = useContext(Context)
  const [provider, setProvider] = useState(null)

  const [step, setStep] = useState(1)
  const [tokenAddress, setTokenAddress] = useState()
  const [tokenLoading, setTokenLoading] = useState(false)
  const [tokenName, setTokenName] = useState(
    chainConfig[chainId]?.nativeCurrency.symbol
  )
  const [decimals, setDecimals] = useState(18)
  const [sameAmount, setSameAmount] = useState()
  const [addressAmount, setAddressAmount] = useState(``)
  const [errorRow, setErrorRow] = useState([])
  const [modeValue, setModeValue] = useState(2)
  const [isSolana, setIsSolana] = useState()

  // step2
  const fee =
    {
      1: 100,
      56: 6,
      4200: 0.05,
    }[chainId] || 100
  const [maxFee, setMaxFee] = useState(fee)
  const [priorityFee, setPriorityFee] = useState(2)
  const [tableData, setTableData] = useState([])
  const [useKey, setUseKey] = useState(true)
  const [privateKey, setPrivateKey] = useState()
  const [balance, setBalance] = useState()

  // step3
  const [current, setCurrent] = useState(0)
  const [approvedStatus, setApprovedStatus] = useState(0)
  const [txFailed, setTxFailed] = useState(false)

  useEffect(() => {
    if (step === 1) {
      setMaxFee(fee)
      setPriorityFee(2)
      setTableData([])
      setPrivateKey()
      setBalance()
    }
    if (step === 2 || step === 1) {
      setCurrent(0)
      setApprovedStatus(0)
      setTxFailed(false)
    }
  }, [step])

  useEffect(() => {
    setBalance()
  }, [tokenAddress])

  const handleProvider = (customProvider) => {
    if (customProvider?._rpcEndpoint?.includes('solana')) {
      setIsSolana(true)
      setTokenName('SOL')
    } else {
      setIsSolana(false)
    }
    setProvider(customProvider)
  }

  const handleAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的合约地址',
      })
      return
    }
    address && getTokenName(address)
    setTokenAddress(address)
  }

  const getTokenName = async (address) => {
    const contract = new ethers.Contract(address, abiStr, provider)
    const hide = message.loading('代币信息加载中...', null)
    setTokenLoading(true)
    try {
      const decimals = await contract.decimals()
      const name = await contract.symbol()
      setTokenName(name)
      setDecimals(decimals)
    } catch (error) {
      setTokenName('错误地址')
      setDecimals(0)
    }
    setTokenLoading(false)
    hide()
  }

  const sendSameAmount = () => {
    if (!sameAmount) {
      message.error('请输入发送金额')
      return
    }
    if (!errorRow.length && addressAmount) {
      let arr = addressAmount.split(/[(\r\n)\r\n]+/).filter((_) => _)
      arr = arr.map((item) => {
        item = item.includes(',')
          ? item.replace(/(\d+)(\.\d+)?$/, sameAmount)
          : `${item},${sameAmount}`
        return item
      })
      setAddressAmount(arr.join('\n'))
    }
  }

  const changeAddressAccount = (value) => {
    setAddressAmount(value)
    if (!value) {
      setErrorRow([])
      return
    }
    const arr = value.split(/[(\r\n)\r\n]+/).filter((_) => _)
    const _erroeRow = []
    const regex = new RegExp(/(\,(\d+)(\.\d+)?)?$/)
    arr.forEach((row, index) => {
      if (!regex.test(row) && row) {
        _erroeRow.push(index + 1)
      }
    })
    setErrorRow(_erroeRow)
  }

  // 下一步
  const nextStep = () => {
    let err = false
    addressAmount.split(/[(\r\n)\r\n]+/).forEach((item) => {
      if (!item.includes(',') || /,0$/.test(item)) {
        message.error(`地址 ${item} 未设置金额!`)
        err = true
      }
    })
    if (err) return
    setStep(2)
    setTableData(
      addressAmount.split(/[(\r\n)\r\n]+/).map((item) => {
        return {
          address: item.split(',')[0],
          amount: item.split(',')[1],
        }
      })
    )
    // 如果有转移其他token地址，获取一下是否有授权给批量转移合约
    if (tokenAddress) {
      const contract = new ethers.Contract(tokenAddress, abiStr, provider)
      contract
        .allowance(window.ethereum.selectedAddress, DISPERSE)
        .then((res) => {
          setApprovedStatus(res > 0 ? 2 : 0)
        })
    }
  }

  // 删除一条地址
  const delAddress = (record) => {
    setTableData(tableData.filter((_) => _.address !== record.address))
  }

  const getBalanceOfSolWallet = async (e) => {
    const key = e.target.value
    setPrivateKey(key)
    if (!key) return
    const { base58 } = ethers.utils
    const keypair = Keypair.fromSecretKey(base58.decode(key))
    const res = await provider.getBalance(keypair.publicKey)
    setBalance(res / 10 ** 9)
  }

  const updateDesignatedWallet = (e) => {
    if (isSolana) {
      getBalanceOfSolWallet(e)
      return
    }
    let key = add0xPrefix(e.target.value)
    if (!PrivateKeyReg.test(key) && key !== '') {
      message.error({
        key: 'privateKeyError',
        content: '请输入正确的私钥',
      })
      return
    }
    setPrivateKey(key)
    if (!key) return
    wallet = new ethers.Wallet(key, provider)
    const setBalanceOfWallet = (_balance) => {
      setBalance(
        ethers.utils.formatUnits(`${_balance}`, 'ether') * 10 ** (18 - decimals)
      )
    }
    if (!tokenAddress) {
      wallet.getBalance().then(setBalanceOfWallet)
    } else {
      const contract = new ethers.Contract(tokenAddress, abiStr, provider)
      contract.balanceOf(wallet.address).then().then(setBalanceOfWallet)
    }
  }

  const onChangeUseKey = (value) => {
    if (isSolana) {
      setUseKey(true)
    }
    setUseKey(value)
  }

  // 调用metamask发送eth和其他token代币
  const matemaskSendToken = async ({ sendFun, token }) => {
    const iface = new ethers.utils.Interface(CoinInfoABI)
    let total = 0
    let params = [
      tableData.map((_) => _.address),
      tableData.map((_) => {
        total += _.amount * 10 ** 9
        return amountToStrByDecimals(_.amount, 10 ** decimals)
      }),
    ]
    if (token) params = [tokenAddress, ...params]
    const data = iface.encodeFunctionData(sendFun, params)
    const transactionParameters = {
      to: DISPERSE,
      from: window.ethereum.selectedAddress,
      value: token
        ? 0
        : str2hex(amountToStrByDecimals(total / 10 ** 9, 10 ** decimals)),
      data: data,
    }
    try {
      const hash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      setCurrent(1)
      message.success('交易已到pending池,等待被矿工打包')
      await sleep(1000)
      provider
        .getTransaction(hash)
        .then((tx) => {
          tx.wait()
            .then(() => setCurrent(3))
            .catch((err) => {
              console.error(err)
              setTxFailed(true)
              setCurrent(3)
            })
        })
        .catch((error) => {
          console.error(error)
          message.error(JSON.stringify(error))
        })
    } catch (error) {
      message.error('用户拒绝发送交易!')
      setStep(2)
    }
  }

  const matemaskApproved = async () => {
    const iface = new ethers.utils.Interface(CoinInfoABI)
    const data = iface.encodeFunctionData('approve', [
      DISPERSE,
      unlimitedAmount,
    ])
    const transactionParameters = {
      to: tokenAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    }
    try {
      const hash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      message.success('交易已到pending池,等待被矿工打包')
      await sleep(1000)
      provider
        .getTransaction(hash)
        .then((tx) => {
          tx.wait().then(() => {
            // 授权成功，自动弹起转账交易
            setApprovedStatus(2)
            matemaskSendToken({
              sendFun: 'disperseToken',
              token: tokenAddress,
            })
          })
        })
        .catch((error) => {
          console.error(error)
          message.error(JSON.stringify(error))
        })
    } catch (error) {
      message.error('用户拒绝发送交易!')
      setApprovedStatus(0)
      setStep(2)
    }
  }

  const sendToken = async () => {
    if (useKey && !privateKey) {
      message.error('请填写转出私钥地址!')
      return
    }
    let gasInfo = {}
    if (chainConfig[chainId]?.noEip1559) {
      gasInfo = { gasPrice: maxFee * 10 ** 9 }
    } else {
      gasInfo = {
        maxFeePerGas: maxFee * 10 ** 9,
        maxPriorityFeePerGas: priorityFee * 10 ** 9,
      }
    }
    if (modeValue === 2) {
      Modal.confirm({
        okText: '确认发送',
        title: `请再次确认提笔地址与金额`,
        width: 600,
        onOk: async () => {
          setTableData(
            tableData.map((_) => ({
              ..._,
              tx: undefined,
              status: undefined,
            }))
          )
          setStep(6)
          startSendTransitions(gasInfo)
        },
      })
      return
    } else {
      setStep(3)
      // 发送主链上的代币
      if ([1, 3, 4, 5, 42, 56, 137, 10001].includes(chainId)) {
        // 发送eth
        if (!tokenAddress) {
          if (useKey) {
            sendTokenUseKey({
              sendFun: 'disperseEther',
              gasInfo,
            })
          } else {
            matemaskSendToken({
              sendFun: 'disperseEther',
            })
          }
          // 发送其他代币
        } else {
          if (useKey) {
            if (approvedStatus !== 2) {
              setApprovedStatus(1)
              approvedUseKey({ gasInfo })
            } else {
              sendTokenUseKey({
                sendFun: 'disperseToken',
                token: tokenAddress,
                gasInfo,
              })
            }
          } else {
            if (approvedStatus !== 2) {
              setApprovedStatus(1)
              matemaskApproved()
            } else {
              matemaskSendToken({
                sendFun: 'disperseToken',
                token: tokenAddress,
              })
            }
          }
        }
      }
    }
  }

  const selSuccess = () => {
    const arr = tableData.filter((_) => _.status !== 1)
    tempData = arr
    setTableData(arr)
  }

  let tempData = []
  const resHandle = (transaction, i) => {
    tempData[i].tx = transaction.hash
    setTableData(tempData)
    transaction
      .wait()
      .then(() => {
        tempData[i].status = 1
        setTableData(tempData)
      })
      .catch((error) => {
        tempData[i].status = 2
        setTableData(tempData)
      })
  }

  const errHandle = (err, i) => {
    message.error({
      key: `sendTxError`,
      content: JSON.stringify(err),
    })
    tempData[i].tx = '交易未发出'
    tempData[i].status = 2
    setTableData(tempData)
  }

  const startSendSolTransitions = async () => {
    const { base58 } = ethers.utils
    const keypair = Keypair.fromSecretKey(base58.decode(privateKey))
    tempData = []
    tempData = JSON.parse(JSON.stringify(tableData))
    for (let i = 0; i < tableData.length; i++) {
      if ((i + 1) % 2 === 0) await sleep(1000)
      const item = tableData[i]
      let transaction = new Transaction()
      console.log(base58.decode(item.address))
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: keypair.publicKey,
          toPubkey: new PublicKey(item.address),
          lamports: item.amount * 10 ** 9,
        })
      )
      sendAndConfirmTransaction(provider, transaction, [keypair])
        .then((res) => {
          if (res) {
            tempData[i].tx = res
            tempData[i].status = 1
          } else {
            tempData[i].tx = '发送交易失败'
            tempData[i].status = 2
          }
          setTableData(tempData)
        })
        .catch((err) => {
          console.log(err)
          tempData[i].tx = '发送交易失败'
          tempData[i].status = 2
        })
    }
  }

  const startSendTransitions = async (gasInfo) => {
    if (isSolana) {
      startSendSolTransitions()
      return
    }
    const wallet = new ethers.Wallet(privateKey, provider)
    const nonce = await wallet.getTransactionCount()
    tempData = []
    tempData = JSON.parse(JSON.stringify(tableData))
    for (let i = 0; i < tableData.length; i++) {
      const item = tableData[i]
      const nowNonce = nonce + i
      const balance = amountToStrByDecimals(item.amount, 10 ** decimals)
      if (tokenAddress) {
        const ABI = JSON.stringify(CoinInfoABI)
        let contract = new ethers.Contract(tokenAddress, ABI, wallet)
        let params = [item.address, `${balance}`]
        contract.estimateGas
          .transfer(...params)
          .then((gasLimit) => {
            contract
              .transfer(...params, { ...gasInfo, gasLimit, nonce: nowNonce })
              .then((_) => resHandle(_, i))
              .catch((_) => errHandle(_, i))
          })
          .catch((_) => errHandle(_, i))
      } else {
        wallet
          .sendTransaction({
            nonce: nowNonce,
            to: item.address,
            value: `0x${Number(balance).toString(16)}`,
            ...gasInfo,
          })
          .then((_) => resHandle(_, i))
          .catch((_) => errHandle(_, i))
      }
      if ((i + 1) % 5 === 0) await sleep(1000)
    }
  }

  const approvedUseKey = async ({ gasInfo }) => {
    const contract = new ethers.Contract(tokenAddress, abiStr, wallet)
    const params = [DISPERSE, unlimitedAmount]
    contract.estimateGas
      .approve(...params, gasInfo)
      .then((limit) => {
        contract
          .approve(...params, {
            ...gasInfo,
            gasLimit: Math.ceil(limit * 1.2),
          })
          .then((tx) => {
            tx.wait().then(() => {
              // 授权成功，自动发送转账交易
              setApprovedStatus(2)
              sendTokenUseKey({
                sendFun: 'disperseToken',
                token: tokenAddress,
                gasInfo,
              })
            })
          })
          .catch((error) => {
            console.error(error)
            message.error(JSON.stringify(error))
            setStep(2)
          })
      })
      .catch((error) => {
        console.error(error)
        message.error('预估gasLimit错误,合约无法被执行')
        setStep(2)
      })
  }

  const sendTokenUseKey = async ({ sendFun, token, gasInfo }) => {
    const disperseContract = new ethers.Contract(DISPERSE, abiStr, wallet)
    let total = 0
    let params = [
      tableData.map((_) => _.address),
      tableData.map((_) => {
        total += _.amount * 10 ** 9
        return amountToStrByDecimals(_.amount, 10 ** decimals)
      }),
    ]
    if (token) params = [tokenAddress, ...params]
    const override = {
      ...gasInfo,
      value: token ? 0 : amountToStrByDecimals(total / 10 ** 9, 10 ** decimals),
    }
    disperseContract.estimateGas[sendFun](...params, override)
      .then((limit) => {
        disperseContract[sendFun](...params, {
          ...override,
          gasLimit: Math.ceil(limit * 1.2),
        })
          .then((res) => {
            setCurrent(1)
            res
              .wait()
              .then(() => setCurrent(3))
              .catch((err) => {
                console.error(err)
                setTxFailed(true)
                setCurrent(3)
              })
          })
          .catch((error) => {
            console.error(11, error)
          })
      })
      .catch((error) => {
        console.error(error)
        message.error('预估gasLimit错误,合约无法被执行')
      })
  }

  const onModeChange = (e) => {
    setModeValue(e.target.value)
    if (e.target.value === 2) {
      setUseKey(true)
    }
  }

  const renderStrpProps = (i) => {
    let icon, title, description
    if (i === current) icon = <LoadingOutlined />
    if (i === 0) title = '发送交易'
    if (i === 1) title = '交易上链'
    if (i === 2) {
      title = txFailed ? '转账失败' : '转账完成'
      icon = txFailed && <CloseCircleOutlined style={{ color: '#b91c1c' }} />
      description = txFailed && (
        <a
          href={`https://etherscan.io/address/${wallet.address}`}
          target={'_blank'}
        >
          详情
        </a>
      )
    }
    return { title, icon, description }
  }

  const renderApprovedStatus = (i) => {
    return [
      <Tag icon={<CloseCircleOutlined />} color="default">
        未授权
      </Tag>,
      <Tag icon={<SyncOutlined spin />} color="warning">
        授权中
      </Tag>,
      <Tag icon={<CheckCircleOutlined />} color="success">
        已授权
      </Tag>,
    ][i]
  }

  return (
    <div className="page">
      <DocLink
        text={'批量转账使用说明'}
        link={'https://docs.cryptoweb3.tools/pi-liang-zhuan-yi-gui-ji-eth'}
      />
      {step === 1 && (
        <div className="transfer_tokens_page step1">
          <div className="top">
            <Input
              className="token_address_input"
              placeholder="代币地址,不填写默认为本链原生代币"
              value={tokenAddress}
              onChange={(e) => handleAddressChange(e.target.value)}
            />
            <div className="rpc">
              <CustomProvider onConfirm={handleProvider} />
            </div>
            <div className="token_name">{tokenName}</div>
          </div>
          <Divider />
          <div className="input_box">
            <div className="header">
              <div className="left">
                <h3>地址和金额</h3>
                <Popover
                  placement="right"
                  content={<img src={examplePng} alt="" />}
                >
                  <QuestionCircleOutlined />
                </Popover>
              </div>
              <div className="right">
                <InputNumber
                  min={0}
                  value={sameAmount}
                  placeholder="转账金额"
                  onChange={setSameAmount}
                />
                <Button
                  type="primary"
                  onClick={sendSameAmount}
                  disabled={!addressAmount}
                >
                  给每个钱包发送相同数量Token
                </Button>
              </div>
            </div>
            <div className="content">
              <TextArea
                rows={10}
                value={addressAmount}
                onChange={(e) => changeAddressAccount(e.target.value)}
              />
              <div className="bottom_action">
                <p>
                  {!!errorRow.length && (
                    <Tag color="red">{`格式错误，请参照案例标准填写`}</Tag>
                  )}
                </p>
                <Button
                  type="primary"
                  disabled={!!errorRow.length || !addressAmount || tokenLoading}
                  onClick={nextStep}
                >
                  下一步
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {[2, 3, 4, 5, 6].includes(step) && (
        <div className="transfer_tokens_page step2">
          <div className="mode">
            <Radio.Group onChange={onModeChange} value={modeValue}>
              <Radio value={2}>transfer</Radio>
              <Radio value={1} disabled={isSolana}>
                disperse
              </Radio>
            </Radio.Group>
            {modeValue === 1 && (
              <div className="suport_chain">
                注意 Disperse
                仅支持链：BSC，Polygon，ETH，ETHW，Ropsten，Kovan，Goerli，Rinkeby
              </div>
            )}
          </div>
          <div className="list">
            <div className="del_action">
              <h3>地址列表及转账金额</h3>
              {modeValue === 2 && (
                <Button danger type={'primary'} onClick={selSuccess}>
                  删除已成功钱包
                </Button>
              )}
            </div>
            <Table
              size="small"
              bordered
              dataSource={tableData}
              scroll={{ y: 340 }}
              rowKey="address"
              columns={[
                {
                  title: '地址',
                  dataIndex: 'address',
                  width: '70%',
                  render: (_address) => (
                    <a
                      href={`${chainConfig[chainId].explorer}/address/${_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_address}
                    </a>
                  ),
                },
                {
                  title: `金额(${tokenName})`,
                  dataIndex: 'amount',
                  width: '15%',
                },
                {
                  title: '操作',
                  key: 'action',
                  width: '15%',
                  render: (text, record) => (
                    // eslint-disable-next-line
                    <a
                      className="del"
                      href={void 0}
                      onClick={() => delAddress(record)}
                    >
                      删除
                    </a>
                  ),
                },
              ]}
              pagination={false}
            />
          </div>
          <div className="list use_designated_wallet">
            <div className="left">
              <b>使用指定钱包转出: </b>
              <Switch
                value={useKey}
                onChange={onChangeUseKey}
                disabled={modeValue === 2}
                checked={useKey}
              />
              {useKey && (
                <Input
                  value={privateKey}
                  placeholder={'输入转出地址的私钥'}
                  onChange={updateDesignatedWallet}
                  type="password"
                />
              )}
              {balance && (
                <div className="balance">
                  (余额:{Number(balance).toFixed(4)})
                </div>
              )}
              {useKey && !isSolana && (
                <div className="speed">
                  <div className="gas">
                    <Divider type={'vertical'} />
                    {chainConfig[chainId]?.noEip1559 ? (
                      <div className="max_fee">
                        <b>Gas Price:</b>
                        <InputNumber
                          min={0}
                          value={maxFee}
                          onChange={setMaxFee}
                        />
                      </div>
                    ) : (
                      [
                        <div className="max_fee" key="max_fee">
                          <div>MaxFee:</div>
                          <InputNumber
                            min={0}
                            value={maxFee}
                            onChange={setMaxFee}
                          />
                        </div>,
                        <div className="priority_fee" key="priority_fee">
                          <div>矿工小费:</div>
                          <InputNumber
                            min={0}
                            value={priorityFee}
                            onChange={setPriorityFee}
                          />
                        </div>,
                      ]
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="right">
              <Button
                onClick={() => {
                  setStep(1)
                }}
              >
                上一步
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  sendToken(true)
                }}
                disabled={!tableData.length}
              >
                下一步
              </Button>
            </div>
          </div>
          {[3, 4, 5].includes(step) && (
            <div className="step3">
              <Divider />
              {tokenAddress && (
                <div className="approve">
                  <div>
                    你需要授权
                    <a
                      href="https://etherscan.io/address/0xd152f549545093347a162dce210e7293f1452150"
                      target={'_blank'}
                    >
                      批量发送合约
                    </a>
                    帮你批量转移代币，它很安全（
                    <a
                      href="https://etherscan.io/address/0xd152f549545093347a162dce210e7293f1452150#code#L14"
                      target={'_blank'}
                    >
                      查看源码
                    </a>
                    ），无法在你不知情的情况下转移你的代币
                  </div>
                  {renderApprovedStatus(approvedStatus)}
                </div>
              )}
              {!(tokenAddress && approvedStatus !== 2) && (
                <div className="steps">
                  <Steps current={current}>
                    {[...Array(3).keys()].map((i) => {
                      return <Step key={i} {...renderStrpProps(i)} />
                    })}
                  </Steps>
                </div>
              )}
            </div>
          )}
          {step === 6 && (
            <div className="transfer_mode">
              <Table
                size="small"
                bordered
                dataSource={tableData}
                scroll={{ y: 340 }}
                rowKey="address"
                columns={[
                  {
                    title: '地址',
                    dataIndex: 'address',
                    render: (_address) => (
                      <a
                        onClick={() => {
                          navigator.clipboard.writeText(_address)
                          message.success('已复制')
                        }}
                      >
                        {_address}
                      </a>
                    ),
                  },
                  {
                    title: `金额(${tokenName})`,
                    dataIndex: 'amount',
                    width: '15%',
                  },
                  {
                    title: '交易Hash',
                    dataIndex: 'tx',
                    render: (tx) => {
                      if (tx === '') return '-'
                      if (tx === undefined) return <Spin size={'small'} />
                      return (
                        <a
                          onClick={() => {
                            navigator.clipboard.writeText(tx)
                            message.success('已复制')
                          }}
                        >
                          {tx.substring(0, 10) + '...'}
                        </a>
                      )
                    },
                  },
                  {
                    title: '状态',
                    dataIndex: 'status',
                    render: (status) => {
                      return (
                        <div className="status">
                          {
                            [
                              <Spin size="small" />,
                              <Tag color="green">成功</Tag>,
                              <Tag color="red">失败</Tag>,
                              <span>-</span>,
                            ][status || 0]
                          }
                        </div>
                      )
                    },
                  },
                ]}
                pagination={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default TransferTokens
