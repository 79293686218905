import { getUserToken, sleep } from '../tools'
import webSiteConfig from '../config/webSiteConfig'

let reTry = 5

export async function openSocketConnection(resFun) {
  let socket = new WebSocket(webSiteConfig.socketURL) //
  socket.addEventListener('open', () => {
    const token = getUserToken()
    socket.send(`42['login', '${token}']`)
    resFun && resFun(true)
    reTry = 5
  })
  socket.addEventListener('close', async () => {
    reTry--
    if (reTry === 0) window.location.href = '/login'
    console.error('socket连接关闭,重试中......')
    resFun && resFun(false)
    await sleep(1000)
    await openSocketConnection(resFun)
  })
  socket.addEventListener('error', async () => {
    console.error('连接发生错误,重试中......')
    resFun && resFun(false)
    await sleep(1000)
    await openSocketConnection(resFun)
  })
  window.socket = socket
}
