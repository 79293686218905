import React, { useEffect, useState } from 'react'
import { Layout, Menu, Button, message } from 'antd'
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import {
  MenuOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  WalletOutlined,
  InfoCircleOutlined,
  CrownOutlined,
  ToolOutlined,
  BookOutlined,
  FileImageOutlined,
  CloudServerOutlined,
  SafetyCertificateOutlined,
  BarsOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { hideStr, getChainNameById, getCurrentAddr } from './tools/index'
import { getUserToken, DecryptAES } from './tools'
import { openSocketConnection } from './network/socket'
import Role from './constant/Role'
import GasAndEthPrice from './component/GasAndEthPrice'
import './App.css'

// page
// import OpenSeaFloor from './page/OpenSeaFloor'
import MintBy0xData from './page/MintBy0xData'
import ContractMint from './page/ContractMint'
// import MintLive from './page/MintLive'
import AddAccounts from './page/admin_page/AddAccounts'
import Home from './page/Home'
import FilterWallet from './page/FilterWallet'
import Feedback from './page/Feedback'
import BecomeVip from './page/BecomeVip'
// import WaterMark from './page/WaterMark'
// import BulkSetName from './page/BulkSetName'
import RPCPage from './page/RPC'
import NFTCollection from './page/NFTCollection'
import NFTDistribution from './page/NFTDistribution'
import NFTBuy from './page/NFTBuy'
import NotFindPage from './page/NotFind'
import PersonalCenter from './page/PersonalCenter'
// import MakeOrder from './page/MakeOrder'
import Login from './page/Login'
import CreateWallets from './page/CreateWallets'
import ProofStr from './page/ProofStr'
import TransferTokens from './page/TransferTokens.js'
import CollectTokens from './page/CollectTokens.js'
import QueryTokenBalance from './page/QueryTokenBalance.js'
import WithDrawFromExchange from './page/WithDrawFromExchange.js'
import { Context } from './context'
import webSiteConfig from './config/webSiteConfig'
import logo from './assets/logo_white.png'
import logo_txt from './assets/logo_txt.png'

import { signUp, getLoginNonce, getUserInfo } from './network/site'

const { SubMenu } = Menu
const { Header, Content } = Layout
let checkLoginStatusTimer

const setMenuIcon = (iconStr) => {
  const icon = {
    CloudServerOutlined: <CloudServerOutlined />,
    BookOutlined: <BookOutlined />,
    UserOutlined: <UserOutlined />,
    LaptopOutlined: <LaptopOutlined />,
    WalletOutlined: <WalletOutlined />,
    NotificationOutlined: <NotificationOutlined />,
    SettingOutlined: <SettingOutlined />,
    InfoCircleOutlined: <InfoCircleOutlined />,
    CrownOutlined: <CrownOutlined />,
    ToolOutlined: <ToolOutlined />,
    FileImageOutlined: <FileImageOutlined />,
    SafetyCertificateOutlined: <SafetyCertificateOutlined />,
    BarsOutlined: <BarsOutlined />,
    TeamOutlined: <TeamOutlined />,
  }[iconStr] || <MenuOutlined />
  return icon
}

const App = () => {
  const [userInfo, setUserInfo] = useState(null)
  const [nextBlockGasPrice, setNextBlockGasPrice] = useState(null)
  const [ethPrice, setEthPrice] = useState(0)
  const [account, setAccount] = useState('')
  const [chainId, setChainId] = useState('')
  const [socketConnected, setSocketConnected] = useState(false)
  const location = useLocation()
  const { pathname, search } = location
  const navigate = useNavigate()

  useEffect(() => {
    connectWallet()
    initUserInfo()
    listenWalletChange()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userInfo?.roleList) {
      openSocketConnection((res) => {
        setSocketConnected(res)
      })
      checkLoginStatusTimer = setInterval(checkTokenStatus, 5000)
    }
    return () => {
      clearInterval(checkLoginStatusTimer)
    }
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(() => {
    const isAuthRoute = webSiteConfig.authRouter.includes(pathname)
    const isCanAccessWhenLogin = webSiteConfig.canAccessWhenLogin.includes(
      pathname,
    )
    if (userInfo?.roleList && isCanAccessWhenLogin) return
    if (!userInfo?.roleList?.length && isAuthRoute) {
      navigate(`/login?redirect=${pathname}${search}`)
    }
    // eslint-disable-next-line
  }, [pathname])

  useEffect(() => {
    if (socketConnected) {
      window.socket.addEventListener('message', (event) => {
        try {
          const res = JSON.parse(DecryptAES(event.data))
          if (res.event === 'checkStatus') {
            if (pathname.includes('/login') || pathname === '/') return
            if (!res.data) {
              message.error('登陆失效，请重新登录')
              window.location.href = '/login'
            }
          }
        } catch (error) {
          console.error(error)
        }
      })
    }
    // eslint-disable-next-line
  }, [socketConnected])

  const checkTokenStatus = () => {
    const account = getCurrentAddr()
    const token = getUserToken()
    window.socket.send(`42['checkSatus', '${account}', '${token}']`)
  }

  const listenWalletChange = () => {
    window.ethereum.on('accountsChanged', function (accounts) {
      window.localStorage.setItem('selectedAddress', accounts[0].toLowerCase())
      setAccount(accounts[0])
      window.location.reload()
    })
    window.ethereum.on('chainChanged', function (chainId) {
      setChainId(Number(chainId))
      window.location.reload()
    })
  }

  const initUserInfo = async () => {
    const { user } = (await getUserInfo()) || {}
    setUserInfo(user)
    if (/^\/login/.test(pathname)) return
    if (user && user.roleList.length > 0) {
      const redirect = search.replace('?redirect=', '')
      redirect && navigate(redirect || pathname)
    }
  }

  const goPerson = () => {
    navigate('/PersonalCenter')
  }

  const connectWallet = async () => {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    })
    const account = accounts[0].toLowerCase()
    window.localStorage.setItem('selectedAddress', account)
    setAccount(account)
  }

  const updateUser = (user) => {
    setUserInfo(user)
  }

  const handleLogin = async () => {
    if (!account) {
      connectWallet()
      return
    }
    const nonce = await getLoginNonce({
      wallet: account,
    })
    const message = `I confirm that I am logging in myself.\nNonce: ${nonce}`
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [message, account],
    })
    const loginRes = await signUp({
      wallet: account,
      sign: signature,
    }) //登陆成功
    if (loginRes) {
      setUserInfo(loginRes.user)
      window.localStorage.setItem(
        ethers.utils.id(`${account.toLowerCase()}_token`),
        loginRes.token,
      )
      if (loginRes.user && loginRes.user.roleList.length > 0) {
        // 登陆成功，重新连接socket
        setSocketConnected(false)
        openSocketConnection((res) => {
          setSocketConnected(res)
        })
        const redirect = search.replace('?redirect=', '')
        navigate(redirect)
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  const setPage = (pageKey) => {
    const propChainId = chainId || Number(window.ethereum.networkVersion)
    const page = {
      PersonalCenter: <PersonalCenter />,
      RpcList: <RPCPage />,
      ProofStr: <ProofStr />,
      // BulkSetName: <BulkSetName chainId={propChainId} />,
      // OpenSeaFloor: <OpenSeaFloor />,
      ContractInteraction: <NFTBuy userInfo={userInfo} chainId={propChainId} />,
      // MakeOrder: <MakeOrder location={location} userInfo={userInfo} />,
      MintBy0xData: <MintBy0xData chainId={propChainId} />,
      ContractMint: <ContractMint chainId={propChainId} />,
      // MintLive: (
      //   <MintLive
      //     socketConnected={socketConnected}
      //     userInfo={userInfo}
      //     gasInfo={nextBlockGasPrice}
      //     ethPrice={ethPrice}
      //   />
      // ),
      AddAccounts: <AddAccounts />,
      FilterWallet: <FilterWallet />,
      NFTCollection: <NFTCollection chainId={propChainId} />,
      NFTDistribution: <NFTDistribution chainId={propChainId} />,
      GenerateWalletsInBatches: <CreateWallets />,
      TransferTokenInBatches: <TransferTokens />,
      CollectTokenInBatches: <CollectTokens chainId={propChainId} />,
      QueryTokenBalance: <QueryTokenBalance chainId={propChainId} />,
      WithDrawFromExchange: <WithDrawFromExchange chainId={propChainId} />,
      Feedback: <Feedback />,
      BecomeVip: <BecomeVip />,
      // WaterMark: <WaterMark />,
    }[pageKey] || <NotFindPage />
    return page
  }

  const setPageRoute = () => {
    const routes = []
    webSiteConfig.leftMenu.forEach((menu) => {
      if (menu.admin) {
        const roleList = userInfo?.roleList || []
        const isAdmin = roleList.some((_) => _ === Role.ADMIN)
        if (!isAdmin) return
      }
      routes.push(
        <Route
          key={menu.key}
          path={`/${menu.key}`}
          element={setPage(menu.key)}
        />,
      )
      if (menu.subMenu) {
        menu.subMenu.forEach((subMenu) => {
          routes.push(
            <Route
              key={subMenu.key}
              path={`/${menu.key}/${subMenu.key}`}
              element={setPage(subMenu.key)}
            />,
          )
        })
      }
      return
    })
    return (
      <Routes>
        <Route key="home" path="/" element={<Home />} />
        <Route key="login" path="/login" element={<Login />} />
        {routes}
        <Route path="*" element={<NotFindPage />} />
      </Routes>
    )
  }
  ////////////////////////////////////////////////////////////////////////////////

  const pathArr = pathname.split('/')
  const selectedMenuItem = pathArr[pathArr.length - 1]

  return (
    <Layout>
      <Header className="header">
        <div className="header_box">
          <div
            className="logo"
            onClick={() => {
              navigate('/')
            }}
          >
            <img src={logo} alt={'img'} />
            <img src={logo_txt} alt={'img'} />
          </div>
          <Menu
            className="left-menu"
            mode="horizontal"
            theme="dark"
            defaultSelectedKeys={[selectedMenuItem]}
          >
            {webSiteConfig.leftMenu.map((menu) => {
              if (menu.hiden) return false
              if (menu.admin) {
                const roleList = userInfo?.roleList || []
                const isAdmin = roleList.some((_) => _ === Role.ADMIN)
                if (!isAdmin) return false
              }
              return menu.subMenu ? (
                <SubMenu
                  key={menu.key}
                  icon={setMenuIcon(menu.icon)}
                  title={menu.text}
                >
                  {menu.subMenu &&
                    menu.subMenu.map((subMenu) => {
                      return (
                        <Menu.Item key={subMenu.key}>
                          <Link to={`/${menu.key}/${subMenu.key}`}>
                            {subMenu.text}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                </SubMenu>
              ) : (
                <Menu.Item icon={setMenuIcon(menu.icon)} key={menu.key}>
                  <Link to={`/${menu.key}`}>{menu.text}</Link>
                </Menu.Item>
              )
            })}
          </Menu>
          <div className="right">
            <div className="chain">
              {getChainNameById(
                chainId || Number(window.ethereum.networkVersion),
              )}
            </div>
            <Button
              type="primary"
              shape="round"
              icon={<WalletOutlined />}
              onClick={account ? goPerson : connectWallet}
            >
              {account ? <span>{hideStr(account, 5, 4)}</span> : '链接钱包'}
            </Button>
          </div>
        </div>
      </Header>
      <Layout>
        <Layout className="site-layout-main">
          <Content className="white-background site-layout-content">
            <Context.Provider
              value={{
                userInfo: userInfo,
                account: account,
                ethPrice: ethPrice,
                gasInfo: nextBlockGasPrice,
                chainId: chainId || Number(window.ethereum.networkVersion),
                handleLogin: handleLogin,
                updateUser: updateUser,
              }}
            >
              {setPageRoute()}
            </Context.Provider>
          </Content>
        </Layout>
      </Layout>
      <div className={`gas_eth_price`}>
        <GasAndEthPrice
          socketConnected={socketConnected}
          onGasChange={setNextBlockGasPrice}
          onEthPriceChange={setEthPrice}
        />
      </div>
    </Layout>
  )
}

export default App
