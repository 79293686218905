import { ethers } from 'ethers'
import abiDecoder from 'abi-decoder'

function getAbiByMethodStr(methodStr) {
  const abi = {
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }
  const params = methodStr
    .substring(methodStr.indexOf('(') + 1, methodStr.lastIndexOf(')'))
    .split(',')
    .filter((_) => _)
  abi.inputs = params.map((type, index) => ({
    internalType: type,
    name: `parse${index}`,
    type: type,
  }))
  abi.name = methodStr.substring(0, methodStr.indexOf('('))
  return [abi]
}

export function analysisInputData(data, methodStr) {
  if (!data || !methodStr) return ''
  const abi = getAbiByMethodStr(methodStr)
  abiDecoder.addABI(abi)
  const decodedData = abiDecoder.decodeMethod(data)
  return decodedData
}

export function getHexDataByMethodParams(methodStr, params) {
  const abi = getAbiByMethodStr(methodStr)
  const iface = new ethers.utils.Interface(abi)
  const data = iface.encodeFunctionData(
    abi[0].name,
    params.map((_) => _.value),
  )
  return data
}
