import React from 'react'
import { Result } from 'antd'

function NotFind() {
  return (
    <div className="page">
      <div className="page_404">
        <Result
          status="404"
          title="404"
          subTitle="对不起，你访问的页面不存在。"
        />
      </div>
    </div>
  )
}
export default NotFind
