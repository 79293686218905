import React, { useContext } from 'react'
import { Divider } from 'antd'
import DocLink from '../component/DocLink'

import { Context } from '../context'
import Role from '../constant/Role'

import '../styles/PersonalCenter.css'
import dayjs from 'dayjs'

function PersonalCenter() {
  const { userInfo = {} } = useContext(Context)
  const roleList = userInfo?.roleList || []
  const role = roleList[0]
  const isAdmin = role === Role.ADMIN

  return isAdmin ? (
    <div className="page">你拥有系统最高权限</div>
  ) : (
    <div className="page">
      <DocLink
        text={'账号升级'}
        link={'https://docs.cryptoweb3.tools/sheng-ji-hui-yuan'}
      />
      <div className="page_personal_center">
        <div className="section children_address">
          <div className="title_box">
            <p className="title">账号信息</p>
            {userInfo?.expireDate && (
              <p>
                到期时间：
                {dayjs(userInfo?.expireDate * 1000).format('YYYY-MM-DD')}
              </p>
            )}
          </div>
          <Divider />
          {!userInfo?.roleList?.length && (
            <div className="address_box">
              <p>
                升级为网站会员，不受任何限制，点此
                <a
                  href="https://docs.cryptoweb3.tools/sheng-ji-hui-yuan"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  升级账号
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default PersonalCenter
