const Contract = {
  OpenSeaConduit: '0x1E0049783F008A0085193E00003D00cd54003c71', // os售卖地址
  X2Y2Delegate: '0xf849de01b080adc3a814fabe1e2087475cf2e354', // x2y2售卖地址
  DISPERSE: '0xd152f549545093347a162dce210e7293f1452150', // 批量转发代币的合约地址
  BSC_SIG_TOKEN: '0xe3b1d32e43ce8d658368e2cbff95d57ef39be8a6', // BSC上的.bnb 域名地址
  BSC_SIG_RREGISTRAR: '0xc647d0EbEdDc9Eb8FFa01E992511cC5cBeF270D6', // BSC上的.bnb 域名地址
  ENS_TOKEN: '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85', // ETH上的.eth 域名地址
  ENS_REVERSE_RREGISTRAR: '0x084b1c3c81545d370f3634392de611caabff8148', // ETH上的.eth 域名解析
  MULTI_MINT: '0x72ae08158b40cd88607ffc5b4138185d8c87e724', // 3gm批量Mint合约
}

export const DISPERSE = '0xd152f549545093347a162dce210e7293f1452150'

export default Contract
