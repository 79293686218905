import React, { useRef } from 'react'
import { Divider, Button } from 'antd'
import CustomProvider from '../component/CustomProvider'
import DocLink from '../component/DocLink'

import '../styles/RPCPage.css'

let httpsRefs = [],
  wssRefs = []
const httpsRpcList = [
  `https://geth.mytokenpocket.vip`,
  `https://mainnet-eth.compound.finance`,
  `https://cloudflare-eth.com`,
  `https://api.mycryptoapi.com/eth`,
  `https://rpc.ankr.com/eth`,
  `https://mainnet-nethermind.blockscout.com`,
]

const wssRpcList = [
  `wss://eth-mainnet.alchemyapi.io/v2/9n3qYk_fd5oqkqUnelRtJKEYwC7E8ooR`,
  `wss://6deb370f0eb84353851658c2e598cd47.eth.ws.rivet.cloud/`,
  `wss://bfa9982081814020ad3db16ae5bcb187.eth.ws.rivet.cloud/`,
]

function RPCPage() {
  const testSpeed = () => {
    const runTest = (_) => _.current.testSpeed()
    httpsRefs.forEach(runTest)
    wssRefs.forEach(runTest)
  }

  httpsRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
  wssRefs = [useRef(), useRef(), useRef()]
  return (
    <div className="page">
      <DocLink
        text={'RPC节点使用说明'}
        link={'https://docs.cryptoweb3.tools/rpc-jie-dian'}
      />

      <div className="page page_rpc">
        <div>
          <Button onClick={testSpeed} type={'primary'}>
            一键测速
          </Button>
        </div>
        <Divider />
        <div className="rpc_box">
          <div className="rpc_list">
            <div className="head">HTTPS节点</div>
            {httpsRpcList.map((rpc, i) => {
              return (
                <CustomProvider
                  key={i}
                  rpc={rpc}
                  cRef={(e) => (httpsRefs[i].current = e)}
                />
              )
            })}
          </div>
          <div className="rpc_list">
            <div className="head">WSS节点</div>
            {wssRpcList.map((rpc, i) => {
              return (
                <CustomProvider
                  key={i}
                  rpc={rpc}
                  cRef={(e) => (wssRefs[i].current = e)}
                />
              )
            })}
          </div>
        </div>
        <Divider />
        <div className="tip">
          公共节点时常不稳定, 建议点击
          <a
            href="https://docs.cryptoweb3.tools/rpc-jie-dian"
            target="_blank"
            rel="noopener noreferrer"
          >
            RPC节点使用说明
          </a>
          , 获取自己的Rpc节点链接。
        </div>
      </div>
    </div>
  )
}
export default RPCPage
