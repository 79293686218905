import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { ethers } from 'ethers'
import { Button, Input, notification, message, Upload } from 'antd'
import { FileProtectOutlined, SettingOutlined } from '@ant-design/icons'
import { hidePrivateKeyStr } from '../constant/String'
import PrivateKeyGroup from './PrivateKeyGroup'

import '../styles/component/ResolvePrivateKey.css'
import { add0xPrefix, getKeysFromLocal } from '../tools'

const { TextArea } = Input
let wallets = []

function ResolvePrivateKey({
  precondition,
  onWalletsLoaded,
  onWalletsDataUpdate,
  onClear,
  provider,
  cRef,
}) {
  useImperativeHandle(cRef, () => ({
    resetWalletsStatus: resetWalletsStatus,
  }))
  const privateKeyGroupRef = useRef()
  const [disabledPrivateKey, setDisabledPrivateKey] = useState(false)
  const [hidePrivateStr, setHidePrivateStr] = useState('')
  const [privateKey, setPrivateKey] = useState('')
  const [inputKeys, setInputKeys] = useState('')

  useEffect(() => {
    if (privateKey) {
      validatePrivateKey()
    }
    return () => {
      wallets = []
    }
    // eslint-disable-next-line
  }, [privateKey])

  const onClickCommit = () => {
    if (!(!precondition || precondition())) {
      message.error(`请先填写必要数据！`)
      return
    }
    const seletedKeyGroups = privateKeyGroupRef.current.getSelectedGroups()
    const keysStr = seletedKeyGroups.map((_) => _.keys)
    setPrivateKey([inputKeys, ...keysStr].join('\n'))
  }

  const resetWalletsStatus = () => {
    wallets = []
    setDisabledPrivateKey(false)
    validatePrivateKey()
  }

  const handleKeysChange = (info) => {
    const reader = new FileReader()
    reader.onload = () => {
      const _list = getKeysFromLocal(reader.result)
      const groups = privateKeyGroupRef.current.getGroups()
      privateKeyGroupRef.current.setGroups([...groups, ..._list])
    }
    reader.readAsText(info)
    return false
  }

  const validatePrivateKey = async () => {
    const _keysStr = privateKey
    if (!_keysStr) return
    const keys = _keysStr.split(/[(\r\n)\r\n]+/).filter((_) => !!_)
    for (let i = 0; i < keys.length; i++) {
      let key = add0xPrefix(keys[i])
      let wallet
      try {
        wallet = new ethers.Wallet(key, provider)
      } catch (error) {
        notification.error({
          message: `请检查私钥${key}是否正确`,
        })
        continue
      }
      // 重复的钱包不再添加到数组
      if (!wallets.find((_) => _.wallet.address === wallet.address)) {
        wallets.push({ wallet, key })
        wallet.getTransactionCount().then((nonce) => {
          updateWalletsData(wallet.address, 'nonce', nonce)
        })
        wallet.getBalance().then((value) => {
          const _balance = ethers.utils.formatUnits(`${value}`, 'ether')
          updateWalletsData(wallet.address, 'balance', _balance)
        })
      }
    }
    setDisabledPrivateKey(true)
    setHidePrivateStr(
      privateKey.replace(/^([\w]{4})[\w]+([\w]{4})$/gm, hidePrivateKeyStr),
    )
    onWalletsLoaded && onWalletsLoaded(wallets)
  }

  const updateWalletsData = (addr, key, value) => {
    wallets = wallets.map((_) => {
      if (_.wallet.address === addr) {
        const newWallet = { ..._, [key]: value }
        onWalletsDataUpdate && onWalletsDataUpdate(newWallet)
        return newWallet
      } else {
        return _
      }
    })
  }

  const clearPrivateKey = () => {
    wallets = []
    setDisabledPrivateKey(false)
    setHidePrivateStr('')
    setPrivateKey('')
    setInputKeys('')
    onClear()
  }

  return (
    <div className="private_key_box">
      <Upload
        name="file"
        disabled={disabledPrivateKey}
        showUploadList={false}
        beforeUpload={handleKeysChange}
      >
        <div className={`upload ${disabledPrivateKey ? 'disable' : ''}`}>
          <FileProtectOutlined style={{ fontSize: 60, color: '#c3c3c3' }} />
          <p>解析加密的私钥文件</p>
        </div>
      </Upload>
      <div className="center_box">
        <TextArea
          rows={5}
          placeholder="私钥地址"
          disabled={disabledPrivateKey}
          value={hidePrivateStr || inputKeys}
          onChange={(e) => setInputKeys(e.target.value)}
        />
        <PrivateKeyGroup
          cRef={privateKeyGroupRef}
          disabled={disabledPrivateKey}
        />
      </div>
      <div className="private_action">
        <Button
          disabled={disabledPrivateKey}
          onClick={onClickCommit}
          type="primary"
        >
          验证
        </Button>
        <Button danger disabled={!disabledPrivateKey} onClick={clearPrivateKey}>
          清空
        </Button>
        <Button
          icon={<SettingOutlined />}
          onClick={() => privateKeyGroupRef.current.openManageGroupsModal()}
        >
          钱包组
        </Button>
      </div>
    </div>
  )
}
export default ResolvePrivateKey
