import React, { useContext } from 'react'
import { Context } from '../context'

function SimpleGas() {
  const { gasInfo } = useContext(Context)
  const blockPrices = gasInfo?.blockPrices[0]
  return (
    <div className="now_xf" style={{ fontWeight: 800, color: '#444' }}>
      <div style={{ fontWeight: 600 }}>Priority:</div>
      <div>
        {(blockPrices?.estimatedPrices[0]?.maxPriorityFeePerGas || 0).toFixed(
          1,
        )}
      </div>
      &nbsp;&nbsp;&nbsp;<div style={{ fontWeight: 600 }}>Max:</div>
      {(blockPrices?.estimatedPrices[0]?.maxFeePerGas || 0).toFixed(1)}
      &nbsp;&nbsp;&nbsp;<div style={{ fontWeight: 600 }}>Base:</div>
      {(blockPrices?.baseFeePerGas || 0).toFixed(1)}
    </div>
  )
}
export default SimpleGas
