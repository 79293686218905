import axios from 'axios'

import thirdKeyConfig from '../config/thirdKeyConfig'
import { formatNftGoItemToOrderData } from '../helper/MakeOrder'
import API from './api'
import Contract from '../constant/contract'

export const getNftGoAssets = async (data) => {
  const params = {
    address: data.walletAddress,
    contract_addresses: data.nftAddress,
  }
  const res = await axios({
    url: API.nftgoAssets.replace(':chain', data.chainApiPrefix),
    method: 'get',
    params: params,
    headers: {
      'X-API-KEY':
        thirdKeyConfig.nftgoKeys[
          Math.floor(Math.random() * thirdKeyConfig.nftgoKeys.length)
        ], // 每次调用随机取一个apiKey
    },
  })
  return res
}

export const getDomainNames = async (data) => {
  const res = await getNftGoAssets({
    ...data,
    ercType: 'erc721',
    nftAddress: {
      bnb: Contract.BSC_SIG_TOKEN,
      eth: Contract.ENS_TOKEN,
    }[data.chainApiPrefix],
  })
  try {
    return (
      res.content?.map((item) => {
        return {
          name: item.name,
          tokenId: item.token_id,
        }
      }) || []
    )
  } catch (error) {
    throw error
  }
}

export const getNftGoTokens = async (params) => {
  const res = await getNftGoAssets(params)
  const resList = []
  try {
    res.content.forEach((item) => {
      if (resList)
        resList.push({
          tokenId: item.token_id,
          amount: item.amount,
        })
    })
    return resList
  } catch (error) {
    throw error
  }
}

export const getNftGoAssetsToSell = async (data) => {
  const res = await getNftGoAssets({
    ...data,
    chainApiPrefix: 'eth',
    ercType: 'erc721',
  })
  try {
    let list = res?.content || []
    list = list.map((_) => formatNftGoItemToOrderData(_))
    return { list, moreKey: null }
  } catch (error) {
    throw error
  }
}
