import React, { useContext } from 'react'
import { Button, Divider, message } from 'antd'
import dayjs from 'dayjs'

import { Context } from '../context'
import '../styles/BecomeVip.css'
import { WebsiteVaultAddr } from '../constant/String'
import { amountToStrByDecimals, str2hex } from '../tools'

function BecomeVip() {
  const { userInfo = {}, ethPrice } = useContext(Context)

  const roles = userInfo?.roleList || []
  const roleName = {
    admin: '超级管理员',
    year: '年度会员',
    month: '月度会员',
  }[roles[0]]
  const isVip = !!userInfo?.roleList?.length

  const matemaskSendToken = async (value) => {
    value = (Number(value) / Number(ethPrice)).toFixed(4)
    const transactionParameters = {
      to: WebsiteVaultAddr,
      from: window.ethereum.selectedAddress,
      value: str2hex(amountToStrByDecimals(value, 10 ** 18)),
      data: '0x1',
    }
    try {
      const hash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
    } catch (error) {
      message.error('用户拒绝发送交易!')
    }
  }

  return (
    <div className="page">
      <div className="page_become_vip">
        <div className="header">
          <div>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                matemaskSendToken(200)
              }}
            >
              {isVip ? '续费一月' : '成为月会员'}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                matemaskSendToken(2000)
              }}
            >
              {isVip ? '续费一年' : '成为年会员'}
            </Button>
          </div>
          {userInfo?.expireDate && isVip && (
            <div>
              您是<b>{roleName}</b>，到期时间：
              <b>{dayjs(userInfo?.expireDate * 1000).format('YYYY-MM-DD')}</b>
            </div>
          )}
        </div>
        <Divider />
        <div className="body">
          <p>
            成为会员，无任何限制使用网站所有功能。（200U/月，2000U/年，支付时将换算成等额ETH）
            <a
              href="https://docs.cryptoweb3.tools/sheng-ji-hui-yuan"
              target={'_blank'}
            >
              更多支付方式
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
export default BecomeVip
