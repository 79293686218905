const isDev = process.env.NODE_ENV === 'development'
let siteConfig = {
  apiURL: isDev
    ? 'https://web-api.cryptoweb3.tools'
    : 'https://web-api.cryptoweb3.tools',
  socketURL: isDev
    ? 'wss://web-api.cryptoweb3.tools/socket'
    : 'wss://web-api.cryptoweb3.tools/socket',
  canAccessWhenLogin: ['/BecomeVip', '/Others/ProofStr'],
  leftMenu: [
    {
      key: 'PersonalCenter',
      text: '个人中心',
      icon: 'UserOutlined',
      hiden: true,
    },
    {
      key: 'Nft',
      text: 'NFT工具',
      icon: 'CrownOutlined',
      subMenu: [
        // { key: 'MintLive', text: '实时Mint数据', auth: true },
        { key: 'MintBy0xData', text: 'Hash Mint', auth: true },
        // { key: 'MakeOrder', text: '一键挂单', auth: true },
        { key: 'NFTCollection', text: 'NFT归集', auth: true },
        { key: 'NFTDistribution', text: 'NFT分发', auth: true },
        { key: 'ContractInteraction', text: '任意合约交互', auth: true },
        { key: 'ContractMint', text: '合约批量Mint', auth: true },
        // { key: 'OpenSeaFloor', text: 'OS扫地版', auth: true },
      ],
    },
    {
      key: 'wallet',
      text: '钱包及资金',
      icon: 'WalletOutlined',
      subMenu: [
        { key: 'QueryTokenBalance', text: '批量查地址余额', auth: true },
        { key: 'GenerateWalletsInBatches', text: '批量生成钱包', auth: true },
        { key: 'FilterWallet', text: '搜索助记词下的钱包', auth: true },
        { key: 'TransferTokenInBatches', text: '一对多转账', auth: true },
        { key: 'CollectTokenInBatches', text: '多对一,多对多转账', auth: true },
        {
          key: 'WithDrawFromExchange',
          text: '交易所批量提币到多钱包',
          auth: true,
        },
      ],
    },
    {
      key: 'Others',
      text: '其他工具',
      icon: 'BarsOutlined',
      subMenu: [
        // { key: 'BulkSetName', text: '批量解析域名', auth: true },
        // { key: 'WaterMark', text: '信息加水印', auth: true },
        { key: 'ProofStr', text: '批量查询', auth: true },
      ],
    },
    {
      key: 'RpcList',
      text: 'RPC节点',
      icon: 'CloudServerOutlined',
    },
    {
      key: 'Feedback',
      text: '意见或建议',
      icon: 'InfoCircleOutlined',
    },
    {
      key: 'BecomeVip',
      text: '升级会员',
      icon: 'TeamOutlined',
      auth: true,
    },
    {
      key: 'Admin',
      text: '管理员',
      icon: 'SafetyCertificateOutlined',
      admin: true,
      subMenu: [{ key: 'AddAccounts', text: '添加会员', auth: true }],
    },
  ],
}

const authRouter = [] // 根据菜单设置，设置需要权限访问的路径
siteConfig.leftMenu.forEach((menu) => {
  if (menu.subMenu) {
    menu.subMenu.forEach((subMenu) => {
      if (subMenu.auth) {
        authRouter.push(`/${menu.key}/${subMenu.key}`)
      }
    })
  } else if (menu.auth) {
    authRouter.push(`/${menu.key}`)
  }
  return
})

siteConfig = {
  authRouter,
  ...siteConfig,
}

export default siteConfig
