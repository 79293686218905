import React from 'react'
import { ethers } from 'ethers'

import { WalletReg } from '../constant/Regular'
import DocLink from '../component/DocLink'
import CustomProvider from '../component/CustomProvider'
import {
  Divider,
  InputNumber,
  Input,
  Button,
  message,
  notification,
  Spin,
  Tag,
  Table,
} from 'antd'
import CoinInfoABI from '../constant/ABI/CoinInfo'
import { updateResultListByWallets, amountToStrByDecimals } from '../tools'
import chainConfig from '../config/chainConfig'
import ResolvePrivateKey from '../component/ResolvePrivateKey'

import '../styles/CollectTokens.css'

const { TextArea } = Input
class CollectTokens extends React.Component {
  constructor(props) {
    super(props)
    const defaultToken = chainConfig[this.props.chainId]?.nativeCurrency.symbol
    this.state = {
      provider: null,
      resultList: [],
      maxFee:
        {
          56: 5,
          42161: 10,
        }[this.props.chainId] || 100, //最大交易费
      priorityFee: 2, //矿工小费
      targetAddressList: [], // 归集的目标地址
      tokenAddress: '', // Token合约地址
      decimals: 0, // Token 精度
      tokenLoading: false,
      tokenName: defaultToken, // token名
      verifyBalanceIng: false, //余额验证中
      start: false,
    }
  }

  handleAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的合约地址',
      })
      return
    }
    address && this.getTokenName(address)
    this.setState({
      tokenAddress: address,
      resultList: [],
      start: false,
    })
  }

  getTokenName = async (address) => {
    const { provider } = this.state
    const abiStr = JSON.stringify(CoinInfoABI)
    const contract = new ethers.Contract(address, abiStr, provider)
    const hide = message.loading('代币信息加载中...', null)
    this.setState({ tokenLoading: true })
    try {
      const decimals = await contract.decimals()
      const name = await contract.symbol()
      this.setState({
        decimals,
        tokenName: name,
        tokenLoading: false,
      })
    } catch (error) {
      this.setState({
        tokenName: '错误地址',
        tokenLoading: false,
      })
    }
    hide()
  }

  handleTargetAddressChange = (user, value) => {
    if (!WalletReg.test(value) && value !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的接收地址',
      })
      return
    }
    this.setState({
      resultList: this.state.resultList.map((_) => {
        if (_.address === user) _.targetAddr = value
        return _
      }),
    })
  }

  handleTargetAddressListChange = (addrListStr) => {
    const addrList = addrListStr.split(/[(\r\n)\r\n]+/)
    for (let i = 0; i < addrList.length; i++) {
      const addr = addrList[i]
      if (!WalletReg.test(addr) && addr !== '') {
        message.error({
          key: 'addressError',
          content: '请输入正确的接收地址',
        })
        return
      }
    }
    this.setState({
      targetAddressList: addrList,
    })
  }

  clearPrivateKey = () => {
    this.setState({
      resultList: [],
      start: false,
    })
  }

  walletsLoaded = (wallets) => {
    const addrArr = this.state.targetAddressList
    if (wallets.length !== addrArr.length && addrArr.length !== 1) {
      notification.warning({
        message: `发送钱包数量与接受钱包数量不一致，请确认是否有误。无目标地址的钱包不会发送交易`,
      })
    }
    this.setState({
      resultList: wallets.map((_, i) => {
        if (this.state.tokenAddress) {
          this.getBalanceOf(_.wallet)
        }
        return {
          ..._,
          address: _.wallet.address,
          tx: '',
          status: 3,
          targetAddr: addrArr.length === 1 ? addrArr[0] : addrArr[i],
        }
      }),
    })
  }

  getBalanceOf = async (wallet) => {
    const { tokenAddress, provider, decimals } = this.state
    const abiStr = JSON.stringify(CoinInfoABI)
    const contract = new ethers.Contract(tokenAddress, abiStr, provider)
    contract.balanceOf(wallet.address).then((value) => {
      let datas = this.state.resultList.map((_) => {
        if (_.address === wallet.address) {
          return {
            ..._,
            _balance: `${
              ethers.utils.formatUnits(`${value}`, 'ether') *
              10 ** (18 - decimals)
            }`,
          }
        }
        return _
      })
      this.setState({
        resultList: datas,
      })
    })
  }

  walletsDataUpdate = (wallet) => {
    const { resultList } = this.state
    this.setState({
      resultList: updateResultListByWallets(resultList, wallet),
    })
  }

  // 删除一条地址
  delAddress = (record) => {
    const { resultList } = this.state
    this.setState({
      resultList: resultList.filter((_) => _.address !== record.address),
    })
  }

  handleClick = async () => {
    if (!this.state.targetAddressList.length) {
      message.error('请输入接收地址')
      return
    }
    this.setState(
      {
        start: true,
        resultList: this.state.resultList.map((_) => ({
          ..._,
          tx: undefined,
          status: undefined,
        })),
      },
      this.send,
    )
  }

  send = async () => {
    const {
      maxFee,
      priorityFee,
      tokenAddress,
      resultList,
      decimals,
    } = this.state
    const _maxFee = ethers.utils.parseUnits(`${maxFee}`, 'gwei')
    const _priorityFee = ethers.utils.parseUnits(`${priorityFee}`, 'gwei')
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    const gasInfo = noEip1559
      ? {
          gasPrice: _maxFee,
        }
      : {
          maxFeePerGas: _maxFee,
          maxPriorityFeePerGas: _priorityFee,
        }

    const resHandle = (transaction, i) => {
      this.setState((prevState) => {
        const resultList = [...prevState.resultList]
        resultList[i].tx = transaction.hash
        return { resultList }
      })
      transaction
        .wait()
        .then(() => {
          this.setState((prevState) => {
            const resultList = [...prevState.resultList]
            resultList[i].status = 1
            return { resultList }
          })
        })
        .catch((error) => {
          this.setState((prevState) => {
            const resultList = [...prevState.resultList]
            resultList[i].status = 2
            return { resultList }
          })
        })
    }

    const errHandle = (err, i) => {
      message.error({
        key: `sendTxError`,
        content: JSON.stringify(err),
      })
      this.setState((prevState) => {
        const resultList = [...prevState.resultList]
        resultList[i].tx = '交易未发出'
        resultList[i].status = 2
        return {
          resultList,
        }
      })
    }

    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      const wallet = item.wallet
      if (tokenAddress) {
        if (item._balance > 0) {
          const balance = amountToStrByDecimals(item._balance, 10 ** decimals)
          const ABI = JSON.stringify(CoinInfoABI)
          let contract = new ethers.Contract(tokenAddress, ABI, wallet)
          let params = [item.targetAddr, `${balance}`]
          contract.estimateGas
            .transfer(...params)
            .then((gasLimit) => {
              contract
                .transfer(...params, { ...gasInfo, gasLimit })
                .then((_) => resHandle(_, i))
                .catch((_) => errHandle(_, i))
            })
            .catch((_) => errHandle(_, i))
        }
      } else {
        let gasLimit = 21000
        const balance = ethers.utils.parseUnits(`${item.balance}`, 'ether')
        const gas = noEip1559 ? gasInfo.gasPrice : gasInfo.maxFeePerGas
        // 特殊处理，当前链为Arbitrum链时。需要预估gaslimit
        if (this.props.chainId === 42161) {
          gasLimit = await wallet.estimateGas({
            to: item.targetAddr,
            value: balance,
          })
        }
        wallet
          .sendTransaction({
            ...gasInfo,
            gasLimit,
            to: item.targetAddr,
            value: balance.sub(gas.mul(gasLimit)),
          })
          .then((_) => resHandle(_, i))
          .catch((_) => errHandle(_, i))
      }
    }
  }

  onChangeCollectType = (e) => {
    this.setState({
      collectType: e.target.value,
      tokenAddress: '',
      resultList: [],
      start: false,
    })
  }

  render() {
    const {
      maxFee,
      priorityFee,
      tokenAddress,
      resultList,
      start,
      targetAddressList,
      tokenName,
    } = this.state
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    return (
      <div className="page">
        <DocLink
          text={'批量归集钱包余额使用说明'}
          link={'https://docs.cryptoweb3.tools/pi-liang-zhuan-yi-gui-ji-eth'}
        />
        <div className="page_token_collection">
          <div className="header">
            <div className="nft_info">
              <div className="section">
                {noEip1559 ? (
                  <div className="max_fee">
                    <div className="key">Gas Price:</div>
                    <InputNumber
                      min={0}
                      value={maxFee}
                      onChange={(value) => this.setState({ maxFee: value })}
                    />
                  </div>
                ) : (
                  [
                    <div className="priority_fee" key={'priority_fee'}>
                      <div className="key">小费:</div>
                      <InputNumber
                        min={0}
                        value={priorityFee}
                        onChange={(value) =>
                          this.setState({ priorityFee: value })
                        }
                      />
                    </div>,
                    <div className="max_fee" key="max_fee">
                      <div className="key">Max Fee :</div>
                      <InputNumber
                        min={0}
                        value={maxFee}
                        onChange={(value) => this.setState({ maxFee: value })}
                      />
                    </div>,
                  ]
                )}
              </div>
              <div className="top">
                <Input
                  className="nft_address"
                  placeholder={`代币地址，不填写默认为本链原生代币`}
                  value={tokenAddress}
                  onChange={(e) => this.handleAddressChange(e.target.value)}
                />
                <div className="rpc">
                  <CustomProvider
                    onConfirm={(_provider) => {
                      this.setState({ provider: _provider })
                    }}
                  />
                </div>
                {tokenName && <div className="token_name">{tokenName}</div>}
              </div>
            </div>
          </div>
          <Divider />
          <div className="box">
            <ResolvePrivateKey
              precondition={() => {
                if (!this.state.targetAddressList.length) {
                  message.error('接收地址未填')
                  return
                }
                return true
              }}
              provider={this.state.provider}
              onWalletsLoaded={this.walletsLoaded}
              onWalletsDataUpdate={this.walletsDataUpdate}
              onClear={this.clearPrivateKey}
            />
            <div className="target_address">
              <TextArea
                rows={5}
                placeholder="接收地址(一行一个地址，当填写一个地址时，表示多对一归集)"
                value={targetAddressList.join('\n')}
                onChange={(e) =>
                  this.handleTargetAddressListChange(e.target.value)
                }
              />
            </div>
          </div>
          <Divider />
          <div className="list">
            <div className="action">
              <div>
                <Button onClick={this.handleClick} type="primary">
                  开始
                </Button>
              </div>
            </div>
            <Table
              size="small"
              bordered
              dataSource={resultList}
              scroll={{ y: 540 }}
              rowKey="address"
              columns={[
                {
                  title: '地址',
                  dataIndex: 'address',
                  render: (_address) => (
                    <a
                      href={`${
                        chainConfig[this.props.chainId]?.explorer
                      }/address/${_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_address}
                    </a>
                  ),
                },
                {
                  title: `余额(${tokenName})`,
                  width: 108,
                  dataIndex: '_balance',
                  render: (_balance, record) => {
                    _balance = tokenAddress ? _balance : record.balance
                    return _balance ? (
                      _balance.substring(0, 6)
                    ) : (
                      <Spin size="small" />
                    )
                  },
                },
                {
                  title: `目标地址`,
                  dataIndex: 'targetAddr',
                  render: (address, record) => {
                    return (
                      <Input
                        value={address}
                        onChange={(e) =>
                          this.handleTargetAddressChange(
                            record.address,
                            e.target.value,
                          )
                        }
                      />
                    )
                  },
                },
                {
                  title: '交易Hash',
                  dataIndex: 'tx',
                  render: (tx) => {
                    return (
                      <div className="hash">
                        {tx === '' ? (
                          '-'
                        ) : tx ? (
                          <a
                            target="_blank"
                            href={`${
                              chainConfig[this.props.chainId]?.explorer
                            }/tx/${tx}`}
                            rel="noopener noreferrer"
                          >
                            {tx.substring(0, 10) + '...'}
                          </a>
                        ) : (
                          <Spin size="small" />
                        )}
                      </div>
                    )
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  render: (status) => {
                    return (
                      <div className="status">
                        {
                          [
                            <Spin size="small" />,
                            <Tag color="green">成功</Tag>,
                            <Tag color="red">失败</Tag>,
                            <span>-</span>,
                          ][status || 0]
                        }
                      </div>
                    )
                  },
                },
                {
                  title: '操作',
                  width: '15%',
                  key: 'action',
                  render: (text, record) =>
                    !start ? (
                      <div
                        className="del"
                        onClick={() => this.delAddress(record)}
                      >
                        删除
                      </div>
                    ) : (
                      '-'
                    ),
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default CollectTokens
