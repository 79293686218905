import React from 'react'

import '../styles/Home.css'

function Home() {
  return (
    <div className="page page_home">
      <div className="link">
        <a href="https://docs.cryptoweb3.tools/" target={'_blank'}>
          CryptoWeb3.tools使用说明
        </a>
        <p>
          文档里不仅有教程，还有些小技巧以及注意事项，强烈建议读一下，很简短，不会花费你太多时间
        </p>
      </div>
    </div>
  )
}
export default Home
