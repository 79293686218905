import React from 'react'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import {
  Divider,
  InputNumber,
  Button,
  Input,
  message,
  notification,
  Spin,
  Tag,
  Table,
  Popconfirm,
  Switch,
  Tooltip,
  Radio,
  Space,
} from 'antd'
import { RocketOutlined } from '@ant-design/icons'
import { TxReg } from '../constant/Regular'
import '../styles/MintBy0xData.css'
import TotalSupplyABI from '../constant/ABI/TotalSupply'
import { updateResultListByWallets, xSmallNumToStr } from '../tools'
import chainConfig from '../config/chainConfig'
import CustomProvider from '../component/CustomProvider'
import ResolvePrivateKey from '../component/ResolvePrivateKey'
import SimpleGas from '../component/SimpleGas'
import DocLink from '../component/DocLink'
import { sleep } from '../tools'
import {
  analysisInputData,
  getHexDataByMethodParams,
} from '../helper/MintBy0xData'
import { getFunParamsByHex } from '../network/site'
import { rpc_3gm_ap, rpc_3gm_en, rpc_3gm_us } from '../constant/String'

const { TextArea } = Input

const gasMaxLimit = 300000
const dataMaxlength = 350
let totalSupplyTimer

class MintBy0xData extends React.Component {
  constructor(props) {
    super(props)
    this.privateKeysRef = React.createRef()
    this.state = {
      rpc: 'ETH',
      maxFee: 0, //最大交易费
      priorityFee: 2, //矿工小费
      gasLimit: 200000, //Gas Limit
      price: 0, //单笔交易价格
      data: '', //十六进制数据
      nftAddress: '', // NFT合约地址
      totalSupply: 0,
      privateKey: '', // 私钥地址
      resultList: [], //余额验证中
      start: false,
      copyTx: '', // 复制别人的交易，自动填充值
      upMaxFee: 800,
      forceSend: false, // 强制发送交易
      transferSelf: false, //自己转自己
      reTryTip: '', // 非强制发送时的重试提示
      totalCount: 1, // 统一控制每个钱包发送交易的次数
      dataMode: 'hex',

      parseArr: null, //方法模式的参数
    }
  }

  componentDidMount() {
    const search = window.location.search
    if (search) {
      this.setState({
        copyTx: search.substring(6),
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { mintData } = this.props
    if (prevState.copyTx !== this.state.copyTx) {
      this.getTxInfo(this.state.copyTx)
    }
    if (prevProps.mintData !== mintData && mintData) {
      this.setState({
        copyTx: mintData.tx,
      })
    }
  }
  componentWillUnmount() {
    clearInterval(totalSupplyTimer)
  }

  handleClick = async () => {
    if (this.isPending()) {
      message.error(`交易正在进行...`)
      return
    }
    if (this.state.start) {
      // 停止
      this.setState({
        start: false,
        reTryTip: '',
        resultList: [],
      })
      return
    }
    this.setState(
      {
        start: true,
        resultList: this.state.resultList.map((_) => ({
          ..._,
          hashs: [...new Array(_.count)].map((_) => [undefined, undefined]),
        })),
      },
      this.send
    )
  }

  // 删除一条地址
  delAddress = (record) => {
    const { resultList } = this.state
    this.setState({
      resultList: resultList.filter((_) => _.address !== record.address),
    })
  }

  clearPrivateKey = () => {
    this.setState({
      start: false,
      resultList: [],
    })
  }

  walletsLoaded = (wallets) => {
    this.setState({
      resultList: wallets.map((_) => ({
        ..._,
        address: _.wallet.address,
        hashs: [['', 3]],
        count: 1,
      })),
    })
  }

  walletsDataUpdate = (item) => {
    const { resultList } = this.state
    this.setState({
      resultList: updateResultListByWallets(resultList, item),
    })
  }

  isPending = () => {
    const { resultList } = this.state
    let hasPending = false
    resultList.forEach((wallet) => {
      wallet.hashs.forEach((hash) => {
        if (hash[0] && hash[1] === undefined) {
          hasPending = true
        }
      })
    })
    return hasPending
  }

  handleForceSend = (checked) => {
    this.setState({
      forceSend: checked,
    })
  }

  handleTransferSelf = (checked) => {
    this.setState({
      transferSelf: checked,
    })
  }

  resetStatus = () => {
    if (this.isPending()) {
      message.error(`还有交易正在pending中...`)
      return
    }
    this.setState(
      {
        maxFee: 0,
        priorityFee: 2,
        gasLimit: 200000,
        price: 0,
        data: '',
        nftAddress: '',
        totalSupply: 0,
        resultList: [],
        start: false,
        copyTx: '',
        upMaxFee: 800,
        listening: false,
        dataMode: 'hex',
        parseArr: null,
        funName: '',
        fullMethodStr: '',
      },
      this.privateKeysRef.current.resetWalletsStatus
    )
  }

  setProvider = (provider) => {
    this.setState({
      provider: provider,
    })
  }

  setParamsByData = (data) => {
    const methodHexStr = data.substring(0, 10)
    getFunParamsByHex(methodHexStr).then((res) => {
      if (!res) return
      const a = analysisInputData(data, res)
      const params = a?.params || []
      const funName = a?.name || ''
      this.setState({
        parseArr: params,
        funName: funName,
        dataMode: 'fun',
        fullMethodStr: res,
      })
    })
  }

  getTxInfo = async (value) => {
    const { mintData } = this.props
    if (!value) return
    if (!TxReg.test(value)) {
      message.error({
        key: 'error_wallet',
        content: 'Hash值不正确',
      })
      return
    }
    let tx
    if (!mintData) {
      const hide = message.loading('正在获取交易...', 0)
      tx = await this.state.provider.getTransaction(value)
      if (!tx)
        tx = await ethers.getDefaultProvider(rpc_3gm_ap).getTransaction(value)
      if (!tx)
        tx = await ethers.getDefaultProvider(rpc_3gm_us).getTransaction(value)
      if (!tx)
        tx = await ethers.getDefaultProvider(rpc_3gm_en).getTransaction(value)
      hide()
    }
    if (!mintData && !tx) {
      message.error({
        key: 'no_find_tx',
        content: '没有找到这笔交易记录',
      })
      return
    }
    if (tx || mintData) {
      let from,
        max,
        priority,
        limit,
        price,
        maxFee,
        priorityFee,
        nftAddress,
        data,
        params = null,
        dataMode = 'hex',
        funName = '',
        fullMethodStr = ''
      if (mintData) {
        from = mintData.from?.toLowerCase().substring(2)
        max = mintData.maxFee || mintData.gasPrice
        priority = mintData.maxPriorityFee || mintData.gasPrice
        limit = Number(ethers.utils.formatUnits(`${mintData.gasLimit}`, 'wei'))
        price = Number(ethers.utils.formatUnits(`${mintData.value}`, 'ether'))
        price = xSmallNumToStr(price)
        maxFee = Number(ethers.utils.formatUnits(`${max}`, 'gwei'))
        priorityFee = Number(ethers.utils.formatUnits(`${priority}`, 'gwei'))
        nftAddress = mintData.contract
        data = mintData.inputData
        // 如果有方法名就进行解析
        if (mintData.method) {
          const a = analysisInputData(mintData.inputData, mintData.method)
          params = a?.params || []
          funName = a?.name || ''
          dataMode = 'fun'
          fullMethodStr = mintData.method
        }
      } else {
        from = tx.from.toLowerCase().substring(2)
        max = tx.maxFeePerGas || tx.gasPrice
        priority = tx.maxPriorityFeePerGas || tx.gasPrice
        limit = Number(ethers.utils.formatUnits(`${tx.gasLimit}`, 'wei'))
        price = Number(ethers.utils.formatUnits(`${tx.value}`, 'ether'))
        price = xSmallNumToStr(price)
        maxFee = Number(ethers.utils.formatUnits(`${max}`, 'gwei'))
        priorityFee = Number(ethers.utils.formatUnits(`${priority}`, 'gwei'))
        nftAddress = tx.to
        data = tx.data
        this.setParamsByData(tx.data)
      }
      if (price) {
        notification.info({
          message: `单笔交易需要花费 ${price} ETH!`,
        })
      }
      if (limit > gasMaxLimit) {
        notification.warning({
          message: `GasLimit已达到${limit}, 手续费可能较高, 请谨慎!`,
        })
      }
      if (data.includes(from)) {
        notification.success({
          message: `数据中检测到发送者地址, 已经自动替换成你的地址，安心`,
        })
      }
      if (data.length > dataMaxlength) {
        notification.warning({
          message: `Data数据长度已达到${data.length}, 请确认方法是否有签名!`,
        })
      }
      this.setState(
        {
          from: from,
          nftAddress: nftAddress,
          gasLimit: Math.ceil(limit * 1.2),
          price: price,
          maxFee: maxFee.toFixed(1),
          priorityFee: priorityFee < 3 ? 3 : priorityFee.toFixed(1),
          data: data,
          listening: true,
          parseArr: params,
          dataMode,
          funName,
          fullMethodStr,
        },
        () => this.startListen(nftAddress)
      )
    }
  }

  startListen = async (contractAddress) => {
    const { mintData } = this.props
    if (mintData?.extension) {
      contractAddress = mintData.extension.tokenAddress
    }
    let contract = new ethers.Contract(
      contractAddress,
      JSON.stringify(TotalSupplyABI),
      this.state.provider
    )
    if (totalSupplyTimer) clearInterval(totalSupplyTimer)
    totalSupplyTimer = setInterval(async () => {
      if (!this.state.listening) clearInterval(totalSupplyTimer)
      try {
        this.setState({
          totalSupply: (await contract.totalSupply()).toString(),
        })
      } catch (error) {
        console.error(error)
      }
    }, 2000)
  }

  printError = (err, result, address) => {
    message.error({
      key: `${address}-sendTxError`,
      content: address + JSON.stringify(err),
    })
    result = result.map((_) => {
      if (_.address === address) {
        _.hashs = _.hashs.map((tx) => {
          if (!tx[0]) {
            tx[0] = '交易未发出,不扣Gas费'
            tx[1] = 2
          }
          return tx
        })
      }
      return _
    })
    this.setState({
      resultList: result,
    })
  }

  sendTransaction = (wallet, parse) => {
    const { resultList } = this.state
    let result = resultList
    const w = new ethers.Wallet(wallet.key, this.state.provider)
    w.sendTransaction(parse)
      .then((transaction) => {
        result = result.map((_) => {
          if (_.address === wallet.address) {
            let txs
            if (wallet.hashs.find((_) => _[2] === transaction.nonce)) {
              txs = wallet.hashs.map((_) => {
                if (_[2] === transaction.nonce) {
                  message.info(
                    `已替换${wallet.address}钱包Nonce为${transaction.nonce}的交易`
                  )
                  return [transaction.hash, undefined, transaction.nonce]
                }
                return _
              })
            } else {
              txs = [
                [transaction.hash, undefined, transaction.nonce],
                ...wallet.hashs,
              ]
              txs.pop()
            }
            _.hashs = txs
          }
          return _
        })
        this.setState({
          resultList: result,
        })
        transaction
          .wait()
          .then((res) => {
            if (res.status === 1) {
              result = result.map((_) => {
                if (_.address === wallet.address) {
                  _.hashs = _.hashs.map((tx) => {
                    if (tx[0] === transaction.hash) {
                      tx[1] = 1
                    }
                    return tx
                  })
                }
                return _
              })
              this.setState({
                resultList: result,
              })
            }
          })
          .catch((err) => {
            result = result.map((_) => {
              if (_.address === wallet.address) {
                _.hashs = _.hashs.map((tx) => {
                  if (tx[0] === transaction.hash) {
                    tx[1] = 2
                  }
                  return tx
                })
              }
              return _
            })
            this.setState({
              resultList: result,
            })
          })
      })
      .catch((err) => {
        message.error('交易未发出, 详情查看控制台打印信息')
        this.printError(err, result, wallet.address)
      })
  }

  send = async () => {
    const {
      maxFee,
      priorityFee,
      price,
      data,
      nftAddress,
      resultList,
      gasLimit,
      from,
      forceSend,
      transferSelf,
    } = this.state
    const _maxFee = ethers.utils.parseUnits(`${maxFee}`, 'gwei')
    const _priorityFee = ethers.utils.parseUnits(`${priorityFee}`, 'gwei')
    const _value = ethers.utils.parseUnits(`${price}`, 'ether')
    let gasInfo = {}
    if (chainConfig[this.props.chainId]?.noEip1559) {
      gasInfo = {
        gasPrice: _maxFee,
      }
    } else {
      gasInfo = {
        maxFeePerGas: _maxFee,
        maxPriorityFeePerGas: _priorityFee,
      }
    }

    if (!forceSend) {
      const wallet = resultList[0].wallet
      const self = wallet.address.substring(2)
      do {
        if (!this.state.start) return
        try {
          await wallet.estimateGas({
            ...gasInfo,
            to: transferSelf ? wallet.address : nftAddress,
            value: _value,
            data: data.replaceAll(from, self),
          })
          this.setState({ reTryTip: '' })
          break
        } catch (error) {
          const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
          this.setState({
            reTryTip: this.state.start ? `合约不能被执行，重试中${now}...` : '',
            errDetail: JSON.stringify(error).substring(0, 300),
          })
        }
      } while (true)
    }

    message.info({ key: 'send_tx', content: '开始发送交易' })
    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      const wallet = item.wallet
      const self = wallet.address.substring(2)

      for (let j = 0; j < item.count; j++) {
        if ((resultList.length * i + j + 1) % 5 === 0) await sleep(1000)
        const parse = {
          ...gasInfo,
          nonce: item.nonce + j,
          to: transferSelf ? wallet.address : nftAddress,
          value: _value,
          data: data.replaceAll(from, self),
          gasLimit: gasLimit,
        }
        this.sendTransaction(item, parse)
      }
    }
  }

  changeCount = (address, count) => {
    const res = this.state.resultList.map((item) => {
      if (item.address === address || address === 'all') {
        item.hashs = [...new Array(count)].map((_) => ['', 3])
        item.count = count
      }
      return item
    })
    this.setState({
      resultList: res,
    })
  }

  upSpeed = () => {
    const { upMaxFee, upPriorityFee, resultList } = this.state
    return new Promise((resolve) => {
      let count = 0,
        completed = 0
      resultList.forEach((wallet) => {
        wallet.hashs.forEach(async (hash) => {
          if (hash[0] && hash[1] === undefined) {
            count++
            this.state.provider.getTransaction(hash[0]).then((tx) => {
              const _maxFee = ethers.utils.parseUnits(`${upMaxFee}`, 'gwei')
              const _priority = ethers.utils.parseUnits(
                `${upPriorityFee || upMaxFee}`,
                'gwei'
              )
              let gasInfo = {}
              if (chainConfig[this.props.chainId]?.noEip1559) {
                gasInfo = {
                  gasPrice: _maxFee,
                }
              } else {
                gasInfo = {
                  maxFeePerGas: _maxFee,
                  maxPriorityFeePerGas: _priority,
                }
              }
              this.sendTransaction(wallet, {
                nonce: tx.nonce,
                ...gasInfo,
                to: tx.to,
                value: tx.value,
                data: tx.data,
                gasLimit: tx.gasLimit,
              })
              completed++
              if (count === completed) {
                this.setState({
                  upMaxFee: upMaxFee * 1.2,
                  upPriorityFee: upPriorityFee * 1.2,
                })
                resolve()
              }
            })
          }
        })
      })
    })
  }

  changeTotalCount = (value) => {
    const { resultList } = this.state
    const list = resultList.map((item) => {
      item.count = value
      return item
    })
    this.setState(
      {
        resultList: list,
      },
      this.changeCount('all', value)
    )
  }

  totalAmount = () => {
    return (
      this.state.resultList?.reduce(
        (previousValue, _) => previousValue + Number(_.balance),
        0
      ) || 0
    )
  }

  copyAllAddress = () => {
    const { resultList } = this.state
    const addressStr = resultList.map((_) => _.address).join('\n')
    navigator.clipboard.writeText(addressStr)
    message.success({
      key: 'copyAll',
      content: `已复制`,
    })
  }

  // 切换 inputData 数据显示模式
  onDataModeChange = (e) => {
    this.setState({
      dataMode: e.target.value,
    })
  }

  changeParse = (index, key, value) => {
    const { parseArr, fullMethodStr } = this.state
    const newArr = JSON.parse(JSON.stringify(parseArr))
    newArr[index][key] = value
    const hexData = getHexDataByMethodParams(fullMethodStr, newArr)
    this.setState({
      parseArr: newArr,
      data: hexData,
    })
  }

  renderFunParams = () => {
    const { parseArr, funName } = this.state
    return (
      <div className="fun_params_box">
        <div className="method">
          交互方法：
          <b>{funName}</b>
        </div>
        <div>
          {parseArr.map((parse, index) => {
            return (
              <div className="parse_box" key={index}>
                参数类型：
                <Input
                  disabled
                  size="small"
                  value={parse.type}
                  placeholder="如address,bool,uint256等"
                />
                参数值：
                <Input
                  size="small"
                  value={parse.value}
                  placeholder="参数值"
                  onChange={(e) =>
                    this.changeParse(index, 'value', e.target.value)
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const {
      maxFee,
      priorityFee,
      price,
      data,
      nftAddress,
      resultList,
      start,
      gasLimit,
      copyTx,
      upMaxFee,
      upPriorityFee,
      totalSupply,
      listening,
      reTryTip,
      totalCount,
      dataMode,
      parseArr,
      funName,
    } = this.state
    const { isFrame } = this.props
    return (
      <div className="page">
        {!isFrame && (
          <DocLink
            text={'Hash Mint使用说明'}
            link={'https://docs.cryptoweb3.tools/hash-mint'}
          />
        )}
        <div className="data_mint_page">
          <div className="header">
            <Button danger type={'primary'} onClick={this.resetStatus}>
              重置页面数据
            </Button>
            <div className="rpc">
              <CustomProvider onConfirm={this.setProvider} />
            </div>
          </div>
          <Divider />
          <div className="section">
            <div className="tx">
              <div className="key">复制别人的交易Hash:</div>
              <Input
                value={copyTx}
                placeholder={'0x10b634179...'}
                onChange={(e) =>
                  this.setState({ copyTx: e.target.value.trim() })
                }
              />
            </div>
          </div>
          <div className="section">
            {chainConfig[this.props.chainId]?.noEip1559 ? (
              <div className="max_fee">
                <div className="key">Gas Price:</div>
                <InputNumber
                  min={0}
                  value={maxFee}
                  onChange={(value) => this.setState({ maxFee: value })}
                />
              </div>
            ) : (
              [
                <div className="priority_fee" key="priority_fee">
                  <div className="key">小费:</div>
                  <InputNumber
                    min={0}
                    value={priorityFee}
                    onChange={(value) => this.setState({ priorityFee: value })}
                  />
                </div>,
                <div className="max_fee" key="max_fee">
                  <div className="key">Max Fee :</div>
                  <InputNumber
                    min={0}
                    value={maxFee}
                    onChange={(value) => this.setState({ maxFee: value })}
                  />
                </div>,
              ]
            )}
            <div className="gas_limit">
              <div className="key">Gas Limit:</div>
              <InputNumber
                min={0}
                value={gasLimit}
                width={500}
                onChange={(value) => this.setState({ gasLimit: value })}
              />
            </div>
            <SimpleGas />
          </div>
          <div className="section">
            <div className="gas_limit">
              <div className="key">总价:</div>
              <InputNumber
                min={0}
                value={price}
                onChange={(value) => this.setState({ price: value })}
              />
            </div>
            <div className="price">
              <div className="key">转自己:</div>
              <Switch
                checked-={this.state.transferSelf}
                onChange={(checked) => this.handleTransferSelf(checked)}
              />
            </div>
            {!this.state.transferSelf && (
              <div className="address">
                <div className="key">目标地址:</div>
                <Input
                  value={nftAddress}
                  onChange={(e) =>
                    this.setState({ nftAddress: e.target.value.trim() })
                  }
                />
                {!!totalSupply && listening && (
                  <div className="selling">已经售卖:{totalSupply}</div>
                )}
              </div>
            )}
          </div>
          <div className="section">
            <div className="data">
              <div className="modo">
                <Radio.Group
                  onChange={this.onDataModeChange}
                  value={dataMode}
                  disabled={!funName || !parseArr}
                >
                  <Space direction="vertical">
                    <Radio value={'hex'}>十六进制数据:</Radio>
                    <Radio value={'fun'}>数据解析:</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="input_data_value">
                {dataMode === 'fun' && this.renderFunParams()}
                {dataMode === 'hex' && (
                  <TextArea
                    rows={3}
                    value={data}
                    onChange={(e) => this.setState({ data: e.target.value })}
                  />
                )}
              </div>
            </div>
          </div>
          <Divider />
          <ResolvePrivateKey
            cRef={this.privateKeysRef}
            provider={this.state.provider}
            onWalletsLoaded={this.walletsLoaded}
            onWalletsDataUpdate={this.walletsDataUpdate}
            onClear={this.clearPrivateKey}
          />
          <Divider />
          <div className="list">
            <div className="head">
              <div className="force_send">
                <div className="key">强制发送</div>
                <Switch
                  disabled={start}
                  onChange={(checked) => this.handleForceSend(checked)}
                />
                {!!reTryTip && (
                  <div className="re_try_tip">
                    {reTryTip}
                    <Tooltip title={this.state.errDetail} defaultOpen={true}>
                      <span>[详情]</span>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="action">
                {this.isPending() && (
                  <Popconfirm
                    title={
                      <div className="up_speed">
                        {chainConfig[this.props.chainId]?.noEip1559 ? (
                          <InputNumber
                            placeholder="GasPrice"
                            value={upMaxFee}
                            onChange={(val) =>
                              this.setState({
                                upMaxFee: val,
                              })
                            }
                          />
                        ) : (
                          [
                            <InputNumber
                              key="PriorityFee"
                              placeholder="PriorityFee"
                              value={upPriorityFee}
                              onChange={(val) =>
                                this.setState({
                                  upPriorityFee: val,
                                })
                              }
                            />,
                            <InputNumber
                              key="MaxFee"
                              placeholder="MaxFee"
                              value={upMaxFee}
                              onChange={(val) =>
                                this.setState({
                                  upMaxFee: val,
                                })
                              }
                            />,
                          ]
                        )}
                      </div>
                    }
                    onConfirm={this.upSpeed}
                    icon={<RocketOutlined style={{ fontSize: '26px' }} />}
                  >
                    <Button type="primary">一键加速</Button>
                  </Popconfirm>
                )}
                <Button
                  danger={start}
                  onClick={this.handleClick}
                  type="primary"
                >
                  {start ? '停止' : '开始'}
                </Button>
              </div>
            </div>
            <Table
              size="small"
              bordered
              dataSource={resultList}
              scroll={{ y: 340 }}
              rowKey="address"
              columns={[
                {
                  title: '地址',
                  dataIndex: 'address',
                  render: (_address) => (
                    <a
                      href={`${
                        chainConfig[this.props.chainId].explorer
                      }/address/${_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_address}
                    </a>
                  ),
                },
                {
                  title: '余额',
                  dataIndex: 'balance',
                  render: (_balance) =>
                    _balance ? _balance.substring(0, 6) : <Spin size="small" />,
                },
                {
                  title: () => {
                    return (
                      <div className="count_header">
                        <div>请求次数</div>
                        <InputNumber
                          min={1}
                          step={1}
                          defaultValue={totalCount}
                          disabled={start || !resultList.length}
                          onChange={this.changeTotalCount}
                        />
                      </div>
                    )
                  },
                  width: 188,
                  dataIndex: 'count',
                  render: (count, record) => {
                    return (
                      <InputNumber
                        min={1}
                        step={1}
                        defaultValue={count}
                        value={count}
                        disabled={start}
                        onChange={(value) =>
                          this.changeCount(record.address, value)
                        }
                      />
                    )
                  },
                },
                {
                  title: '交易Hash',
                  dataIndex: 'hashs',
                  className: 'hash_cell',
                  render: (hashs) => {
                    return (
                      <div>
                        {hashs.map((tx, i) => {
                          return (
                            <div key={i} className="hash">
                              {tx[0] === '' ? (
                                '-'
                              ) : tx[0] ? (
                                <a
                                  target={'_blank'}
                                  href={`${
                                    chainConfig[this.props.chainId].explorer
                                  }/tx/${tx[0]}`}
                                >
                                  {tx[0].substring(0, 10) + '...'}
                                </a>
                              ) : (
                                <Spin size="small" />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'hashs',
                  className: 'status_cell',
                  render: (hashs, record) => {
                    return (
                      <div>
                        {hashs.map((tx, i) => {
                          return (
                            <div key={i} className="status">
                              {
                                [
                                  <Spin size="small" />,
                                  <Tag color="green">成功</Tag>,
                                  <Tag color="red">失败</Tag>,
                                  <span>-</span>,
                                ][tx[1] || 0]
                              }
                            </div>
                          )
                        })}
                      </div>
                    )
                  },
                },
                {
                  title: '操作',
                  width: '15%',
                  key: 'action',
                  render: (text, record) =>
                    !start ? (
                      <div
                        className="del"
                        onClick={() => this.delAddress(record)}
                      >
                        删除
                      </div>
                    ) : (
                      '-'
                    ),
                },
              ]}
              pagination={false}
            />
          </div>
          <div className="make_order">
            {!!resultList?.length && (
              <Button type="primary" onClick={this.copyAllAddress}>
                复制所有地址
              </Button>
            )}
            {!!this.totalAmount() && (
              <div className="total_amount">总额:{this.totalAmount()}</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default MintBy0xData
