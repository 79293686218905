import webSiteConfig from '../config/webSiteConfig'

export const openseaBaseUrl = 'https://api.opensea.io'
export const nftgoBaseUrl = 'https://data-api.nftgo.io'
export const blocknativeBaseUrl = 'https://api.blocknative.com'
export const getRankBaseUrl = `https://review.nftflip.ai`

const API = {
  // Java Server
  getNonce: `${webSiteConfig.apiURL}/wallet/nonce`,
  login: `${webSiteConfig.apiURL}/wallet/connect`,
  user: `${webSiteConfig.apiURL}/wallet/user`,
  accountsCanRun: `${webSiteConfig.apiURL}/account/canRun`,
  addMember: `${webSiteConfig.apiURL}/user/manage`,
  x2y2Order: `${webSiteConfig.apiURL}/x2y2/order`,
  x2y2OpenOrder: `${webSiteConfig.apiURL}/x2y2/openOrder`,
  binanceWithdraw: `${webSiteConfig.apiURL}/cex/withDrawBinance`,
  getWithDrawRecord: `${webSiteConfig.apiURL}/cex/withDrawRecord`,
  // nftflip接口
  getRank: `${getRankBaseUrl}/api/contract/getranklistv2`,
  // blocknative接口
  gasInfo: `${blocknativeBaseUrl}/gasprices/blockprices`,
  // 直接连接opensea
  openseaApiAssets: `${openseaBaseUrl}/api/v1/assets`,
  openseaApiEvents: `${openseaBaseUrl}/api/v1/events`,
  openseaCollection: `${openseaBaseUrl}/api/v1/asset_contract`,
  // nftgo 接口
  nftgoAssets: `${nftgoBaseUrl}/:chain/v3/address/nfts`,
  // 获取方法名接口
  getFunNameByHex: `https://www.4byte.directory/api/v1/signatures/?hex_signature={hex_signature}`,
}
export default API
