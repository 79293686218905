import axios from 'axios'
import { ethers } from 'ethers'
import { message } from 'antd'

import API, { openseaBaseUrl, blocknativeBaseUrl, getRankBaseUrl } from './api'
import { getUserToken } from '../tools'
import webSiteConfig from '../config/webSiteConfig'
import { formatX2Y2ItemToOrderData } from '../helper/MakeOrder'
import { handleUrl } from '../helper/ProofStr'

axios.interceptors.request.use(
  (config) => {
    const token = getUserToken()
    if (token && config.url.includes(webSiteConfig.apiURL)) {
      config.headers.Authorzation = `${token}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  (res) => {
    if (res.request.responseURL.includes(blocknativeBaseUrl)) {
      if (!res) {
        return null
      }
      return res
    }
    if (res.request.responseURL.includes(openseaBaseUrl)) {
      if (!res?.data) {
        message.error('调用OpenSea接口失败')
        return null
      }
      return res?.data
    }
    if (
      res.request.responseURL.includes(getRankBaseUrl) ||
      res.request.responseURL.includes(webSiteConfig.apiURL) ||
      res.request.responseURL.includes('nftgo.com')
    ) {
      const { status, code, data, msg } = res?.data
      const { authorzation, wallet } = res?.headers
      if (authorzation) {
        window.localStorage.setItem(
          ethers.utils.id(`${wallet.toLowerCase()}_token`),
          authorzation
        )
      }
      if (code === 200 || status === 1) return data
      if (code === 401) {
        message.error('登陆失效，请重新登录')
        window.location.href = '/login'
      }
      if (code === 403) {
        message.error('你没有权限执行此操作，请联系网站管理员')
      }
      if (code === 500) {
        message.error(msg)
      }
      if (code === 404) {
        message.error('登陆失效，请重新登录')
        window.location.href = '/login'
      }
    } else {
      return res.data
    }
  },
  (error) => {
    console.error('error', error)
    if (JSON.stringify(error).includes(webSiteConfig.apiURL)) {
      const pathname = window?.location?.pathname || ''
      if (pathname.includes('/login') || pathname === '/') return
      message.error('登陆失效，请重新登录')
      setTimeout(() => {
        window.location.href = '/login'
      }, 2000)
    }
  }
)

export const signUp = async (params) => {
  return axios.post(API.login, params)
}

export const getLoginNonce = async (params) => {
  return axios.post(API.getNonce, params)
}

export const getUserInfo = async (params) => {
  return axios.post(API.user, params)
}

export const addMember = async (params) => {
  return axios.post(API.addMember, params)
}

export const makeX2Y2Order = async (params) => {
  return axios.post(API.x2y2Order, params)
}

export const getX2Y2Orders = async (params) => {
  return axios.post(API.x2y2OpenOrder, params)
}

export const getRank = async (params) => {
  let data = await axios({
    url: API.getRank,
    params: {
      u_address: '0xb8b20cdd5d1aff9bcc314f24cab018c027b4bcf5',
      address: params.nftAddress,
    },
  })
  return data?.list || {}
}

export const getGas = async () => {
  let data = await axios.get(API.gasInfo)
  return data
}

export const getListedOrders = async (params) => {
  params = {
    maker: params.walletAddress,
    contract: params.nftAddress,
    limit: 50,
  }
  try {
    const res = (await getX2Y2Orders(params)) || []
    let list = res.map((_) => formatX2Y2ItemToOrderData(_))
    return { list, moreKey: res.next }
  } catch (error) {
    return { list: [], moreKey: null }
  }
}

export const getListedOrderById = async (params) => {
  params = {
    maker: params.walletAddress,
    contract: params.nftAddress,
    tokenId: params.tokenId,
    limit: 1,
  }
  try {
    const res = (await getX2Y2Orders(params)) || []
    return res[0]
  } catch (error) {
    return null
  }
}

export const getSig = async (params) => {
  let { url, body, method } = params
  const reqObj = { url: url, method: method }
  if (method === 'post') {
    url = handleUrl(url)
    reqObj.url = url
    reqObj.data = body
  }
  let res = await axios(reqObj)
  if (typeof res !== 'string') {
    res = JSON.stringify(res)
  }
  return res || null
}

export const getFunParamsByHex = async (hexSignature) => {
  try {
    const res = await axios.get(
      API.getFunNameByHex.replace('{hex_signature}', hexSignature)
    )
    if (!res.results.length) return
    return res.results[0].text_signature
  } catch (error) {
    return
  }
}

export const withdrawFromBinance = async (params, cbk) => {
  const res = await axios.post(API.binanceWithdraw, params)
  cbk && cbk(res)
  return res
}

export const getWithDrawRecord = async (params) => {
  return axios.post(API.getWithDrawRecord, params)
}
