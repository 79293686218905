import React from 'react'
import { ethers } from 'ethers'

import { WalletReg, PrivateKeyReg } from '../constant/Regular'
import CustomProvider from '../component/CustomProvider'
import {
  Divider,
  InputNumber,
  Input,
  Button,
  message,
  Spin,
  Tag,
  Table,
  Radio,
  Select,
} from 'antd'
import { getNftGoTokens } from '../network/nftgo'
import TransferERC721NFTABI from '../constant/ABI/TransferERC721NFT'
import TransferERC1155NFTABI from '../constant/ABI/TransferERC1155NFT'
import { sleep, hideStr, add0xPrefix } from '../tools'
import chainConfig from '../config/chainConfig'

import '../styles/NFTDistribution.css'

const { Option } = Select
const { TextArea } = Input
const ErcType = {
  erc721: 'erc721',
  erc1155: 'erc1155',
}

class NFTDistribution extends React.Component {
  constructor(props) {
    super(props)
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    this.state = {
      provider: null,
      resultList: [],
      maxFee: noEip1559 ? 20 : 200, //最大交易费
      priorityFee: 2, //矿工小费
      nonce: undefined, // 分发的钱包的nonce
      privateKey: ``, // 分发的钱包的私钥
      nftAddress: '', // NFT合约地址
      start: false,
      collectType: ErcType.erc721,
      nftGoApiPrefixForChain: 'eth', // eth:eth主链,bnbapi:BSC币安链
      rootAddress: '',
      nftNumPerAddr: 1, // 每个钱包接收NFt的个数
      loading: false,
    }
  }

  handleNftAddressChange = (address) => {
    if (!WalletReg.test(address) && address !== '') {
      message.error({
        key: 'addressError',
        content: '请输入正确的合约地址',
      })
      return
    }
    this.setState({
      nftAddress: address,
      resultList: [],
      privateKey: '',
      start: false,
    })
  }

  handlePrivateChange = (key) => {
    const { provider } = this.state
    if (!this.state.nftAddress) {
      message.error('请先输入NFT合约地址')
      return
    }
    key = add0xPrefix(key)
    if (!PrivateKeyReg.test(key) && key !== '') {
      message.error({
        key: 'privateKeyError',
        content: '请输入正确的私钥',
      })
      return
    }
    this.setState({
      privateKey: key,
      rootAddress: key ? ethers.utils.computeAddress(key) : '',
    })
    if (!key) return
    const wallet = new ethers.Wallet(key, provider)
    wallet.getTransactionCount().then((nonce) => {
      this.setState({
        nonce: nonce,
      })
    })
  }

  queryTokens = async () => {
    const {
      rootAddress,
      nftAddress,
      collectType,
      nftGoApiPrefixForChain,
    } = this.state
    const parems = {
      nftAddress: nftAddress,
      walletAddress: rootAddress,
      ercType: collectType,
      chainApiPrefix: nftGoApiPrefixForChain,
    }
    let hasData = false
    let errTryCount = 20
    this.setState({ loading: true })
    while (errTryCount > 0 && !hasData) {
      // eslint-disable-next-line
      const tryReq = await new Promise((resolve) => {
        getNftGoTokens(parems)
          .then((tokens) => {
            hasData = true
            this.setState({
              loading: false,
              resultList: tokens.map((token) => ({
                tokenId: token.tokenId,
                tx: '',
                status: 3,
              })),
            })
            resolve(false)
          })
          .catch(async (err) => {
            errTryCount--
            resolve(true)
          })
      })
      if (tryReq) await sleep(1000)
    }
  }

  isPending = () => {
    const { resultList } = this.state
    let hasPending = false
    resultList.forEach((item) => {
      if (item.tx === undefined) {
        hasPending = true
      }
    })
    return hasPending
  }

  clear = () => {
    if (this.isPending()) {
      message.error(`交易正在进行...`)
      return
    }
    this.setState({
      resultList: [],
      start: false,
      addressList: '',
    })
  }

  handleClick = async () => {
    if (this.state.start) {
      this.clear()
      return
    }
    this.setState(
      {
        start: true,
        resultList: this.state.resultList.map((_) => {
          if (_.tokenId && _.address)
            return {
              ..._,
              tx: undefined,
              status: undefined,
            }
          else return _
        }),
      },
      this.send,
    )
  }

  send = async () => {
    const {
      maxFee,
      priorityFee,
      nftAddress,
      rootAddress,
      resultList,
      provider,
      collectType,
      privateKey,
      nonce,
    } = this.state
    const _maxFee = ethers.utils.parseUnits(`${maxFee}`, 'gwei')
    const _priorityFee = ethers.utils.parseUnits(`${priorityFee}`, 'gwei')
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    const gasInfo = noEip1559
      ? {
          gasPrice: _maxFee,
        }
      : {
          maxFeePerGas: _maxFee,
          maxPriorityFeePerGas: _priorityFee,
        }
    const ABI = {
      [ErcType.erc1155]: TransferERC1155NFTABI,
      [ErcType.erc721]: TransferERC721NFTABI,
    }[collectType]
    const wallet = new ethers.Wallet(privateKey, provider)
    const contract = new ethers.Contract(nftAddress, ABI, wallet)
    for (let i = 0, j = 0; i < resultList.length; i++, j++) {
      const overrides = {
        gasLimit: 150000,
        ...gasInfo,
      }
      const item = resultList[i]
      if (!item.address || !item.tokenId) return
      let params = [rootAddress, item.address, item.tokenId]
      contract.estimateGas
        .safeTransferFrom(...params, overrides)
        .then(() => {
          contract
            .safeTransferFrom(...params, {
              ...overrides,
              nonce: nonce + j,
            })
            .then((transaction) => {
              this.setState((prevState) => {
                const resultList = [...prevState.resultList]
                resultList[i].tx = transaction.hash
                return { resultList }
              })
              transaction
                .wait()
                .then(() => {
                  this.setState((prevState) => {
                    const resultList = [...prevState.resultList]
                    resultList[i].status = 1
                    return { resultList }
                  })
                })
                .catch((error) => {
                  this.setState((prevState) => {
                    const resultList = [...prevState.resultList]
                    resultList[i].status = 2
                    return { resultList }
                  })
                })
            })
            .catch((err) => {
              j--
              message.error({
                key: `${item.tokenId}-sendTxError`,
                content: item.tokenId + JSON.stringify(err),
              })
              this.setState((prevState) => {
                const resultList = [...prevState.resultList]
                resultList[i].tx = '交易未发出'
                resultList[i].status = 2
                return {
                  resultList,
                }
              })
            })
        })
        .catch((err) => {
          message.error({
            key: `${item.tokenId}-sendTxError`,
            content: item.tokenId + JSON.stringify(err),
          })
          this.setState((prevState) => {
            const resultList = [...prevState.resultList]
            resultList[i].tx = '交易未发出'
            resultList[i].status = 2
            return {
              resultList,
            }
          })
        })
    }
  }

  onChangeCollectType = (e) => {
    this.setState({
      collectType: e.target.value,
      nftAddress: '',
      resultList: [],
      privateKey: '',
      start: false,
    })
  }

  handleChangeChain = (value) => {
    this.setState({
      nftGoApiPrefixForChain: value,
    })
  }

  changeAddressList = (value) => {
    const { nftNumPerAddr, resultList } = this.state
    this.setState({ addressList: value })
    const arr = value.split(/[(\r\n)\r\n]+/).filter((_) => _)
    for (let i = 0; i < arr.length; i++) {
      const addr = arr[i]
      if (!WalletReg.test(addr)) {
        message.error(`钱包地址${addr}错误，请检查`)
        return
      }
    }
    this.setState({
      resultList: resultList.map((item, i) => {
        return {
          ...item,
          address: arr[Math.floor(i / nftNumPerAddr)],
        }
      }),
    })
  }

  handleNftNumPerAddr = (value) => {
    const { resultList, addressList } = this.state
    const arr = addressList.split(/[(\r\n)\r\n]+/).filter((_) => _)
    this.setState({
      nftNumPerAddr: value,
      resultList: resultList.map((item, i) => {
        return {
          ...item,
          address: arr[Math.floor(i / value)],
        }
      }),
    })
  }

  render() {
    const {
      maxFee,
      priorityFee,
      nftAddress,
      addressList,
      resultList,
      privateKey,
      collectType,
      nftGoApiPrefixForChain,
      rootAddress,
      nftNumPerAddr,
      loading,
      start,
    } = this.state
    const noEip1559 = chainConfig[this.props.chainId]?.noEip1559
    return (
      <div className="page">
        <div className="page_nft_distribution">
          <div className="header">
            <div className="nft_info">
              <div className="top">
                <Select
                  defaultValue={nftGoApiPrefixForChain}
                  onChange={this.handleChangeChain}
                >
                  <Option value="eth">Eth主链</Option>
                  <Option value="bnbapi">BSC链</Option>
                </Select>
                <Radio.Group
                  onChange={this.onChangeCollectType}
                  value={collectType}
                >
                  <Radio value={ErcType.erc721}>ERC-721</Radio>
                  {/* <Radio value={ErcType.erc1155}>ERC-1155</Radio> */}
                </Radio.Group>
                <Input
                  className="nft_address"
                  placeholder={`需要分发的NFT的合约地址`}
                  value={nftAddress}
                  onChange={(e) => this.handleNftAddressChange(e.target.value)}
                />
                <div className="rpc">
                  <CustomProvider
                    onConfirm={(_provider) => {
                      this.setState({ provider: _provider })
                    }}
                  />
                </div>
              </div>
              <div className="section">
                <div className="section_left">
                  <div className="private_key">
                    <Input
                      value={privateKey}
                      type={'password'}
                      placeholder="分发钱包的私钥"
                      onChange={(e) => this.handlePrivateChange(e.target.value)}
                    />
                  </div>
                  <div className="query">
                    <Button
                      type="primary"
                      disabled={!rootAddress || !nftAddress}
                      onClick={this.queryTokens}
                    >
                      查询
                    </Button>
                  </div>
                  <div className="gas_info">
                    {noEip1559 ? (
                      <div className="max_fee">
                        <div className="key">Gas Price:</div>
                        <InputNumber
                          min={0}
                          value={maxFee}
                          onChange={(value) => this.setState({ maxFee: value })}
                        />
                      </div>
                    ) : (
                      [
                        <div className="priority_fee" key={'priority_fee'}>
                          <div className="key">小费:</div>
                          <InputNumber
                            min={0}
                            value={priorityFee}
                            onChange={(value) =>
                              this.setState({ priorityFee: value })
                            }
                          />
                        </div>,
                        <div className="max_fee" key="max_fee">
                          <div className="key">Max Fee :</div>
                          <InputNumber
                            min={0}
                            value={maxFee}
                            onChange={(value) =>
                              this.setState({ maxFee: value })
                            }
                          />
                        </div>,
                      ]
                    )}
                  </div>
                </div>
                <div className="address_list">
                  <TextArea
                    rows={5}
                    disabled={!resultList.length || start}
                    value={addressList}
                    placeholder={'接受钱包地址列表,一行一个'}
                    onChange={(e) => this.changeAddressList(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="list">
            <div className="header">
              <div className="root_address">{rootAddress}</div>
              <div className="action">
                <div className="num_nft_per">
                  <div>每个钱包发送</div>
                  <InputNumber
                    value={nftNumPerAddr}
                    min={1}
                    step={1}
                    onChange={this.handleNftNumPerAddr}
                  />
                  <div>个Token</div>
                </div>
                <Button
                  danger={start}
                  disabled={!addressList}
                  onClick={this.handleClick}
                  type="primary"
                >
                  {start ? '清空' : '开始'}
                </Button>
              </div>
            </div>
            <Table
              size="small"
              bordered
              dataSource={resultList}
              scroll={{ y: 540 }}
              loading={loading}
              rowKey="tokenId"
              columns={[
                {
                  title: 'TokenIDs',
                  dataIndex: 'tokenId',
                  render: (token) => hideStr(token, 3, 3),
                },
                {
                  title: '接收地址',
                  dataIndex: 'address',
                  render: (addr) => (addr ? addr : '-'),
                },
                {
                  title: '交易Hash',
                  dataIndex: 'tx',
                  render: (tx) => {
                    return (
                      <div className="hash">
                        {tx === '' ? (
                          '-'
                        ) : tx ? (
                          <a
                            target="_blank"
                            href={`${
                              chainConfig[this.props.chainId]?.explorer
                            }/tx/${tx}`}
                            rel="noopener noreferrer"
                          >
                            {tx.substring(0, 10) + '...'}
                          </a>
                        ) : (
                          <Spin size="small" />
                        )}
                      </div>
                    )
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  render: (status) => {
                    return (
                      <div className="status">
                        {
                          [
                            <Spin size="small" />,
                            <Tag color="green">成功</Tag>,
                            <Tag color="red">失败</Tag>,
                            <span>-</span>,
                          ][status || 0]
                        }
                      </div>
                    )
                  },
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default NFTDistribution
