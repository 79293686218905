export const BinanceCoins = [
  'ETH',
  'BTC',
  'BNB',
  'USDT',
  'USDC',
  'BUSD',
  'TUSD',
  'MATIC',
  'TRX',
  'SOL',
  'ARB',
  'OP',
  'APT',
  'FTM',
  'GMT',
  'OKB',
  'MEME',
  'CKB',
  'TON',
  'WBETH',
  'MNT',
]
export const BinanceNetworks = [
  'ETH',
  'BTC',
  'BSC',
  'MATIC',
  'ARBITRUM',
  'OPTIMISM',
  'BASE',
  'ZKSYNC-ERA',
  'TRX',
  'SOL',
  'APT',
  'FTM',
  'STARKNET',
  'CKB',
  'TON',
  'MNT',
]
