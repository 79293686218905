import React from 'react'

import '../styles/Feedback.css'

function Feedback() {
  return (
    <div className="page">
      <div className="page_feedback">
        <div className="tip">
          <h3>直接联系我</h3>
          <p>邮箱:wuyunjiangzero@gmail.com</p>
          <p>微信:15756290079</p>
          <p>QQ:335208671</p>
        </div>
      </div>
    </div>
  )
}
export default Feedback
