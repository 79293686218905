import queryString from 'query-string'

export function handleUppOrLow(type, str) {
  return (
    {
      normal: str,
      lower: str.toLowerCase(),
      upper: str.toUpperCase(),
    }[type] || str
  )
}

export function handleUrl(url) {
  if (!url.includes('?')) return url
  return url.substring(0, url.indexOf('?'))
}

export function handleBody(method, url, postBody) {
  let _body
  if (method === 'post' && !postBody) return undefined
  if (method === 'get' && !url.includes('?')) return undefined
  try {
    _body =
      method === 'post'
        ? JSON.parse(postBody)
        : queryString.parse(url.substring(url.indexOf('?') + 1))
  } catch (error) {
    return null
  }
  return _body
}
