import { ethers } from 'ethers'
import React from 'react'
import {
  Divider,
  InputNumber,
  Input,
  message,
  Button,
  Table,
  Radio,
  Checkbox,
} from 'antd'
import {
  hideStr,
  cryptoKeysAndSave,
  getKeysFromLocal,
  downloadFile,
  getBtcWalletsByMnemonic,
  getSolWalletsByMnemonic,
  getEVMWalletsByMnemonic,
  getAddressBalanceOfType,
  sleep,
} from '../tools'

import '../styles/FilterWallet.css'

const walletType = [
  { key: 'eth', text: 'EVM' },
  { key: 'btc', text: 'BTC' },
  { key: 'sol', text: 'Solana' },
]

const btcAddressTypesOptions = [
  { label: '1开头(P2PKH)', value: 'P2PKH' },
  { label: '3开头(P2SH-P2WPKH)', value: 'P2SH_P2WPKH' },
  { label: 'bc1q(P2WPKH)', value: 'P2WPKH' },
  { label: 'bc1p(P2TR)', value: 'P2TR' },
]

class FilterWallet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startIndex: 1, // 第多少个钱包开始
      endIndex: 10, // 第多少个钱包结束
      mnemonic: '',
      resultList: [],
      newGroupName: '',
      type: 'eth',
      btcAddressType: 'P2TR',
      showBalance: false,
    }
  }

  handleMnemonic = (e) => {
    const mnemonic = e.target.value
    this.setState({ mnemonic })
  }

  onChangeWalletType = (e) => {
    this.setState({
      balanceLimit: 0,
      startIndex: 1,
      endIndex: 10,
      resultList: [],
      newGroupName: '',
      type: e.target.value,
    })
  }

  onChangeBtcWalletType = (e) => {
    this.setState({
      resultList: [],
      newGroupName: '',
      btcAddressType: e.target.value,
    })
  }

  verifyMnemonic = () => {
    try {
      ethers.Wallet.fromMnemonic(this.state.mnemonic)
      message.success('助记词正确')
    } catch (error) {
      message.error({
        key: 'mnemonic_error',
        content: '助记词错误, 请检查后重试',
      })
    }
  }
  submit = async () => {
    const { mnemonic, startIndex, endIndex, type, btcAddressType } = this.state
    if (!mnemonic) {
      message.error('请填写助记词')
      return
    }
    this.setState({ resultList: null })
    if (type === 'btc') {
      const btcWallets = getBtcWalletsByMnemonic(mnemonic, startIndex, endIndex)
      this.setState(
        {
          resultList: btcWallets.map((item, i) => {
            const address = {
              P2PKH: item.address1,
              P2SH_P2WPKH: item.address3,
              P2WPKH: item.addressBc1q,
              P2TR: item.addressBc1p,
            }[btcAddressType]
            const privateKey = {
              P2PKH: item.key1,
              P2SH_P2WPKH: item.key3,
              P2WPKH: item.keyBc1q,
              P2TR: item.keyBc1p,
            }[btcAddressType]
            return {
              index: startIndex + i,
              address: address,
              key: privateKey,
              balance: '-',
            }
          }),
        },
        this.getBalance
      )
      return
    }
    if (type === 'sol') {
      const solWallets = getSolWalletsByMnemonic(mnemonic, startIndex, endIndex)
      this.setState(
        {
          resultList: solWallets.map((item, i) => {
            return {
              index: startIndex + i,
              address: item.address,
              key: item.privateKey,
              balance: '-',
            }
          }),
        },
        this.getBalance
      )
      return
    }
    if (type === 'eth') {
      const evmWallets = getEVMWalletsByMnemonic(mnemonic, startIndex, endIndex)
      this.setState(
        {
          resultList: evmWallets.map((item, i) => {
            return {
              index: startIndex + i,
              address: item.address,
              key: item.privateKey,
              balance: '-',
            }
          }),
        },
        this.getBalance
      )
      return
    }
  }

  getBalance = async () => {
    const { type, resultList, showBalance } = this.state
    if (!showBalance) return

    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      if ((i + 1) % 5 === 0) await sleep(1000)
      getAddressBalanceOfType(item.address, type).then((balance) => {
        this.setState({
          resultList: this.state.resultList.map((wallet) => {
            if (wallet.address === item.address) wallet.balance = balance
            return wallet
          }),
        })
      })
    }
  }

  downloadCsvFile = () => {
    const { type, mnemonic, resultList } = this.state
    let csv = '',
      proxy = '',
      excAddr = ''
    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      csv += `${type},${item.key},${type},${proxy},${excAddr},${item.address},${mnemonic}\n`
    }
    downloadFile(`${type}-Walllets.csv`, csv)
  }

  copyAddress = () => {
    const { resultList } = this.state
    const addressStr = resultList.map((_) => _.address).join('\n')
    navigator.clipboard.writeText(addressStr)
    message.success({
      key: 'copyAll',
      content: `已复制`,
    })
  }
  copyKey = () => {
    const { resultList } = this.state
    const addressStr = resultList.map((_) => _.key).join('\n')
    navigator.clipboard.writeText(addressStr)
    message.success({
      key: 'copyAll',
      content: `已复制`,
    })
  }
  exportGroup = () => {
    const { resultList, newGroupName } = this.state
    const keys = resultList.map((_) => _.key).join('\n')
    cryptoKeysAndSave([
      ...getKeysFromLocal(),
      {
        id: `${Date.now()}`,
        name: newGroupName,
        keys: keys,
        seleted: false,
      },
    ])
    message.success({
      key: 'copyAll',
      content: `已设置到钱包组`,
    })
  }

  handleShowBalance = (e) => {
    this.setState({
      showBalance: e.target.checked,
    })
  }

  render() {
    const {
      balanceLimit,
      startIndex,
      endIndex,
      resultList,
      type,
      showBalance,
    } = this.state
    return (
      <div className="page page_filter">
        <Radio.Group onChange={this.onChangeWalletType} value={type}>
          {walletType.map((item, i) => {
            return (
              <Radio key={i} value={item.key}>
                <b>{item.text}</b>
              </Radio>
            )
          })}
        </Radio.Group>
        <Divider dashed />
        {type === 'btc' && (
          <div>
            <b>派生路径：</b>
            <Radio.Group
              defaultValue="P2TR"
              onChange={this.onChangeBtcWalletType}
            >
              {btcAddressTypesOptions.map((item, i) => {
                return (
                  <Radio key={i} value={item.value}>
                    {item.label}
                  </Radio>
                )
              })}
            </Radio.Group>
          </div>
        )}
        <Divider />
        <div className="form_info">
          <div className="mnemonic">
            <Input
              onChange={this.handleMnemonic}
              placeholder="请输入助记词, 每个单词以空格隔开"
            />
            <Button type="primary" onClick={this.verifyMnemonic}>
              验证
            </Button>
          </div>
          <div className="count_limit">
            <div>第</div>
            <InputNumber
              value={startIndex}
              min={1}
              step={1}
              onChange={(value) => this.setState({ startIndex: value })}
            />
            <div>到</div>
            <InputNumber
              value={endIndex}
              min={startIndex}
              step={1}
              onChange={(value) => this.setState({ endIndex: value })}
            />
            <div>个钱包</div>
          </div>
          <Checkbox
            checked={showBalance}
            onChange={this.handleShowBalance}
            className="show_balance"
          >
            筛选余额
          </Checkbox>
          {showBalance && (
            <div className="balance_limit">
              <div>≥</div>
              <InputNumber
                value={balanceLimit}
                onChange={(value) => this.setState({ balanceLimit: value })}
              />
              <div>
                的钱包（筛选余额会使用网络请求数据，降低速率，非必要请勿开)
              </div>
            </div>
          )}
          <div className="submit">
            <Button type={'primary'} onClick={this.submit}>
              确定
            </Button>
          </div>
        </div>
        <Divider />
        <div className="table_list">
          <Table
            size="small"
            bordered
            dataSource={resultList}
            loading={resultList === null}
            scroll={{
              x: true,
            }}
            columns={[
              {
                title: '编号',
                dataIndex: 'index',
              },
              {
                title: '地址',
                dataIndex: 'address',
              },
              {
                title: '私钥',
                dataIndex: 'key',
                render: (_key) => hideStr(_key, 3, 4),
              },
              {
                title: '余额',
                dataIndex: 'balance',
              },
              {
                title: '操作',
                dataIndex: 'key',
                render: (_key, record) => {
                  return (
                    <Button
                      size="small"
                      type={'primary'}
                      onClick={() => {
                        navigator.clipboard.writeText(_key)
                        message.success({
                          key: 'copyAll',
                          content: `已复制`,
                        })
                      }}
                    >
                      复制私钥
                    </Button>
                  )
                },
              },
            ]}
          />
        </div>
        {!!resultList?.length && (
          <div className="copy">
            <Button onClick={this.downloadCsvFile} type="primary">
              下载CSV文件
            </Button>
            <Button type="primary" onClick={this.copyAddress}>
              复制所有地址
            </Button>
            <Button type="primary" onClick={this.copyKey}>
              复制所有私钥
            </Button>
            <Divider type="vertical" />
            {type === 'eth' && [
              <Input
                key={0}
                onChange={(e) =>
                  this.setState({ newGroupName: e.target.value })
                }
                placeholder="钱包组名称"
              />,
              <Button key={1} type="primary" onClick={this.exportGroup}>
                导入到钱包组
              </Button>,
            ]}
          </div>
        )}
      </div>
    )
  }
}
export default FilterWallet
