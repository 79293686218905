import React from 'react'
import '../styles/component/DocLink.css'

function DocLink({ text, link }) {
  return (
    <div className="doc_link">
      <a href={link} target={'_blank'}>
        {text}
      </a>
    </div>
  )
}
export default DocLink
