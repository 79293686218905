import React, { useState, useEffect, useImperativeHandle } from 'react'
import { ethers } from 'ethers'
import { Button, Input, Divider, Modal, message, Upload } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  ImportOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  cryptoKeysAndSave,
  getKeysFromLocal,
  add0xPrefix,
  downloadFile,
  getCurrentAddr,
} from '../tools'

import '../styles/component/PrivateKeyGroup.css'

const { TextArea } = Input

function PrivateKeyGrounp({ cRef, disabled }) {
  useImperativeHandle(cRef, () => ({
    openManageGroupsModal: showManageGroupsModal,
    getSelectedGroups: getSelectedGroups,
    setGroups: setGroups,
    getGroups: () => groups,
  }))
  const [first, setFirst] = useState(true)
  const [groups, setGroups] = useState([])
  const [newGroupName, setNewGroupName] = useState('')
  const [newPrivateKeys, setNewPrivateKeys] = useState('')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getInfoFromLocalStorage()
  }, [])

  useEffect(() => {
    if (!first) {
      cryptoKeysAndSave(groups)
    } else {
      setFirst(false)
    }
    // eslint-disable-next-line
  }, [groups])

  const getInfoFromLocalStorage = () => {
    setGroups(getKeysFromLocal())
  }

  const getSelectedGroups = () => {
    return groups.filter((_) => _.seleted)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) return
    setGroups(reorder(groups, result.source.index, result.destination.index))
  }

  const clickGroup = (group) => {
    if (disabled) return
    setGroups(
      groups.map((_) => {
        if (_.id === group.id) {
          return { ..._, seleted: !_.seleted }
        } else {
          return _
        }
      }),
    )
  }

  const showManageGroupsModal = () => {
    setVisible(true)
  }

  const renderGroups = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={'sort_box'}
              {...provided.droppableProps}
            >
              {groups?.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style }}
                      className={`group_item ${
                        group.seleted ? 'seleted' : ''
                      } ${disabled ? 'disabled' : ''}`}
                      onClick={() => clickGroup(group)}
                    >
                      {group.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const addGroup = () => {
    if (!newGroupName || !newPrivateKeys) {
      message.error('填写名称和私钥')
      return
    }
    if (groups.find((_) => _.name === newGroupName)) {
      message.error('分组名称已存在')
      return
    }
    const keys = newPrivateKeys?.split(/[(\r\n)\r\n]+/).filter((_) => !!_)
    for (let i = 0; i < keys.length; i++) {
      try {
        new ethers.Wallet(add0xPrefix(keys[i]))
      } catch (error) {
        message.error(`请检查私钥${keys[i]}是否正确`)
        return
      }
    }
    const _groups = [
      ...groups,
      {
        id: `${Date.now()}`,
        name: newGroupName,
        keys: newPrivateKeys,
        seleted: false,
      },
    ]
    setGroups(_groups)
    setNewGroupName('')
    setNewPrivateKeys('')
    message.success(`${newGroupName}分组添加成功`)
  }

  const deleteGroup = (id) => {
    const _groups = groups.filter((_) => _.id !== id)
    setGroups(_groups)
    message.error(`${newGroupName}分组已删除`)
  }

  const editGroup = (id) => {
    const targetGroup = groups.find((_) => _.id === id)
    const _groups = groups.filter((_) => _.id !== id)
    setNewGroupName(targetGroup.name)
    setNewPrivateKeys(targetGroup.keys)
    setGroups(_groups)
  }

  const renderManageGroups = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={'sort_box'}
              {...provided.droppableProps}
            >
              {groups?.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style }}
                      className={`group_item`}
                    >
                      <EditOutlined
                        className="edit"
                        onClick={() => {
                          editGroup(group.id)
                        }}
                      />
                      {group.name}
                      <CloseOutlined
                        className="close"
                        onClick={() => {
                          deleteGroup(group.id)
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const exportGroups = () => {
    const account = getCurrentAddr()
    const localGroupsStr = localStorage.getItem(
      ethers.utils.id(`${account.toLowerCase()}_groups`),
    )
    downloadFile('groups_config.txt', localGroupsStr)
  }

  const importGroups = (info) => {
    const reader = new FileReader()
    reader.onload = () => {
      const _list = getKeysFromLocal(reader.result)
      setGroups([...groups, ..._list])
    }
    reader.readAsText(info)
    return false
  }

  return (
    <div className="private_key_group_bos">
      {/* <Button
          icon={<SettingOutlined />}
          size="small"
          onClick={showManageGroupsModal}
        >
          管理分组
        </Button> */}
      <div className="lists">{renderGroups()}</div>
      <Modal
        title="管理钱包分组"
        open={visible}
        footer={null}
        maskClosable={false}
        onCancel={() => {
          if (newGroupName) {
            message.warning('你还有编辑中的分组未保存，请先保存或清除')
            return false
          }
          setVisible(false)
          setNewGroupName('')
          setNewPrivateKeys('')
        }}
      >
        <div className="manage_private_key_group_modal">
          <p>添加常用的钱包分组, 使用时点击即可, 拖动可排序</p>
          <div className="new_group_form">
            <div className="name">
              <Input
                placeholder="分组名称"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
              <Button type="primary" onClick={addGroup}>
                确认添加
              </Button>
            </div>
            <TextArea
              rows={3}
              placeholder={'请填写私钥列表，一行一个'}
              value={newPrivateKeys}
              onChange={(e) => setNewPrivateKeys(e.target.value)}
            />
          </div>
          <div className="export_import">
            <Upload
              name="groups"
              showUploadList={false}
              beforeUpload={importGroups}
            >
              <Button size="small" icon={<ImportOutlined />}>
                导入组
              </Button>
            </Upload>
            <Button
              size="small"
              icon={<ExportOutlined />}
              onClick={exportGroups}
            >
              导出组
            </Button>
          </div>
          <Divider />
          <div className="filter_list">{renderManageGroups()}</div>
        </div>
      </Modal>
    </div>
  )
}
export default PrivateKeyGrounp
