import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd'
import blockIcon from '../assets/img/block.svg'
import ethereumIcon from '../assets/img/ethereum.svg'
import gasIcon from '../assets/img/gas.svg'
import { DecryptAES, sleep } from '../tools'
import '../styles/component/GasAndEthPrice.css'

const { Panel } = Collapse

function GasAndEthPrice({ onGasChange, onEthPriceChange, socketConnected }) {
  const [nextBlockGasPrice, setNextBlockGasPrice] = useState(null)
  const [ethPrice, setEthPrice] = useState(0)

  useEffect(() => {
    if (socketConnected) setGas()
    // eslint-disable-next-line
  }, [socketConnected])

  useEffect(() => {
    if (nextBlockGasPrice) onGasChange(nextBlockGasPrice)
    if (ethPrice) onEthPriceChange(ethPrice)
    // eslint-disable-next-line
  }, [ethPrice, nextBlockGasPrice])

  const setGas = async () => {
    await sleep(1000)
    window.socket.send("42['subscribe', 'GasPrice']")
    window.socket.send("42['subscribe', 'EthPrice']")
    window.socket.addEventListener('message', (event) => {
      try {
        const res = JSON.parse(DecryptAES(event.data))
        if (res.event === 'gas') {
          setNextBlockGasPrice(JSON.parse(res.data))
        }
        if (res.event === 'price') {
          setEthPrice(Number(res.data || 0).toFixed(1))
        }
      } catch (error) {
        console.error(error)
      }
    })
  }

  const getGasColor = () => {
    if (!nextBlockGasPrice) return
    const blockPrices = nextBlockGasPrice?.blockPrices[0]
    const onePriority = blockPrices.estimatedPrices[0].maxPriorityFeePerGas
    const warnValue = blockPrices?.baseFeePerGas + onePriority
    if (warnValue > 260) return 'alarm_gas'
    if (warnValue > 120) return 'warning_gas'
  }

  const blockPrices = nextBlockGasPrice?.blockPrices[0]
  const blockNumber = nextBlockGasPrice?.currentBlockNumber || 0

  return (
    <div className={`gas_info ${getGasColor()}`}>
      <Collapse>
        <Panel
          showArrow={false}
          header={
            <div className="header">
              <div className="gas">
                <img src={gasIcon} alt="img" />
                <span>{(blockPrices?.baseFeePerGas || 0).toFixed(1)}</span>
                <span>
                  （
                  {(
                    blockPrices?.estimatedPrices[0]?.maxPriorityFeePerGas || 0
                  ).toFixed(1)}
                  ）
                </span>
              </div>
              <div className="block_price">
                <div className="block">
                  <img src={blockIcon} alt="img" />
                  {blockNumber}
                </div>
                <div className="price">
                  <img src={ethereumIcon} alt="img" />${ethPrice}
                </div>
              </div>
            </div>
          }
        >
          <div className="detail">
            {blockPrices && (
              <div className="gas_list" key={0}>
                {blockPrices?.estimatedPrices?.map((gas, index) => {
                  if (index > 2) return null
                  return (
                    <div className={`gas_block${index} gas_block `} key={index}>
                      <div className="txt">
                        {['极快', '正常', '缓慢'][index]}
                      </div>
                      <div className="value">
                        <p className="max_fee">
                          Max：<b>{gas.maxFeePerGas}</b>
                        </p>
                        <p className="priority_fee">
                          Priority：<b>{gas.maxPriorityFeePerGas}</b>
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}
export default GasAndEthPrice
