import React, { useContext, useEffect } from 'react'
import { Button } from 'antd'
import { LoginOutlined } from '@ant-design/icons'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import '../styles/Login.css'
import { Context } from '../context'

function Login() {
  const { account, userInfo, handleLogin } = useContext(Context)
  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    const redirect = search.replace('?redirect=', '')
    if (userInfo && userInfo.roleList?.length > 0) navigate(redirect || '/')
    // eslint-disable-next-line
  }, [userInfo])

  return (
    <div className="page page_login">
      {!(userInfo && !userInfo.roleList.length) && (
        <Button
          type="primary"
          icon={<LoginOutlined />}
          size={'large'}
          onClick={handleLogin}
        >
          {account ? '签名登录' : '使用 MetaMask 登录'}
        </Button>
      )}
      {userInfo && !userInfo.roleList.length && (
        <div className="no_permissions">
          你还不是网站会员,前往
          <Link to={`/BecomeVip`}>升级会员</Link>
        </div>
      )}
    </div>
  )
}
export default Login
