import React, { useState } from 'react'
import { Input, Button, message, Select, Table, Spin } from 'antd'
import { getSig } from '../network/site'
import { handleUppOrLow, handleBody } from '../helper/ProofStr'

import '../styles/ProofStr.css'
import { hideStr, sleep } from '../tools'

const { Option } = Select
const { TextArea } = Input

function ProofStr() {
  const [list, setList] = useState([])
  const [querys, setQuerys] = useState(``)
  const [url, setUrl] = useState('')
  const [uppOrLow, setUpOrLow] = useState('normal')
  const [method, setMethod] = useState('get')
  const [postBody, setPostBody] = useState('')

  const handleClickQuery = async () => {
    let queryList = querys.split(/[(\r\n)\r\n]+/).filter((_) => _)
    if (!url || !queryList.length) {
      message.error('请填写查询链接和参数')
      return
    }
    let tempList = queryList.map((_) => ({ key: _ }))
    setList(tempList)
    for (let i = 0; i < queryList.length; i++) {
      const query = handleUppOrLow(uppOrLow, queryList[i])
      const _url = url.replace(/\{query\}/g, query)
      const _postBody = postBody.replace(/\{query\}/g, query)
      const _body = handleBody(method, _url, _postBody)
      if (_body === null) {
        message.error('设置请求参数出错，请检查后重试')
      }
      const res = await getSig({
        url: _url,
        method,
        body: _body,
      })
      tempList = tempList.map((_) => {
        return _.key === query ? { key: _.key, res } : _
      })
      setList(tempList)
      if (i % 5 === 0) await sleep(2000)
    }
  }

  const selectBefore = (
    <Select value={method} className="select-before" onChange={setMethod}>
      <Option value="get">GET</Option>
      <Option value="post">POST</Option>
    </Select>
  )

  const selectAfter = (
    <Select value={uppOrLow} className="select-after" onChange={setUpOrLow}>
      <Option value="normal">原始大小写</Option>
      <Option value="lower">参数全小写</Option>
      <Option value="upper">参数全大写</Option>
    </Select>
  )

  const copyRes = (res) => {
    if (res) {
      navigator.clipboard.writeText(res)
      message.success(`已复制`)
    }
  }

  return (
    <div className="page">
      <div className="page_proof">
        <div className="query">
          <div className="url">
            <h1>查询链接：</h1>
            <div className="request_info">
              <Input
                addonBefore={selectBefore}
                addonAfter={selectAfter}
                value={url}
                placeholder="查询链接，参数使用{query}代替"
                onChange={(e) => {
                  setUrl(e.target.value)
                }}
              />
            </div>
            {method === 'post' && (
              <TextArea
                rows={6}
                value={postBody}
                onChange={(e) => setPostBody(e.target.value)}
                placeholder={`填写请求数据，key和value请都用引号包裹，如下
{
  "address": "{query}"
}`}
              />
            )}
          </div>
          <div className="value">
            <h1>query参数：</h1>
            <TextArea
              rows={method === 'post' ? 8 : 1}
              value={querys}
              onChange={(e) => setQuerys(e.target.value)}
              placeholder={`输入查询参数query，一行一个`}
            />
          </div>
        </div>
        <div>
          <div className="res_header">
            <h1>查询结果：</h1>
            <Button onClick={handleClickQuery} type={'primary'}>
              请求接口
            </Button>
          </div>
          <div className="res_str">
            <Table
              size="small"
              bordered
              dataSource={list}
              rowKey="key"
              columns={[
                {
                  title: '参数',
                  dataIndex: 'key',
                  render: (key) => `${hideStr(key, 6, 4)}`,
                },
                {
                  title: '请求结果',
                  dataIndex: 'res',
                  render: (res) => {
                    if (res === undefined) return <Spin size="small" />
                    return (
                      <div className="res_value" onClick={() => copyRes(res)}>
                        {res || '-'}
                      </div>
                    )
                  },
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProofStr
