const ChildrenNumABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'getDeployedContracts',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'index',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'contractAddr',
            type: 'address',
          },
        ],
        internalType: 'struct MultiMintManager.ContractArrayIndexes[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

export default ChildrenNumABI
