import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from 'react'
import { ethers } from 'ethers'
import { Connection } from '@solana/web3.js'
import { Button, Input, Tag, Spin, message } from 'antd'
import { Context } from '../context'
import chainConfig from '../config/chainConfig'

import '../styles/component/CustomProvider.css'

function CustomProvider({ cRef, rpc, onConfirm, size }) {
  const { chainId } = useContext(Context)
  const [rpcResSpeed, setRpcResSpeed] = useState({ status: 0, text: '' })
  const [url, setUrl] = useState('')

  useImperativeHandle(cRef, () => ({
    testSpeed: testRPC,
  }))

  const getProvider = (rpcUrl) => {
    try {
      if (rpcUrl.includes('solana')) {
        return new Connection(rpcUrl)
      } else {
        return ethers.getDefaultProvider(rpcUrl)
      }
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    if (rpc) {
      setUrl(rpc)
      return
    }
    const rpcUrl =
      window.localStorage.getItem(`${chainId}_rpc`) || chainConfig[chainId]?.rpc
    if (rpcUrl) {
      setUrl(rpcUrl)
      onConfirm && onConfirm(getProvider(rpcUrl))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (url && !rpc) window.localStorage.setItem(`${chainId}_rpc`, url)
    // eslint-disable-next-line
  }, [url])

  const testRPC = async () => {
    try {
      const provider = getProvider(url)
      if (!provider) {
        message.error('请检查Rpc节点链接是否正确')
        return
      }
      setRpcResSpeed({ status: 3 })
      const startTime = new Date().getTime()
      if (url.includes('solana')) {
        await provider.getMinimumLedgerSlot()
      } else {
        await provider.detectNetwork()
      }
      const endTime = new Date().getTime()
      setRpcResSpeed({
        status: 1,
        text: `连接成功，响应时间 ${endTime - startTime} 毫秒`,
      })
      onConfirm && onConfirm(provider)
    } catch (error) {
      onConfirm && onConfirm(null)
      setRpcResSpeed({
        status: 2,
        text: `连接失败! 请检查节点地址`,
      })
    }
  }

  const copy = () => {
    navigator.clipboard.writeText(rpc)
    message.success('已复制到剪切板')
  }

  return (
    <div className="provider_box">
      <div className="rpc_res_info">
        {!!rpcResSpeed.status &&
          (rpcResSpeed.status === 3 ? (
            <Spin size="small" />
          ) : (
            <Tag color={['', 'green', 'red'][rpcResSpeed.status]}>
              {rpcResSpeed.text}
            </Tag>
          ))}
      </div>
      <Input
        placeholder="RPC节点地址"
        value={url}
        size={size}
        disabled={rpc}
        onChange={(e) => setUrl(e.target.value)}
      />
      {rpc ? (
        <Button type="primary" onClick={copy} size={size}>
          复制
        </Button>
      ) : (
        <Button type="primary" onClick={testRPC} size={size}>
          确认RPC节点
        </Button>
      )}
    </div>
  )
}
export default CustomProvider
