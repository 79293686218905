import React from 'react'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import {
  Divider,
  InputNumber,
  Button,
  Input,
  message,
  Spin,
  Tag,
  Table,
  Popconfirm,
  Switch,
  Tooltip,
  Radio,
  Select,
  Checkbox,
} from 'antd'
import { WalletReg } from '../constant/Regular'
import { PlusOutlined, DeleteOutlined, RocketOutlined } from '@ant-design/icons'
import '../styles/NFTBuy.css'
import CustomProvider from '../component/CustomProvider'
import chainConfig from '../config/chainConfig'
import DocLink from '../component/DocLink'
import { sleep, updateResultListByWallets, xSmallNumToStr } from '../tools'
import ResolvePrivateKey from '../component/ResolvePrivateKey'
import SimpleGas from '../component/SimpleGas'

const { TextArea } = Input

const contractABI = {
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
}
const queryABI = {
  stateMutability: 'view',
  type: 'function',
}

class NFTBuy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callTip: 0,
      maxFee: 0, //最大交易费
      priorityFee: 0, //矿工小费
      gasLimit: 200000, //Gas Limit
      price: 0, //单笔交易价格
      parseArr: [], // Mint方法的参数
      nftAddress: '', // NFT合约地址
      mintFun: '', // mint方法
      resultList: [], // 执行结果
      start: false,
      upMaxFee: 400,
      forceSend: false, // 强制发送交易
      userInfo: props.userInfo,

      actionType: 'write',
      ouputType: 'uint256',
    }
  }

  componentWillUnmount() {
    this.setState({
      resultList: [],
      start: false,
    })
  }

  componentDidMount() {
    // 从本地缓存中读取上次填写的信息
    this.getInfoFromLocalStorage()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      nftAddress,
      mintFun,
      parseArr,
      price,
      priorityFee,
      maxFee,
      gasLimit,
    } = this.state
    // 如果下面几个发生变化 就更新本地存储
    if (nftAddress !== prevState.nftAddress)
      localStorage.setItem('NFTBuy-nftAddress', nftAddress || '')
    if (mintFun !== prevState.mintFun)
      localStorage.setItem('NFTBuy-mintFun', mintFun || '')
    if (parseArr !== prevState.parseArr)
      localStorage.setItem('NFTBuy-parseArr', JSON.stringify(parseArr))
    if (price !== prevState.price)
      localStorage.setItem('NFTBuy-price', price || '')
    if (priorityFee !== prevState.priorityFee)
      localStorage.setItem('NFTBuy-priorityFee', priorityFee || '')
    if (maxFee !== prevState.maxFee)
      localStorage.setItem('NFTBuy-maxFee', maxFee || '')
    if (gasLimit !== prevState.gasLimit)
      localStorage.setItem('NFTBuy-gasLimit', gasLimit || '')
  }
  // 冲本地缓存中设置初始信息
  getInfoFromLocalStorage = () => {
    const nftAddress = localStorage.getItem('NFTBuy-nftAddress') || ''
    const mintFun = localStorage.getItem('NFTBuy-mintFun') || ''
    const parseArr = localStorage.getItem('NFTBuy-parseArr') || '[]'
    const price = localStorage.getItem('NFTBuy-price') || 0
    const priorityFee = localStorage.getItem('NFTBuy-priorityFee') || 0
    const gasLimit = localStorage.getItem('NFTBuy-gasLimit') || 200000
    const maxFee = localStorage.getItem('NFTBuy-maxFee') || 0
    this.setState({
      nftAddress,
      mintFun,
      parseArr: JSON.parse(parseArr),
      price,
      priorityFee,
      maxFee,
      gasLimit,
    })
  }

  handleClick = async () => {
    if (this.isPending()) {
      message.error(`交易正在进行...`)
      return
    }
    if (this.state.start) {
      // 停止
      return this.setState(
        {
          start: false,
          callTip: '',
          resultList: [],
        },
        this.validatePrivateKey
      )
    }
    this.setState(
      {
        start: true,
        resultList: this.state.resultList.map((_) => ({
          ..._,
          hashs: [...new Array(_.count)].map((_) => [undefined, undefined]),
        })),
      },
      this.send
    )
  }

  // 删除一条地址
  delAddress = (record) => {
    const { resultList } = this.state
    this.setState({
      resultList: resultList.filter((_) => _.address !== record.address),
    })
  }

  clearPrivateKey = () => {
    this.setState({
      resultList: [],
      start: false,
    })
  }

  walletsLoaded = (wallets) => {
    this.setState({
      resultList: wallets.map((_) => {
        return {
          ..._,
          address: _.wallet.address,
          hashs: [['', 3]],
          count: 1,
        }
      }),
    })
  }

  walletsDataUpdate = (wallet) => {
    const { resultList } = this.state
    this.setState({
      resultList: updateResultListByWallets(resultList, wallet),
    })
  }

  isPending = () => {
    const { resultList } = this.state
    let hasPending = false
    resultList.forEach((wallet) => {
      wallet.hashs.forEach((hash) => {
        if (hash[0] && hash[1] === undefined) {
          hasPending = true
        }
      })
    })
    return hasPending
  }

  setProvider = (provider) => {
    this.setState({
      provider: provider,
    })
  }

  printError = (err, result, address) => {
    message.error({
      key: `${address}-sendTxError`,
      content: address + JSON.stringify(err),
    })
    result = result.map((_) => {
      if (_.address === address) {
        _.hashs = _.hashs.map((tx) => {
          if (!tx[0]) {
            tx[0] = '交易未发出,不扣Gas费'
            tx[1] = 2
          }
          return tx
        })
      }
      return _
    })
    this.setState({
      resultList: result,
    })
  }

  sendTransaction = (contract, parseArr, wallet, isUpSpeed, upSpeedCbk) => {
    const { resultList, mintFun } = this.state
    let result = resultList
    const callback = (transaction) => {
      result = result.map((_) => {
        if (_.address === wallet.address) {
          let txs
          if (wallet.hashs.find((_) => _[2] === transaction.nonce)) {
            txs = wallet.hashs.map((_) => {
              if (_[2] === transaction.nonce) {
                message.info(
                  `已替换${wallet.address}钱包Nonce为${transaction.nonce}的交易`
                )
                return [transaction.hash, undefined, transaction.nonce]
              }
              return _
            })
          } else {
            txs = [
              [transaction.hash, undefined, transaction.nonce],
              ...wallet.hashs,
            ]
            txs.pop()
          }
          _.hashs = txs
        }
        return _
      })
      this.setState({
        resultList: result,
      })
      transaction
        .wait()
        .then((res) => {
          if (res.status === 1) {
            result = result.map((_) => {
              if (_.address === wallet.address) {
                _.hashs = _.hashs.map((tx) => {
                  if (tx[0] === transaction.hash) {
                    tx[1] = 1
                  }
                  return tx
                })
              }
              return _
            })
            this.setState({
              resultList: result,
            })
          }
        })
        .catch((err) => {
          result = result.map((_) => {
            if (_.address === wallet.address) {
              _.hashs = _.hashs.map((tx) => {
                if (tx[0] === transaction.hash) {
                  tx[1] = 2
                }
                return tx
              })
            }
            return _
          })
          this.setState({
            resultList: result,
          })
        })
    }
    if (isUpSpeed) {
      const w = new ethers.Wallet(wallet.key, this.state.provider)
      w.sendTransaction(parseArr)
        .then((res) => {
          callback(res)
          upSpeedCbk()
        })
        .catch((err) => {
          upSpeedCbk()
          message.error('加速未发出, 详情查看控制台打印信息')
        })
    } else {
      contract.estimateGas[mintFun](...parseArr)
        .then(
          contract[mintFun](...parseArr)
            .then(callback)
            .catch((err) => {
              message.error('交易未发出, 详情查看控制台打印信息')
              this.printError(err, result, wallet.address)
            })
        )
        .catch((err) => {
          message.error('交易未发出, 详情查看控制台打印信息')
          this.printError(err, result, wallet.address)
        })
    }
  }

  send = async () => {
    const {
      maxFee,
      priorityFee,
      gasLimit,
      price,
      mintFun,
      nftAddress,
      resultList,
      provider,
      parseArr,
      forceSend,
    } = this.state
    contractABI.inputs = parseArr.map((_, index) => ({
      internalType: _.type,
      name: `parse${index}`,
      type: _.type,
    }))
    contractABI.name = mintFun
    const abi = `[${JSON.stringify(contractABI)}]`

    let parses = parseArr.map((_) => {
      if (_.type.includes('tuple')) {
        return JSON.parse(_.value)
      } else if (_.type.includes('[]')) {
        return _.value.split(',')
      } else {
        return _.value
      }
    })

    const _maxFee = ethers.utils.parseUnits(`${maxFee}`, 'gwei')
    const _priorityFee = ethers.utils.parseUnits(`${priorityFee}`, 'gwei')
    const _value = ethers.utils.parseUnits(`${xSmallNumToStr(price)}`, 'ether')
    let gasInfo = {}
    if (chainConfig[this.props.chainId]?.noEip1559) {
      gasInfo = {
        gasPrice: _maxFee,
      }
    } else {
      gasInfo = {
        maxFeePerGas: _maxFee,
        maxPriorityFeePerGas: _priorityFee,
      }
    }
    const overrides = {
      ...gasInfo,
      value: _value,
      gasLimit: gasLimit,
    }

    if (!forceSend) {
      const wallet = new ethers.Wallet(resultList[0].key, provider)
      const contract = new ethers.Contract(nftAddress, abi, wallet)
      do {
        if (!this.state.start) return
        try {
          await contract.estimateGas[mintFun](...[...parses, overrides])
          this.setState({ callTip: '' })
          break
        } catch (err) {
          const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
          this.setState({
            callTip: this.state.start ? `合约不能被调用，重试中:${now}...` : '',
            errDetail: JSON.stringify(err).substring(0, 300),
          })
          await sleep(1000)
        }
      } while (true)
    }
    // start send
    message.info({ key: 'send_tx', content: '开始发送交易' })
    for (let i = 0; i < resultList.length; i++) {
      const item = resultList[i]
      // 根据参数名和参数，生成mint ABI
      let contract = new ethers.Contract(nftAddress, abi, item.wallet)
      for (let j = 0; j < item.count; j++) {
        overrides.nonce = item.nonce + j
        this.sendTransaction(contract, [...parses, overrides], item)
      }
    }
  }

  // 自定义函数参数时
  changeParse = (index, key, value) => {
    const { parseArr } = this.state
    const newArr = JSON.parse(JSON.stringify(parseArr))
    newArr[index][key] = value
    this.setState({
      parseArr: newArr,
    })
  }

  changeCount = (address, count) => {
    const res = this.state.resultList.map((item) => {
      if (item.address === address) {
        item.hashs = [...new Array(count)].map((_) => ['', 3])
        item.count = count
      }
      return item
    })
    this.setState({
      resultList: res,
    })
  }

  handleForceSend = (checked) => {
    this.setState({
      forceSend: checked,
    })
  }

  handleQueryClick = async () => {
    const {
      addressList,
      provider,
      nftAddress,
      parseArr,
      mintFun,
      ouputType,
      isArray,
    } = this.state
    if (!addressList.length) return
    this.setState({
      addressList: addressList.map((_) => ({
        ..._,
        queryRes: '',
      })),
    })
    queryABI.inputs = parseArr.map((_, index) => ({
      internalType: _.type,
      name: `parse${index}`,
      type: _.type,
    }))
    queryABI.name = mintFun
    queryABI.outputs = [
      { name: 'res', type: isArray ? `${ouputType}[]` : ouputType },
    ]
    const abi = `[${JSON.stringify(queryABI)}]`
    const queryContract = new ethers.Contract(nftAddress, abi, provider)

    for (let i = 0; i < addressList.length; i++) {
      const item = addressList[i]
      let parses = JSON.parse(JSON.stringify(parseArr)).map((_) => {
        if (_.value.includes('{address}')) {
          _.value = _.value.replace(/\{address\}/g, item.address)
        }
        if (_.type.includes('tuple')) {
          return JSON.parse(_.value)
        } else if (_.type.includes('[]')) {
          return _.value.split(',')
        } else {
          return _.value
        }
      })
      queryContract[mintFun](...parses)
        .then((res) => {
          let resStr = res.toString()
          if (ouputType === 'uint256')
            resStr = resStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          // if (ouputType === 'tuple')
          // if (ouputType === 'bytes32')
          this.setState({
            addressList: this.state.addressList.map((_) => {
              if (_.address === item.address) {
                _.queryRes = resStr
              }
              return _
            }),
          })
        })
        .catch((error) => {
          console.log(error)
        })
      if (i % 5 === 0) await sleep(1000)
    }
  }

  handleQueryResult = (value) => {
    this.setState({
      ouputType: value,
    })
  }

  handleTargetAddressListChange = (addrListStr) => {
    const addrList = addrListStr.split(/[(\r\n)\r\n]+/)
    for (let i = 0; i < addrList.length; i++) {
      const addr = addrList[i]
      if (!WalletReg.test(addr) && addr !== '') {
        message.error({
          key: 'addressError',
          content: '请输入正确的查询地址',
        })
        return
      }
    }
    this.setState({
      addressList: addrList.map((_) => ({
        address: _,
      })),
    })
  }

  upSpeed = () => {
    const { upMaxFee, upPriorityFee, resultList } = this.state
    return new Promise((resolve) => {
      let count = 0,
        completed = 0
      const finish = () => {
        completed++
        if (count === completed) {
          this.setState({
            upMaxFee: upMaxFee * 1.2,
            upPriorityFee: upPriorityFee * 1.2,
          })
          resolve()
        }
      }
      resultList.forEach((wallet) => {
        wallet.hashs.forEach(async (hash) => {
          if (hash[0] && hash[1] === undefined) {
            count++
            this.state.provider.getTransaction(hash[0]).then((tx) => {
              const _maxFee = ethers.utils.parseUnits(`${upMaxFee}`, 'gwei')
              const _priority = ethers.utils.parseUnits(
                `${upPriorityFee || upMaxFee}`,
                'gwei'
              )
              let gasInfo = {}
              if (chainConfig[this.props.chainId]?.noEip1559) {
                gasInfo = {
                  gasPrice: _maxFee,
                }
              } else {
                gasInfo = {
                  maxFeePerGas: _maxFee,
                  maxPriorityFeePerGas: _priority,
                }
              }
              this.sendTransaction(
                null,
                {
                  nonce: tx.nonce,
                  ...gasInfo,
                  to: tx.to,
                  value: tx.value,
                  data: tx.data,
                  gasLimit: tx.gasLimit,
                },
                wallet,
                true,
                finish
              )
            })
          }
        })
      })
    })
  }

  render() {
    const {
      maxFee,
      priorityFee,
      gasLimit,
      price,
      mintFun,
      nftAddress,
      resultList,
      start,
      parseArr,
      callTip,
      upMaxFee,
      upPriorityFee,
      actionType,
      addressList,
      ouputType,
    } = this.state
    return (
      <div className="page">
        <DocLink
          text={'合约交互使用说明'}
          link={'https://docs.cryptoweb3.tools/nft-qiang-gou'}
        />
        <div className="nft_page">
          <div className="header">
            <Radio.Group
              value={actionType}
              onChange={(e) => {
                this.setState({ actionType: e.target.value })
              }}
            >
              <Radio.Button value="write">Write</Radio.Button>
              <Radio.Button value="read">Read</Radio.Button>
            </Radio.Group>
            <div className="rpc">
              <CustomProvider onConfirm={this.setProvider} />
            </div>
          </div>
          <Divider />
          {actionType !== 'read' && (
            <div className="section">
              {chainConfig[this.props.chainId]?.noEip1559 ? (
                <div className="max_fee">
                  <div className="key">Gas Price:</div>
                  <InputNumber
                    min={0}
                    value={maxFee}
                    onChange={(value) => this.setState({ maxFee: value })}
                  />
                </div>
              ) : (
                [
                  <div className="priority_fee" key="priority_fee">
                    <div className="key">小费:</div>
                    <InputNumber
                      min={0}
                      value={priorityFee}
                      onChange={(value) =>
                        this.setState({ priorityFee: value })
                      }
                    />
                  </div>,
                  <div className="max_fee" key="max_fee">
                    <div className="key">Max Fee :</div>
                    <InputNumber
                      min={0}
                      value={maxFee}
                      onChange={(value) => this.setState({ maxFee: value })}
                    />
                  </div>,
                ]
              )}
              <div className="gas_limit">
                <div className="key">Gas Limit:</div>
                <InputNumber
                  min={0}
                  value={gasLimit}
                  onChange={(value) => this.setState({ gasLimit: value })}
                />
              </div>
              <SimpleGas />
            </div>
          )}
          <div className="section">
            {actionType !== 'read' && (
              <div className="price">
                <div className="key">总价:</div>
                <InputNumber
                  min={0}
                  value={price}
                  onChange={(value) => this.setState({ price: value })}
                />
              </div>
            )}
            <div className="address">
              <div className="key">合约地址:</div>
              <Input
                value={nftAddress}
                onChange={(e) =>
                  this.setState({ nftAddress: e.target.value.trim() })
                }
              />
            </div>
          </div>
          <div className="section">
            <div className="mint_fun">
              <div className="key">交互方法:</div>
              <Input
                value={mintFun}
                onChange={(e) =>
                  this.setState({ mintFun: e.target.value.trim() })
                }
              />
            </div>
            <div className="title_box">
              <div className="key">方法参数</div>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => {
                  this.setState({
                    parseArr: [...parseArr, { type: '', value: '' }],
                  })
                }}
              >
                添加参数
              </Button>
            </div>
          </div>
          <div className="section parse_section">
            {parseArr.map((parse, index) => {
              return (
                <div className="parse_box" key={index}>
                  参数类型：
                  <Input
                    value={parse.type}
                    placeholder="如address,bool,uint256等"
                    onChange={(e) =>
                      this.changeParse(index, 'type', e.target.value.trim())
                    }
                  />
                  参数值：
                  <Input
                    value={parse.value}
                    placeholder={
                      actionType === 'read'
                        ? '参数值，填{address}将替换查询钱包地址'
                        : '参数值'
                    }
                    onChange={(e) =>
                      this.changeParse(index, 'value', e.target.value.trim())
                    }
                  />
                  <div
                    className="del_btn"
                    onClick={() => {
                      const arr = [...parseArr]
                      arr.splice(index, 1)
                      this.setState({
                        parseArr: arr,
                      })
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              )
            })}
          </div>
          <Divider />
          {actionType === 'read' && (
            <div className="read_params">
              <div className="target_address">
                <TextArea
                  rows={5}
                  placeholder="待查询地址"
                  onChange={(e) =>
                    this.handleTargetAddressListChange(e.target.value)
                  }
                />
              </div>
              <div className="handle_res_script">
                <div className="output_type">
                  <div>返回数据类型：</div>
                  <Select
                    defaultValue="uint256"
                    onChange={this.handleQueryResult}
                    value={ouputType}
                    options={[
                      { value: 'uint256', label: 'Uint256' },
                      { value: 'string', label: 'String' },
                      { value: 'address', label: 'Address' },
                      { value: 'bool', label: 'Bool' },
                      { value: 'tuple', label: 'Tuple' },
                      { value: 'bytes32', label: 'Bytes32' },
                    ]}
                  />
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ isArray: e.target.checked })
                    }
                  >
                    数组
                  </Checkbox>
                </div>
                <Button onClick={this.handleQueryClick} type="primary">
                  查询
                </Button>
              </div>
            </div>
          )}
          {actionType === 'read' && (
            <div className="list">
              <Table
                size="small"
                bordered
                dataSource={addressList}
                scroll={{ y: 340 }}
                rowKey="address"
                pagination={false}
                columns={[
                  {
                    title: '地址',
                    width: 400,
                    dataIndex: 'address',
                  },
                  {
                    title: '结果',
                    dataIndex: 'queryRes',
                    className: 'query_res',
                    render: (queryRes) =>
                      queryRes === '' ? <Spin size="small" /> : queryRes,
                  },
                ]}
              ></Table>
            </div>
          )}
          {actionType !== 'read' && (
            <ResolvePrivateKey
              provider={this.state.provider}
              onWalletsLoaded={this.walletsLoaded}
              onWalletsDataUpdate={this.walletsDataUpdate}
              onClear={this.clearPrivateKey}
            />
          )}
          <Divider />
          {actionType !== 'read' && (
            <div className="list">
              <div className="action">
                <div className="start_time">
                  <div className="time_to_start">
                    <div className="key">强制发送:</div>
                    <Switch
                      disabled={start}
                      onChange={(checked) => this.handleForceSend(checked)}
                    />
                  </div>
                  {!!callTip && (
                    <div className="re_try_count">
                      {callTip}
                      <Tooltip title={this.state.errDetail} defaultOpen={true}>
                        <span>[详情]</span>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div>
                  {this.isPending() && (
                    <Popconfirm
                      title={
                        <div className="up_speed">
                          {chainConfig[this.props.chainId]?.noEip1559 ? (
                            <InputNumber
                              placeholder="GasPrice"
                              value={upMaxFee}
                              onChange={(val) =>
                                this.setState({
                                  upMaxFee: val,
                                })
                              }
                            />
                          ) : (
                            [
                              <InputNumber
                                placeholder="PriorityFee"
                                key="PriorityFee"
                                value={upPriorityFee}
                                onChange={(val) =>
                                  this.setState({
                                    upPriorityFee: val,
                                  })
                                }
                              />,
                              <InputNumber
                                placeholder="MaxFee"
                                key="MaxFee"
                                value={upMaxFee}
                                onChange={(val) =>
                                  this.setState({
                                    upMaxFee: val,
                                  })
                                }
                              />,
                            ]
                          )}
                        </div>
                      }
                      onConfirm={this.upSpeed}
                      icon={<RocketOutlined style={{ fontSize: '26px' }} />}
                    >
                      <Button type="primary">一键加速</Button>
                    </Popconfirm>
                  )}
                  <Button
                    danger={start}
                    onClick={this.handleClick}
                    type="primary"
                  >
                    {start ? '停止' : '开始'}
                  </Button>
                </div>
              </div>
              <Table
                size="small"
                bordered
                dataSource={resultList}
                scroll={{ y: 340 }}
                rowKey="address"
                columns={[
                  {
                    title: '地址',
                    dataIndex: 'address',
                    render: (_address) => (
                      <a
                        href={`${
                          chainConfig[this.props.chainId].explorer
                        }/address/${_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {_address}
                      </a>
                    ),
                  },
                  {
                    title: '余额',
                    dataIndex: 'balance',
                    render: (_balance) =>
                      _balance ? (
                        _balance.substring(0, 6)
                      ) : (
                        <Spin size="small" />
                      ),
                  },
                  {
                    title: '请求次数',
                    width: 108,
                    dataIndex: 'count',
                    render: (count, record) => {
                      return (
                        <InputNumber
                          min={1}
                          max={10}
                          step={1}
                          defaultValue={count}
                          disabled={start}
                          onChange={(value) =>
                            this.changeCount(record.address, value)
                          }
                        />
                      )
                    },
                  },
                  {
                    title: '交易Hash',
                    dataIndex: 'hashs',
                    className: 'hash_cell',
                    render: (hashs) => {
                      return (
                        <div>
                          {hashs.map((tx, i) => {
                            return (
                              <div key={i} className="hash">
                                {tx[0] === '' ? (
                                  '-'
                                ) : tx[0] ? (
                                  <a
                                    target={'_blank'}
                                    href={`${
                                      chainConfig[this.props.chainId].explorer
                                    }/tx/${tx[0]}`}
                                  >
                                    {tx[0].substring(0, 10) + '...'}
                                  </a>
                                ) : (
                                  <Spin size="small" />
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )
                    },
                  },
                  {
                    title: '状态',
                    dataIndex: 'hashs',
                    className: 'status_cell',
                    render: (hashs, record) => {
                      return (
                        <div>
                          {hashs.map((tx, i) => {
                            return (
                              <div key={i} className="status">
                                {
                                  [
                                    <Spin size="small" />,
                                    <Tag color="green">成功</Tag>,
                                    <Tag color="red">失败</Tag>,
                                    <span>-</span>,
                                  ][tx[1] || 0]
                                }
                              </div>
                            )
                          })}
                        </div>
                      )
                    },
                  },
                  {
                    title: '操作',
                    width: '15%',
                    key: 'action',
                    render: (text, record) =>
                      !start ? (
                        <div
                          className="del"
                          onClick={() => this.delAddress(record)}
                        >
                          删除
                        </div>
                      ) : (
                        '-'
                      ),
                  },
                ]}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default NFTBuy
