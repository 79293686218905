const Role = {
  ADMIN: 'admin',
  MemberMonth: 'month',
  MemberYear: 'year',
  MemberTrial: 'trial',
}

export function getCanUseAccountNum(role) {
  const count =
    {
      [Role.MemberMonth]: 10,
      [Role.MemberYear]: -1,
    }[role] || 0
  return count
}

export default Role
