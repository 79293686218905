import { ethers } from 'ethers'
import { Connection, PublicKey } from '@solana/web3.js'
import * as bitcoin from 'bitcoinjs-lib'
import CryptoJS from 'crypto-js'
import chainConfig from '../config/chainConfig'
import { WebSiteDomain } from '../constant/String'
import ecc from '@bitcoinerlab/secp256k1'
import * as TronWeb from 'tronweb'
import * as solanaWeb3 from '@solana/web3.js'
const bip39 = require('bip39')
const ed25519 = require('ed25519-hd-key')

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function str2hex(str) {
  return Number(str).toString(16)
}

export function amountToStrByDecimals(amount, decimals) {
  amount = `${amount}`
  const i = amount.includes('.')
    ? amount.substring(amount.indexOf('.') + 1).length
    : 0
  let res =
    window.BigInt(amount.replace('.', '')) * window.BigInt(decimals) + ''
  return res.substring(0, res.length - i)
}

export function xSmallNumToStr(xSmallNum) {
  let s = xSmallNum.toString()
  if (!s.includes('.') && !s.includes('-')) return xSmallNum
  let zeroCount = 0,
    l = 0
  if (s.includes('-')) {
    zeroCount = s.substring(s.indexOf('-') + 1)
    s = s.substring(0, s.lastIndexOf('e-'))
  }
  if (s.includes('.')) {
    l = s.toString().replace(/\d+\.(\d*)/, (str, res) => {
      return res.length
    })
  }
  return xSmallNum.toFixed(Number(l) + Number(zeroCount))
}

export function hideStr(str, startLen, endLen) {
  if (!str || !str.length) return ''
  if (str.length < startLen + endLen) return str
  return (
    str.substring(0, startLen) + '****' + str.substring(str.length - endLen)
  )
}

export function getChainNameById(chainId) {
  return chainConfig[chainId]?.name || '未收录的'
}

export function getChainCoinNameById(chainId) {
  return chainConfig[chainId]?.nativeCurrency?.symbol || '未收录的'
}

export function handleIpfsUrl(url = '') {
  if (url.includes('ipfs://')) {
    url = url.replace('ipfs://', 'https://ipfs.io/ipfs/')
  }
  return url
}

export function getResourceURLAndType(resource) {
  const now = Date.now()
  const img = resource.image || resource.image_url
  const animation = resource.animation_url
  let url = ''
  if (img) {
    url = `${handleIpfsUrl(img)}?${now}`
  }
  if (animation) {
    url = `${handleIpfsUrl(animation)}?${now}`
  }
  return [animation ? 'animation' : 'image', url]
}

export function isOperableAccount(address, allowList) {
  if (!address) return false
  if (!allowList) return false
  address = address.toLowerCase()
  allowList = allowList.map((_) => _?.toLowerCase())
  return allowList.includes(address)
}

export function getRankByTokenID(rankInfo, tokenID) {
  if (!rankInfo) return '未知'
  const { tokenIds = [], rarityCountNorm = [] } = rankInfo
  const i = tokenIds.findIndex((_) => _ === tokenID)
  if (i === -1) return '未知'
  return rarityCountNorm[i] || '未知'
}

export function getCurrentAddr() {
  const localSelectedAddress = window.localStorage.getItem('selectedAddress')
  const account = window.ethereum.selectedAddress || localSelectedAddress
  return account || ''
}

export function getUserToken() {
  const account = getCurrentAddr()
  return window.localStorage.getItem(
    ethers.utils.id(`${account.toLowerCase()}_token`)
  )
}

// 添加0x前缀
export function add0xPrefix(key = '') {
  return /^0(x|X)/.test(key) || !key ? key : `0x${key}`
}

export function updateResultListByWallets(resultList, target) {
  return resultList.map((_) =>
    _.address === target.wallet.address ? { ..._, ...target } : _
  )
}

export function cryptoKeys(str) {
  str = JSON.stringify(str)
  let res = window.btoa(unescape(encodeURIComponent(str)))
  if (res.length > 5) res = res.slice(0, 5) + 'sB' + res.slice(5)
  return res
}

export function cryptoKeysAndSave(str) {
  const account = getCurrentAddr()
  localStorage.setItem(
    ethers.utils.id(`${account.toLowerCase()}_groups`),
    cryptoKeys(str)
  )
}

export function getKeysFromLocal(_groupsStr) {
  const account = getCurrentAddr()
  const _groups =
    _groupsStr ||
    localStorage.getItem(ethers.utils.id(`${account.toLowerCase()}_groups`))
  if (!_groups || _groups === '[]') return []
  const str = _groups.slice(0, 5) + _groups.slice(7)
  let res = decodeURIComponent(escape(window.atob(str)))
  return JSON.parse(res)
}

export function downloadFile(filename, text) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

const sKey = CryptoJS.enc.Utf8.parse(WebSiteDomain.split('').reverse().join(''))
// aes加密
export function EncryptAES(s) {
  const encrypted = CryptoJS.AES.encrypt(s, sKey, {
    iv: sKey,
    mode: CryptoJS.mode.ECB, // ECB算法
    padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
  })

  return encrypted.toString(CryptoJS.format.Hex)
}
// aes解密
export function DecryptAES(s) {
  const decrypted = CryptoJS.AES.decrypt(s, sKey, {
    iv: sKey,
    mode: CryptoJS.mode.ECB, // ECB算法
    format: CryptoJS.format.Hex,
    padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
  })

  return decrypted.toString(CryptoJS.enc.Utf8)
}

// CBC 模式下的 AES 加密
// function EncryptAES_CBC(key, plaintext) {
//   if (!key) return plaintext
//   const sKey = CryptoJS.enc.Utf8.parse(key)
//   const iv = CryptoJS.lib.WordArray.random(16) // 生成 16 字节的随机 IV
//   const encrypted = CryptoJS.AES.encrypt(plaintext, sKey, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   })
//   return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Hex)
// }

export function createTronWallets(sameWords, count) {
  const path = `m/44'/195'/0'/0/`
  const tronWeb = new TronWeb({ fullHost: chainConfig.TRON.apiUrl })
  const resArr = []
  if (sameWords) {
    const mnemonic = bip39.generateMnemonic()
    for (let i = 0; i < count; i++) {
      let hdNode = tronWeb.fromMnemonic(mnemonic, path + i)
      resArr.push({
        address: hdNode.address,
        privateKey: hdNode.privateKey.substring(2),
        mnemonic: mnemonic,
      })
    }
  } else {
    for (let i = 0; i < count; i++) {
      const mnemonic = bip39.generateMnemonic()
      let hdNode = tronWeb.fromMnemonic(mnemonic, path + 0)
      resArr.push({
        address: hdNode.address,
        privateKey: hdNode.privateKey,
        mnemonic: mnemonic,
      })
    }
  }
  return resArr
}

export function getSolWalletsByMnemonic(mnemonic, start, end) {
  const resArr = []
  const seed = bip39.mnemonicToSeedSync(mnemonic).toString('hex')
  for (let i = start - 1; i < end; i++) {
    const derivedSeed = ed25519.derivePath(`m/44'/501'/${i}'/0'`, seed).key
    const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed)
    resArr.push({
      address: keypair.publicKey.toBase58(),
      privateKey: ethers.utils.base58.encode(keypair.secretKey),
      mnemonic: mnemonic,
    })
  }
  return resArr
}

export function getTronWalletsByMnemonic(mnemonic, start, end) {
  const path = `m/44'/195'/0'/0/`
  const tronWeb = new TronWeb({ fullHost: chainConfig.TRON.apiUrl })
  const resArr = []
  for (let i = start - 1; i < end; i++) {
    let hdNode = tronWeb.fromMnemonic(mnemonic, path + i)
    resArr.push({
      address: hdNode.address,
      privateKey: hdNode.privateKey.substring(2),
      mnemonic: mnemonic,
    })
  }
  return resArr
}

export async function getAddressBalanceOfType(address, type) {
  if (type === 'eth') {
    const rpcUrl = `https://rpc.ankr.com/eth`
    const provider = ethers.getDefaultProvider(rpcUrl)
    const res = await provider.getBalance(address)
    return ethers.utils.formatEther(res)
  }
  if (type === 'btc') {
    const apiUrl = `https://blockstream.info/api`
    const res = await fetch(`${apiUrl}/address/${address}/utxo`)
    const utxos = await res.json()
    let a = 0
    let b = 0
    utxos.forEach((utxo) => {
      if (utxo.status.confirmed) a += utxo.value
      else b += utxo.value
    })
    let str = `${a / 10 ** 8}`
    if (b) str = str + ` (Pending: ${a / 10 ** 8})`
    return str
  }
  if (type === 'sol') {
    const fun = async () => {
      const rpcs = [
        `https://mainnet.helius-rpc.com/?api-key=8a43af9b-f09e-46f8-b9c4-9b6224c83f32`,
        `https://solana-mainnet.g.alchemy.com/v2/UTq2t48xz0kK-ymL__UAPHYeifHC0B-Q`,
        `https://aged-muddy-arrow.solana-mainnet.quiknode.pro/35dfb1b464f8f07be99579423c84f7a4b55ecf3e/`,
      ]
      const index = Math.floor(Math.random() * rpcs.length)
      let connection = new Connection(rpcs[index], { commitment: 'processed' })
      try {
        const res = await connection.getBalance(new PublicKey(address))
        return res
      } catch (error) {
        return fun()
      }
    }

    return `${(await fun()) / 10 ** 9}`
  }
}

export function getEVMWalletsByMnemonic(mnemonic, start, end) {
  const resArr = []
  const path = `m/44'/60'/0'/0/`
  let seed = ethers.utils.mnemonicToSeed(mnemonic)
  let rootNode = ethers.utils.HDNode.fromSeed(seed)
  for (let i = start - 1; i < end; i++) {
    let hdNode = rootNode.derivePath(path + i)
    resArr.push({
      address: hdNode.address,
      privateKey: hdNode.privateKey,
      mnemonic: mnemonic,
    })
  }
  return resArr
}

export function createSolWallets(sameWords, count) {
  const resArr = []
  if (sameWords) {
    const mnemonic = bip39.generateMnemonic()
    const seed = bip39.mnemonicToSeedSync(mnemonic).toString('hex')
    for (let i = 0; i < count; i++) {
      const derivedSeed = ed25519.derivePath(`m/44'/501'/${i}'/0'`, seed).key
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed)
      resArr.push({
        address: keypair.publicKey.toBase58(),
        privateKey: ethers.utils.base58.encode(keypair.secretKey),
        mnemonic: mnemonic,
      })
    }
  } else {
    for (let i = 0; i < count; i++) {
      const mnemonic = bip39.generateMnemonic()
      const seed = bip39.mnemonicToSeedSync(mnemonic).toString('hex')
      const derivedSeed = ed25519.derivePath(`m/44'/501'/0'/0'`, seed).key
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed)
      resArr.push({
        address: keypair.publicKey.toBase58(),
        privateKey: ethers.utils.base58.encode(keypair.secretKey),
        mnemonic: mnemonic,
      })
    }
  }
  return resArr
}

export function createEthWallets(sameWords, count) {
  const path = `m/44'/60'/0'/0/`
  const resArr = []
  if (sameWords) {
    const mnemonic = bip39.generateMnemonic()
    let seed = ethers.utils.mnemonicToSeed(mnemonic)
    let rootNode = ethers.utils.HDNode.fromSeed(seed)
    for (let i = 0; i < count; i++) {
      let hdNode = rootNode.derivePath(path + i)
      resArr.push({
        address: hdNode.address,
        privateKey: hdNode.privateKey,
        mnemonic: mnemonic,
      })
    }
  } else {
    for (let i = 0; i < count; i++) {
      const mnemonic = bip39.generateMnemonic()
      let seed = ethers.utils.mnemonicToSeed(mnemonic)
      let rootNode = ethers.utils.HDNode.fromSeed(seed)
      let hdNode = rootNode.derivePath(path + 0)
      resArr.push({
        address: hdNode.address,
        privateKey: hdNode.privateKey,
        mnemonic: mnemonic,
      })
    }
  }
  return resArr
}

export function getBtcWalletsByMnemonic(mnemonic, start, end) {
  const resArr = []
  const bc1qPath = "m/84'/0'/0'/0/"
  const bc1pPath = "m/86'/0'/0'/0/"
  const start1Path = "m/44'/0'/0'/0/"
  const start3Path = "m/49'/0'/0'/0/"
  const bip39 = require('bip39')
  const { BIP32Factory } = require('bip32')
  const bip32 = BIP32Factory(ecc)
  bitcoin.initEccLib(ecc)
  const seed = bip39.mnemonicToSeedSync(mnemonic)
  const root = bip32.fromSeed(seed, bitcoin.networks.bitcoin)
  for (let i = start - 1; i < end; i++) {
    const bc1qChild = root.derivePath(bc1qPath + i)
    const keyBc1q = bc1qChild.privateKey.toString('hex')
    const bc1qPublicKey = bc1qChild.publicKey
    const bc1qObj = bitcoin.payments.p2wpkh({ pubkey: bc1qPublicKey })
    const bc1qAddress = bc1qObj.address
    const bc1pChild = root.derivePath(bc1pPath + i)
    const keyBc1p = bc1pChild.privateKey.toString('hex')
    const bc1pPublicKey = bc1pChild.publicKey
    const bc1pObj = bitcoin.payments.p2tr({
      internalPubkey: Buffer.from(bc1pPublicKey.subarray(1, 33)),
    })
    const bc1pAddress = bc1pObj.address
    const start1Child = root.derivePath(start1Path + i)
    const key1 = start1Child.privateKey.toString('hex')
    const start1PublicKey = start1Child.publicKey
    const start1Obj = bitcoin.payments.p2pkh({ pubkey: start1PublicKey })
    const start1Address = start1Obj.address
    const start3Child = root.derivePath(start3Path + i)
    const key3 = start3Child.privateKey.toString('hex')
    const start3PublicKey = start3Child.publicKey
    const start3Obj = bitcoin.payments.p2sh({
      redeem: bitcoin.payments.p2wpkh({ pubkey: start3PublicKey }),
    })
    const start3Address = start3Obj.address
    resArr.push({
      addressBc1q: bc1qAddress,
      addressBc1p: bc1pAddress,
      address1: start1Address,
      address3: start3Address,
      key1: key1,
      key3: key3,
      keyBc1q: keyBc1q,
      keyBc1p: keyBc1p,
      mnemonic: mnemonic,
    })
  }
  return resArr
}

export function createBtcWallets(sameWords, count) {
  const resArr = []
  const bc1qPath = "m/84'/0'/0'/0/"
  const bc1pPath = "m/86'/0'/0'/0/"
  const start1Path = "m/44'/0'/0'/0/"
  const start3Path = "m/49'/0'/0'/0/"
  const bip39 = require('bip39')
  const { BIP32Factory } = require('bip32')
  const bip32 = BIP32Factory(ecc)
  bitcoin.initEccLib(ecc)
  if (sameWords) {
    let mnemonic = bip39.generateMnemonic()
    const seed = bip39.mnemonicToSeedSync(mnemonic)
    const root = bip32.fromSeed(seed, bitcoin.networks.bitcoin)
    for (let i = 0; i < count; i++) {
      const bc1qChild = root.derivePath(bc1qPath + i)
      const keyBc1q = bc1qChild.privateKey.toString('hex')
      const bc1qPublicKey = bc1qChild.publicKey
      const bc1qObj = bitcoin.payments.p2wpkh({ pubkey: bc1qPublicKey })
      const bc1qAddress = bc1qObj.address
      const bc1pChild = root.derivePath(bc1pPath + i)
      const keyBc1p = bc1pChild.privateKey.toString('hex')
      const bc1pPublicKey = bc1pChild.publicKey
      const bc1pObj = bitcoin.payments.p2tr({
        internalPubkey: Buffer.from(bc1pPublicKey.subarray(1, 33)),
      })
      const bc1pAddress = bc1pObj.address
      const start1Child = root.derivePath(start1Path + i)
      const key1 = start1Child.privateKey.toString('hex')
      const start1PublicKey = start1Child.publicKey
      const start1Obj = bitcoin.payments.p2pkh({ pubkey: start1PublicKey })
      const start1Address = start1Obj.address
      const start3Child = root.derivePath(start3Path + i)
      const key3 = start3Child.privateKey.toString('hex')
      const start3PublicKey = start3Child.publicKey
      const start3Obj = bitcoin.payments.p2sh({
        redeem: bitcoin.payments.p2wpkh({ pubkey: start3PublicKey }),
      })
      const start3Address = start3Obj.address
      resArr.push({
        addressBc1q: bc1qAddress,
        addressBc1p: bc1pAddress,
        address1: start1Address,
        address3: start3Address,
        key1: key1,
        key3: key3,
        keyBc1q: keyBc1q,
        keyBc1p: keyBc1p,
        mnemonic: mnemonic,
      })
    }
  } else {
    for (let i = 0; i < count; i++) {
      let mnemonic = bip39.generateMnemonic()
      const seed = bip39.mnemonicToSeedSync(mnemonic)
      const root = bip32.fromSeed(seed, bitcoin.networks.bitcoin)
      const bc1qChild = root.derivePath(bc1qPath + 0)
      const keyBc1q = bc1qChild.privateKey.toString('hex')
      const bc1qPublicKey = bc1qChild.publicKey
      const bc1qObj = bitcoin.payments.p2wpkh({ pubkey: bc1qPublicKey })
      const bc1qAddress = bc1qObj.address
      const bc1pChild = root.derivePath(bc1pPath + 0)
      const keyBc1p = bc1pChild.privateKey.toString('hex')
      const bc1pPublicKey = bc1pChild.publicKey
      const bc1pObj = bitcoin.payments.p2tr({
        internalPubkey: Buffer.from(bc1pPublicKey.subarray(1, 33)),
      })
      const bc1pAddress = bc1pObj.address
      const start1Child = root.derivePath(start1Path + 0)
      const key1 = start1Child.privateKey.toString('hex')
      const start1PublicKey = start1Child.publicKey
      const start1Obj = bitcoin.payments.p2pkh({ pubkey: start1PublicKey })
      const start1Address = start1Obj.address
      const start3Child = root.derivePath(start3Path + 0)
      const key3 = start3Child.privateKey.toString('hex')
      const start3PublicKey = start3Child.publicKey
      const start3Obj = bitcoin.payments.p2sh({
        redeem: bitcoin.payments.p2wpkh({ pubkey: start3PublicKey }),
      })
      const start3Address = start3Obj.address
      resArr.push({
        addressBc1q: bc1qAddress,
        addressBc1p: bc1pAddress,
        address1: start1Address,
        address3: start3Address,
        key1: key1,
        key3: key3,
        keyBc1q: keyBc1q,
        keyBc1p: keyBc1p,
        mnemonic: mnemonic,
      })
    }
  }
  return resArr
}
