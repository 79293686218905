import React, { useState } from 'react'
import { Radio, Divider, InputNumber, message, Spin, Checkbox } from 'antd'
import { Modal, Input, Button, Form, Space } from 'antd'
import '../styles/CreateWallets.css'
import {
  cryptoKeys,
  downloadFile,
  createBtcWallets,
  createEthWallets,
  createSolWallets,
  createTronWallets,
  getBtcWalletsByMnemonic,
  getSolWalletsByMnemonic,
  getEVMWalletsByMnemonic,
  getTronWalletsByMnemonic,
} from '../tools'
const walletType = [
  { key: 'eth', text: 'EVM' },
  { key: 'sol', text: 'SOL' },
  { key: 'trx', text: 'TRON' },
  { key: 'btc', text: 'BTC' },
]
const btcAddressTypesOptions = [
  { label: '1开头(P2PKH)', value: 'P2PKH' },
  { label: '3开头(P2SH-P2WPKH)', value: 'P2SH_P2WPKH' },
  { label: 'bc1q(P2WPKH)', value: 'P2WPKH' },
  { label: 'bc1p(P2TR)', value: 'P2TR' },
]
let walletDownloadTypeOptions = [
  { label: 'EVM', value: 'eth' },
  { label: 'SOL', value: 'sol' },
  { label: 'TRON', value: 'trx' },
  { label: 'BTC（1开头）', value: 'P2PKH' },
  { label: 'BTC（3开头）', value: 'P2SH_P2WPKH' },
  { label: 'BTC（bc1q）', value: 'P2WPKH' },
  { label: 'BTC（bc1p）', value: 'P2TR' },
]

function CreateWallets() {
  const [type, setType] = useState('eth')
  const [count, setCount] = useState()
  const [creating, setCreating] = useState(false)

  const [sameWords, setSameWords] = useState(false)

  const [resultArr, setResultArr] = useState([])

  const [form] = Form.useForm()

  const onChangeWalletType = (e) => {
    setType(e.target.value)
    setResultArr([])
    setCount()
  }
  const create = async () => {
    let wallets = []
    switch (type) {
      case 'eth':
        const ethWallets = createEthWallets(sameWords, count) || []
        for (let i = 0; i < ethWallets.length; i++) {
          const { address, privateKey, mnemonic } = ethWallets[i]
          wallets.push({
            address: address,
            privateKey: privateKey,
            mnemonic: mnemonic,
          })
        }
        break
      case 'sol':
        const solWallets = createSolWallets(sameWords, count) || []
        for (let i = 0; i < solWallets.length; i++) {
          const { address, privateKey, mnemonic } = solWallets[i]
          wallets.push({
            address: address,
            privateKey: privateKey,
            mnemonic: mnemonic,
          })
        }
        break
      case 'trx':
        const trxWallets = createTronWallets(sameWords, count) || []
        for (let i = 0; i < trxWallets.length; i++) {
          const { address, privateKey, mnemonic } = trxWallets[i]
          wallets.push({
            address: address,
            privateKey: privateKey,
            mnemonic: mnemonic,
          })
        }
        break
      case 'btc':
        const btcWallets = createBtcWallets(sameWords, count) || []
        for (let i = 0; i < btcWallets.length; i++) {
          const {
            addressBc1p,
            addressBc1q,
            mnemonic,
            address1,
            address3,
            key1,
            key3,
            keyBc1p,
            keyBc1q,
          } = btcWallets[i]
          wallets.push({
            addressBc1p,
            addressBc1q,
            address1,
            address3,
            key1,
            key3,
            keyBc1p,
            keyBc1q,
            mnemonic,
          })
        }
        break
      default:
        break
    }
    setResultArr(wallets)
    setCreating(false)
  }

  const saveWalletFile = () => {
    Modal.info({
      footer: null,
      content: (
        <Form form={form} onFinish={(values) => downloadCsvFile(values)}>
          <Form.Item
            name="name"
            label="文件名"
            rules={[{ required: true, message: '请输入文件名!' }]}
          >
            <Input />
          </Form.Item>
          {type === 'btc' && (
            <div className="btc_address_type">
              <Form.Item name="btcAddressType">
                <Radio.Group defaultValue="P2TR">
                  {btcAddressTypesOptions.map((item, i) => {
                    return (
                      <Radio key={i} value={item.value}>
                        {item.label}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
          )}
          <Divider />
          {sameWords && (
            <Form.Item name="walletDownloadTypes" label="同时下载其他钱包">
              <Checkbox.Group options={walletDownloadTypeOptions} />
            </Form.Item>
          )}
          <Space align={'end'} style={{ float: 'right' }}>
            <Button onClick={() => Modal.destroyAll()}>取消</Button>
            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
              保存
            </Button>
          </Space>
        </Form>
      ),
    })
  }

  const downloadCsvFile = (formValues) => {
    let { name, btcAddressType, walletDownloadTypes, list, customType } =
      formValues
    let csv = ''
    let addrType = ''
    const downloadList = list ? list : resultArr
    customType = customType ? customType : type
    let sameMnemonic
    downloadList.forEach((wallet, i) => {
      sameMnemonic = wallet.mnemonic
      let mark = `${name}-${i + 1}`,
        key = wallet.privateKey,
        group = name,
        proxy = '',
        excAddr = '',
        address = wallet.address,
        mnemonic = wallet.mnemonic
      if (
        customType === 'btc' ||
        ['P2PKH', 'P2SH_P2WPKH', 'P2WPKH', 'P2TR'].includes(btcAddressType)
      ) {
        addrType = `(${btcAddressType})`
        key = {
          P2PKH: wallet.key1,
          P2SH_P2WPKH: wallet.key3,
          P2WPKH: wallet.keyBc1q,
          P2TR: wallet.keyBc1p,
        }[btcAddressType]
        address = {
          P2PKH: wallet.address1,
          P2SH_P2WPKH: wallet.address3,
          P2WPKH: wallet.addressBc1q,
          P2TR: wallet.addressBc1p,
        }[btcAddressType]
        mnemonic = wallet.mnemonic
      }
      csv += `${mark},${key},${group},${proxy},${excAddr},${address},${mnemonic}\n`
    })
    const filenameType = ['P2PKH', 'P2SH_P2WPKH', 'P2WPKH', 'P2TR'].includes(
      btcAddressType
    )
      ? 'BTC'
      : customType
    downloadFile(
      `${filenameType}${addrType}-${name}-${downloadList.length}.csv`,
      csv
    )
    if (walletDownloadTypes.length) {
      for (let i = 0; i < walletDownloadTypes.length; i++) {
        const wdType = walletDownloadTypes[i]
        if (wdType === type || wdType === btcAddressType) continue
        let getWalletsFun = getBtcWalletsByMnemonic
        if (wdType === 'sol') getWalletsFun = getSolWalletsByMnemonic
        if (wdType === 'eth') getWalletsFun = getEVMWalletsByMnemonic
        if (wdType === 'trx') getWalletsFun = getTronWalletsByMnemonic
        const others = getWalletsFun(sameMnemonic, 1, downloadList.length)
        downloadCsvFile({
          ...formValues,
          walletDownloadTypes: [],
          list: others,
          btcAddressType: wdType,
          customType: wdType,
        })
      }
    }
  }

  const downloadKeys = () => {
    const privateKey = resultArr.map((_) => _.privateKey).join('\n')
    const res = cryptoKeys([
      {
        id: `${Date.now()}`,
        name: `生成的钱包${Date.now()}`,
        keys: privateKey,
        seleted: false,
      },
    ])
    downloadFile(`生成的钱包${Date.now()}`, res)
  }

  const copyWalletInfo = (index, key) => {
    navigator.clipboard.writeText(resultArr[index][key])
    message.success({
      key: 'copyWalletInfo',
      content: `${key}已复制`,
    })
  }

  const copyAllAddress = () => {
    const addressStr = resultArr.map((_) => _.address).join('\n')
    navigator.clipboard.writeText(addressStr)
    message.success({
      key: 'copyAll',
      content: `已复制`,
    })
  }

  const copyAllPrivateKey = () => {
    const privateKeyStr = resultArr.map((_) => _.privateKey).join('\n')
    navigator.clipboard.writeText(privateKeyStr)
    message.success({
      key: 'copyAll',
      content: `已复制`,
    })
  }

  return (
    <div className="page">
      <div className="create_wallet_page">
        <p style={{ color: 'red' }}>
          请务必保存好您的私钥地址，我们不会保存任何用户私钥
        </p>
        <Radio.Group onChange={onChangeWalletType} value={type}>
          {walletType.map((item, i) => {
            return (
              <Radio key={i} value={item.key}>
                <b>{item.text}</b>
              </Radio>
            )
          })}
        </Radio.Group>
        <Divider dashed />
        <div className="action_box">
          <div className="left">
            <div className="same_words">
              <Checkbox
                checked={sameWords}
                onChange={(e) => setSameWords(e.target.checked)}
              >
                使用相同助记词
              </Checkbox>
            </div>
            <InputNumber min={1} value={count} onChange={setCount} />
            <Button
              type="primary"
              onClick={() => {
                if (!count) {
                  message.error('输入要创建钱包的数量')
                  return
                }
                setCreating(true)
                setTimeout(create, 100)
              }}
            >
              生成
            </Button>
            {!!resultArr.length && type === 'eth' && (
              <Button onClick={downloadKeys} type="primary">
                下载加密的私钥文件
              </Button>
            )}
            {!!resultArr.length && (
              <Button onClick={saveWalletFile} type="primary">
                下载CSV文件
              </Button>
            )}
          </div>
        </div>
        <Divider />
        {creating ? (
          <Spin size="large" />
        ) : (
          <div className="wallet_list">
            {resultArr.map((wallet, index) => {
              return type === 'btc' ? (
                <div className="wallet_item" key={index}>
                  <Input value={wallet.addressBc1p} />
                  <Button
                    type="primary"
                    onClick={() => {
                      copyWalletInfo(index, 'addressBc1p')
                    }}
                  >
                    复制bc1p地址
                  </Button>
                  <Input value={wallet.addressBc1q} />
                  <Button
                    type="primary"
                    onClick={() => {
                      copyWalletInfo(index, 'addressBc1q')
                    }}
                  >
                    复制bc1q地址
                  </Button>
                  <Input value={wallet.mnemonic} />
                  <Button
                    type="primary"
                    onClick={() => {
                      copyWalletInfo(index, 'mnemonic')
                    }}
                  >
                    复制助记词
                  </Button>
                </div>
              ) : (
                <div className="wallet_item" key={index}>
                  <Input value={wallet.address} />
                  <Button
                    type="primary"
                    onClick={() => {
                      copyWalletInfo(index, 'address')
                    }}
                  >
                    复制地址
                  </Button>
                  <Input value={wallet.privateKey} />
                  <Button
                    type="primary"
                    onClick={() => {
                      copyWalletInfo(index, 'privateKey')
                    }}
                  >
                    复制密钥
                  </Button>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default CreateWallets
